import './shareholders.css';
import { AdyenWizardStep } from '../AdyenWizard';
import { useTranslation } from 'react-i18next';
import MenuList, {
  MenuListItem,
} from '../../../../../elements/menulist/MenuList';
import { useState } from 'react';
import Popup from '../../../../../elements/popup/Popup';
import { usePetCloudApi } from '../../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../../contexts/errorhandler/ErrorHandler';
import {
  AdyenLegalEntityResponse,
  CreateAdyenLegalEntityRequest,
  TypeEnum10,
} from '../../../../../api/petcloudapi/api';
import Button from '../../../../../elements/button/Button';
import FormBuilder from '../../../../../elements/formbuilder/FormBuilder';
import { DropdownOption } from '../../../../../elements/selectors/Selectors';
import useNotifications from '../../../../../hooks/useNotifications';
import useCommonDropDownOptions from '../../../../../hooks/useCommonDropDownOptions';

export type TempShareholderInfo = {
  jobTitle?: string;
  signatory?: boolean;
  uboThroughControl?: boolean;
  uboThroughOwnership?: boolean;
  legalEntityId: string | null;
  flagged?: boolean;
};

type CreateAdyenLegalEntityRequestWithTempInfo =
  CreateAdyenLegalEntityRequest & {
    temp: TempShareholderInfo;
  };

const emptyLegalEntityRequest = (
  manufacturerId: string
): CreateAdyenLegalEntityRequestWithTempInfo => ({
  type: TypeEnum10.Individual,
  entityAssociations: null,
  individual: {
    name: {
      firstName: null,
      lastName: null,
    },
    nationality: null,
    residentialAddress: {
      city: null,
      country: null,
      postalCode: null,
      street: null,
    },
    birthData: {
      dateOfBirth: null,
    },
    email: null,
  },
  organization: null,
  soleProprietorship: null,
  reference: '',
  manufacturerId: manufacturerId,
  temp: {
    legalEntityId: null,
    jobTitle: undefined,
    signatory: false,
    uboThroughControl: false,
    uboThroughOwnership: false,
  },
});

interface ShareHoldersProps {
  setStep: (step: AdyenWizardStep) => void;
  setPopupWidth: (width: string) => void;
  // used to store values that are required in later requests in the adyen config chain
  commitTempShareholderInfo: (info: TempShareholderInfo[]) => void;
  manufacturerId: string;
  fetchedLegalEntities: AdyenLegalEntityResponse[] | null;
}

const ShareHolders: React.FC<ShareHoldersProps> = ({
  setStep,
  setPopupWidth,
  commitTempShareholderInfo,
  manufacturerId,
  fetchedLegalEntities,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.manufacturer.adyenWizard.shareholders',
  });
  const api = usePetCloudApi();
  const adyenLegalEntitiesApi = api.adyenLegalEntitiesApi();
  const errorHandler = useErrorHandler();
  const { pushNotification } = useNotifications();
  const { countryOptions } = useCommonDropDownOptions();

  const [legalEntities, setLegalEntities] = useState<
    AdyenLegalEntityResponse[]
  >(fetchedLegalEntities ?? []);
  const [newLegalEntity, setNewLegalEntity] =
    useState<CreateAdyenLegalEntityRequestWithTempInfo | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [tempShareholderInfo, setTempShareholderInfo] = useState<
    TempShareholderInfo[] | null
  >(null);
  const [tempShareholderInfoPopup, setTempShareholderInfoPopup] =
    useState(false);
  const [selectedLegalEntities, setSelectedLegalEntities] = useState<
    AdyenLegalEntityResponse[]
  >([]);

  const createLegalEntity = () => {
    if (newLegalEntity) {
      setIsSubmitting(true);
      const { temp } = newLegalEntity;
      const reference = `${newLegalEntity.individual?.name?.firstName} ${newLegalEntity.individual?.name?.lastName}`;
      adyenLegalEntitiesApi
        .adyenLegalEntitiesCreateLegalEntity({
          ...newLegalEntity,
          reference: reference,
        })
        .then((response) => {
          console.log(response);

          // push to be rendered in legal entities list
          const updateL = fetchedLegalEntities ?? [];
          updateL.push(response.data);
          setLegalEntities(updateL);

          // push into temp shareholder array
          const updateT = tempShareholderInfo ?? [];
          updateT.push({ ...temp, legalEntityId: response.data.legalEntityId });
          setTempShareholderInfo([...updateT]);

          setIsSubmitting(false);
          setNewLegalEntity(null);
          pushNotification(t('notifications.create_successful'));
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          setIsSubmitting(false);
        });
    }
  };

  const phoneTypeOptions: DropdownOption[] = [
    {
      id: 'mobile',
      name: t('phoneTypeOptions.mobile'),
    },
    { id: 'landline', name: t('phoneTypeOptions.landline') },
  ];

  const getMenuListItems = (): MenuListItem[] => {
    return legalEntities
      .filter((x) => x.type === ('Individual' as TypeEnum10))
      .map((entity) => {
        return {
          title: entity.reference,
          toggled: !!selectedLegalEntities?.find((x) => x.id === entity.id),
          onToggle: () => {
            const update = [...selectedLegalEntities];
            const i = update.findIndex((x) => x.id === entity.id);
            if (i !== -1) {
              update.splice(i, 1);
            } else {
              update.push(entity);
            }
            setSelectedLegalEntities(update);
          },
        };
      });
  };

  // checks if all selected legal entities have the required temporal information required for the next step
  // if so, it sends the required info up the component order
  // if not, it will prompt the user to complete the info to then send it up the order
  const validateTempInfo = () => {
    const updateT = tempShareholderInfo ?? [];
    let didNotPass = false;
    selectedLegalEntities.forEach((entity) => {
      const temp = tempShareholderInfo?.find(
        (x) => x.legalEntityId === entity.legalEntityId
      );
      if (!temp) {
        updateT.push({
          legalEntityId: entity.legalEntityId,
          flagged: true,
          jobTitle: undefined,
          signatory: undefined,
          uboThroughControl: undefined,
          uboThroughOwnership: undefined,
        });
        didNotPass = true;
      } else if (temp.flagged) {
        didNotPass = true;
      }
    });
    setTempShareholderInfo([...updateT]);
    if (didNotPass && !tempShareholderInfoPopup) {
      setTempShareholderInfoPopup(true);
    } else {
      if (tempShareholderInfo) {
        commitTempShareholderInfo(tempShareholderInfo);
      }
      setStep(AdyenWizardStep.CompanyInfo);
    }
  };

  return (
    <div className={'shareholders'}>
      <div className={'popup-title'}>{t('title')}</div>
      <div className={'global-textElement global-textElement'}>{t('text')}</div>
      <MenuList
        items={getMenuListItems()}
        addButton={{
          cta: t('cta_add'),
          look: 'primary',
          action: () =>
            setNewLegalEntity(emptyLegalEntityRequest(manufacturerId)),
        }}
      />
      {newLegalEntity ? (
        <Popup
          toggled={true}
          width={'30%'}
          close={() => setNewLegalEntity(null)}
        >
          <div className={'popup-title'}>{t('form.title')}</div>
          <div className={'shareholders-form'}>
            <FormBuilder
              contentObject={newLegalEntity}
              setContentObject={setNewLegalEntity}
              elements={[
                {
                  type: 'group',
                  children: [
                    {
                      type: 'input',
                      title: t('form.firstName'),
                      objectPath: 'individual.name.firstName',
                    },
                    {
                      type: 'input',
                      title: t('form.lastName'),
                      objectPath: 'individual.name.lastName',
                    },
                  ],
                },
                {
                  type: 'group',
                  children: [
                    {
                      type: 'dropdownMenu',
                      title: t('form.nationality'),
                      objectPath: 'individual.nationality',
                      optionObjects: countryOptions,
                    },
                    {
                      type: 'date',
                      title: t('form.dateOfBirth'),
                      objectPath: 'individual.birthData.dateOfBirth',
                      dateFormat: 'yyyy-MM-dd',
                    },
                  ],
                },
                {
                  type: 'group',
                  children: [
                    {
                      type: 'input',
                      title: t('form.street'),
                      objectPath: 'individual.residentialAddress.street',
                      size: 2,
                    },
                    {
                      type: 'input',
                      title: t('form.postalCode'),
                      objectPath: 'individual.residentialAddress.postalCode',
                    },
                  ],
                },
                {
                  type: 'group',
                  children: [
                    {
                      type: 'input',
                      title: t('form.city'),
                      objectPath: 'individual.residentialAddress.city',
                    },
                    {
                      type: 'dropdownMenu',
                      title: t('form.country'),
                      objectPath: 'individual.residentialAddress.country',
                      optionObjects: countryOptions,
                    },
                  ],
                },
                {
                  type: 'input',
                  title: t('form.jobTitle'),
                  objectPath: 'temp.jobTitle',
                  required: true,
                },
                {
                  type: 'input',
                  title: t('form.email'),
                  objectPath: 'individual.email',
                },
                {
                  type: 'group',
                  children: [
                    {
                      type: 'input',
                      title: t('form.phoneNumber'),
                      objectPath: 'individual.phone.number',
                      size: 2,
                    },
                    {
                      type: 'dropdown',
                      title: t('form.phoneType'),
                      objectPath: 'individual.phone.type',
                      optionObjects: phoneTypeOptions,
                    },
                  ],
                },
                {
                  type: 'checkbox',
                  title: t('form.signatory'),
                  hint: t('form.signatory_hint'),
                  objectPath: 'temp.signatory',
                },
                {
                  type: 'checkbox',
                  title: t('form.uboThroughControl'),
                  hint: t('form.uboThroughControl_hint'),
                  objectPath: 'temp.uboThroughControl',
                },
                {
                  type: 'checkbox',
                  title: t('form.uboThroughOwnership'),
                  hint: t('form.uboThroughOwnership_hint'),
                  objectPath: 'temp.uboThroughOwnership',
                },
              ]}
            />
          </div>
          <div className={'global-cardActions'}>
            <Button
              cta={t('form.cta')}
              look={'save'}
              action={() => createLegalEntity()}
              isLoading={isSubmitting}
            />
          </div>
        </Popup>
      ) : null}
      <div className={'global-cardActions global-cardActions-margin'}>
        <Button
          cta={t('cta_next')}
          action={validateTempInfo}
          look={'secondary'}
          width={'minimal'}
          active={selectedLegalEntities.length > 0}
        />
      </div>
      <Popup
        toggled={tempShareholderInfoPopup}
        width={'30%'}
        close={() => setTempShareholderInfoPopup(false)}
      >
        <div className={'popup-title'}>
          {t('tempShareholderInfoPopup.title')}
        </div>
        <div className={'global-textElement shareholders-tempInfoText'}>
          {t('tempShareholderInfoPopup.text')}
        </div>
        <div className={'shareholders-tempInfos'}>
          {tempShareholderInfo
            ?.filter((x) => x.flagged)
            .map((temp, index) => {
              const reference = legalEntities.find(
                (x) => x.legalEntityId === temp.legalEntityId
              )?.reference;
              return (
                <div
                  key={temp.legalEntityId}
                  className={'shareholders-tempInfo'}
                >
                  <div
                    key={temp.legalEntityId}
                    className={'shareholders-tempInfo-reference'}
                  >
                    {reference}
                  </div>
                  <FormBuilder
                    contentObject={temp}
                    setContentObject={(updatedTemp) => {
                      const update = [...tempShareholderInfo];
                      update[index] = {
                        ...updatedTemp,
                      };
                      setTempShareholderInfo(update);
                    }}
                    elements={[
                      {
                        type: 'input',
                        title: t('form.jobTitle'),
                        objectPath: 'temp.jobTitle',
                        required: true,
                      },
                      {
                        type: 'checkbox',
                        title: t('form.signatory'),
                        hint: t('form.signatory_hint'),
                        objectPath: 'temp.signatory',
                      },
                      {
                        type: 'checkbox',
                        title: t('form.uboThroughControl'),
                        hint: t('form.uboThroughControl_hint'),
                        objectPath: 'temp.uboThroughControl',
                      },
                      {
                        type: 'checkbox',
                        title: t('form.uboThroughOwnership'),
                        hint: t('form.uboThroughOwnership_hint'),
                        objectPath: 'temp.uboThroughOwnership',
                      },
                    ]}
                  />
                </div>
              );
            })}
        </div>
        <div className={'global-cardActions global-cardActions-margin'}>
          <Button
            cta={t('cta_next')}
            action={validateTempInfo}
            look={'secondary'}
            width={'minimal'}
          />
        </div>
      </Popup>
    </div>
  );
};

export default ShareHolders;
