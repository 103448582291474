import { createContext, useContext, useEffect, useState } from 'react';
import { Store } from 'react-notifications-component';
import { NoAuthorization } from '../../elements/emptystate/EmptyState';
import { LoadingContainer } from '../../elements/loading/Loading';
import { useUser } from './User';

type AuthorizationContextValue = {
  authorizations: string[] | null;
};

export const AuthorizationContext =
  createContext<AuthorizationContextValue | null>(null);

export const useAuthorization = () => {
  const ac = useContext(AuthorizationContext);
  if (ac === null) {
    throw new Error('useAuthorization must be inside of AuthorizationProvider');
  }
  return ac;
};

export const AuthorizationProvider: React.FC = ({ children }) => {
  const { user } = useUser();

  const getUserAuthorizations = async () => {
    const authorizations: string[] = [];
    user?.userRoles.forEach((role) => {
      role.policies.forEach((policy) => {
        if (!authorizations.includes(policy)) {
          authorizations.push(policy);
        }
      });
    });

    if (authorizations) {
      return authorizations;
    } else {
      Store.addNotification({
        message: 'Fatal Server Error!',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
        },
      });
      return null;
    }
  };
  const [authorizations, setAuthorizations] = useState<string[] | null>(null);

  useEffect(() => {
    const getAuthorizations = async () => {
      const userAuthorizations = await getUserAuthorizations();
      setAuthorizations(userAuthorizations);
    };

    getAuthorizations();
  }, []);

  return (
    <AuthorizationContext.Provider value={{ authorizations }}>
      {children}
    </AuthorizationContext.Provider>
  );
};

interface HasProps {
  authorizations: string[];
  isBypassed?: boolean;
  showNoAuthorization?: boolean;
}

export const Has: React.FC<HasProps> = ({
  children,
  authorizations = [],
  isBypassed,
  showNoAuthorization,
}) => {
  const { authorizations: userAuthorizations } = useAuthorization();
  const missingPermissions: string[] = [];
  let match;
  if (isBypassed) {
    match = true;
  } else {
    if (authorizations.length === 0) {
      match = true;
    } else {
      authorizations.forEach((a) => {
        if (!userAuthorizations?.includes(a)) {
          missingPermissions.push(a);
        }
      });
      match = missingPermissions.length === 0;
    }
  }

  if (userAuthorizations) {
    if (match) {
      return <>{children}</>;
    } else {
      if (showNoAuthorization) {
        return <NoAuthorization missingPermissions={missingPermissions} />;
      } else {
        return null;
      }
    }
  } else {
    return <LoadingContainer />;
  }
};
