import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Navigator,
  Stage,
  StageColumn,
  StageColumns,
} from '../../layout/stage/Stage';
import Button from '../../elements/button/Button';
import { ReactComponent as Export } from '../../../assets/icon/export.svg';
import Card, { CardSection } from '../../elements/card/Card';
import { usePetCloudApi } from '../../api/PetCloudApi';
import ManufacturerLogo from '../../sections/manufacturer/manufacturerlogo/ManufacturerLogo';
import ManufacturerInfo from '../../sections/manufacturer/manufacturerinfo/ManufacturerInfo';
import ManufacturerAddress from '../../sections/manufacturer/manufactureraddress/ManufacturerAddress';
import ManufacturerInitialAdmin from '../../sections/manufacturer/manufacturerinitialadmin/ManufacturerInitialAdmin';
import ManufacturerConfiguration from '../../sections/manufacturer/manufacturerconfiguration/ManufacturerConfiguration';
import { Dropdown } from '../../elements/selectors/Selectors';
import { CreateManufacturerRequest } from '../../api/petcloudapi/api';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import ManufacturerConditions from '../../sections/manufacturer/manufacturerconditions/ManufacturerConditions';
import { useNavigate } from 'react-router-dom';
import useNotifications from '../../hooks/useNotifications';

const NewManufacturer = () => {
  const { t } = useTranslation();
  const link = useNavigate();
  const { pushNotification } = useNotifications();
  const api = usePetCloudApi().manufacturersApi();
  const errorHandler = useErrorHandler();
  const [errors, setErrors] = useState<
    { key: string; values: string[] }[] | null
  >(null);

  const [newManufacturer, setNewManufacturer] =
    useState<CreateManufacturerRequest>({
      enabled: true,
      companyName: '',
      slug: '',
      vatId: '',
      registrationNumber: '',
      shortCode: '',
      companyAddress: {
        street: null,
        streetNumber: null,
        zipCode: '',
        city: '',
        state: null,
        additionalAddressLine1: null,
        additionalAddressLine2: null,
        countryId: '',
      },
      user: {
        username: '',
        email: '',
        title: null,
        salutation: '',
        firstName: '',
        lastName: '',
        manufacturerId: null,
      },
      customerNumber: '',
    });

  const [submitting, setSubmitting] = useState(false);
  const logo = null;

  const update = (manufacturer: CreateManufacturerRequest) => {
    setNewManufacturer({ ...manufacturer });
  };

  const updateStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.currentTarget.value === 'active') {
      setNewManufacturer({ ...newManufacturer, enabled: true });
    } else {
      setNewManufacturer({ ...newManufacturer, enabled: false });
    }
  };

  const submit = () => {
    const submissionObject = newManufacturer;
    if (
      submissionObject.manufacturerConditions &&
      submissionObject.manufacturerConditions.length === 0
    ) {
      submissionObject.manufacturerConditions = null;
    }
    setSubmitting(true);
    api
      .manufacturersCreateManufacturer(submissionObject)
      .then((response) => {
        console.log(response);
        setSubmitting(false);
        pushNotification(t('view.manufacturer.notifications.create_success'));
        link('/manufacturers/' + response.data.id);
      })
      .catch((error) => {
        console.log(error);
        setSubmitting(false);
        errorHandler.addError(error.response, (errs) => {
          setErrors(errs);
        });
      });
  };

  return (
    <Stage>
      <Navigator title={newManufacturer.companyName} backLink="/manufacturers">
        <Button cta={t('actions.export')} margin="left" type="icon-text">
          <Export className="button-icon" fill="var(--color-text_secondary)" />
        </Button>
        <Button
          cta={t('actions.save')}
          margin="left"
          look="save"
          action={submit}
          isLoading={submitting}
        ></Button>
      </Navigator>
      <StageColumns>
        <StageColumn size="two">
          <Card bigScreenWidth="100%">
            <CardSection title={t('view.manufacturer.meta.logo.title')}>
              <ManufacturerLogo
                logo={logo}
                manufacturer={newManufacturer}
                updateNewManufacturer={update}
              />
            </CardSection>
            <CardSection title={t('view.manufacturer.meta.info.title')}>
              <ManufacturerInfo
                t={t}
                manufacturer={newManufacturer}
                updateNewManufacturer={update}
                errors={errors}
              />
            </CardSection>
            <CardSection
              title={t('view.manufacturer.meta.configuration.title')}
            >
              <ManufacturerConfiguration
                t={t}
                manufacturer={newManufacturer}
                updateNewManufacturer={update}
                errors={errors}
              />
            </CardSection>
            <CardSection title={t('view.manufacturer.meta.address.title')}>
              <ManufacturerAddress
                t={t}
                manufacturer={newManufacturer}
                updateNewManufacturer={update}
                errors={errors}
              />
            </CardSection>
          </Card>
          <Card
            bigScreenWidth="100%"
            title={t('view.manufacturer.admin.title')}
          >
            <CardSection>
              <ManufacturerInitialAdmin
                updateNewManufacturer={update}
                manufacturer={newManufacturer}
                t={t}
                errors={errors}
              />
            </CardSection>
          </Card>
          <Card
            bigScreenWidth="100%"
            title={t('view.manufacturer.conditions.title')}
          >
            <CardSection>
              <ManufacturerConditions
                setInlineConditionsCallback={(conditions) => {
                  console.log(conditions);
                  setNewManufacturer({
                    ...newManufacturer,
                    manufacturerConditions: conditions,
                  });
                }}
              />
            </CardSection>
          </Card>
        </StageColumn>
        <StageColumn size="one">
          <Card bigScreenWidth="100%">
            <CardSection>
              <Dropdown
                options={['active', 'inactive']}
                selected={newManufacturer.enabled ? 'active' : 'inactive'}
                translationPath={'status.'}
                title={t('view.manufacturer.sidebar.status')}
                update={updateStatus}
              />
            </CardSection>
          </Card>
        </StageColumn>
      </StageColumns>
    </Stage>
  );
};

export default NewManufacturer;
