import './inpettoclientbasedorderdeliveryinformationimport.css';
import { IntegrationSubModuleConfigResponse } from '../../../../../api/petcloudapi/api';
import { useTranslation } from 'react-i18next';
import useNotifications from '../../../../../hooks/useNotifications';
import { useEffect, useState } from 'react';
import { BadgeProps } from '../../../../../elements/badge/Badge';
import Card, { CardSection } from '../../../../../elements/card/Card';
import FormBuilder from '../../../../../elements/formbuilder/FormBuilder';
import Button from '../../../../../elements/button/Button';
import { LoadingContainer } from '../../../../../elements/loading/Loading';
import useInpettoClientBasedIntegrationApi from '../../../setup/inpettoclientbased/hooks/useInpettoClientBasedIntegrationApi';
import { UpsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest } from '../../../../../api/magentoapi';

const newConfig = (
  integrationSubModuleId: string,
  manufacturerId?: string
): UpsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest => ({
  manufacturerId,
  integrationSubModuleId,
  enabled: false,
});

interface InpettoClientBasedOrderDeliveryInformationImportProps {
  integrationIdentifier: string;
  manufacturerId?: string;
  integrationSubModuleId: string;
  fetchedConfig?: IntegrationSubModuleConfigResponse | null;
  locked: boolean;
}

const InpettoClientOrderDeliveryInformationImport: React.FC<
  InpettoClientBasedOrderDeliveryInformationImportProps
> = ({
  integrationIdentifier,
  manufacturerId,
  integrationSubModuleId,
  fetchedConfig,
  locked,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.integration.common.orderDeliveryInformationImport',
  });
  const { deliveryInformationConfigPost } = useInpettoClientBasedIntegrationApi(
    integrationIdentifier
  );
  const { pushNotification } = useNotifications();

  const [config, setConfig] = useState<
    | UpsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest
    | null
    | undefined
  >(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    getConfig();
  }, []);

  const getConfig = () => {
    if (fetchedConfig) {
      setConfig({...fetchedConfig, enabled: fetchedConfig.enabled ?? false});
    } else {
      setConfig(undefined);
    }
  };

  const updateConfig = (
    req: UpsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest
  ) => {
    setIsSubmitting(true);
    deliveryInformationConfigPost(req)
      .then((response) => {
        pushNotification(t('notifications.update_successful'));
        setIsSubmitting(false);
      })
      .catch((error) => {
        setIsSubmitting(false);
      });
  };

  const getBadges = () => {
    const nodes: BadgeProps[] = [];
    if (!fetchedConfig) {
      nodes.push({
        title: t('badge.notConfigured'),
        color: 'var(--color-text_secondary)',
      });
    } else {
      nodes.push({
        title: t('badge.configured'),
        color: 'var(--color-success)',
      });
    }
    return nodes;
  };

  return (
    <div className={'inpettoClientBasedOrderDeliveryInformationImport'}>
      <div className={'inpettoClientBasedOrderDeliveryInformationImport-inner'}>
        <Card bigScreenWidth={'100%'}>
          <CardSection title={t('title')} badges={getBadges()} locked={locked}>
            {config !== null ? (
              config !== undefined ? (
                <div
                  className={
                    'inpettoClientBasedOrderDeliveryInformationImport-body'
                  }
                >
                  <FormBuilder
                    contentObject={config}
                    setContentObject={setConfig}
                    elements={[
                      {
                        type: 'toggle',
                        objectPath: 'enabled',
                        title: t('toggle'),
                      },
                    ]}
                  />
                  <div className={'global-cardActions-margin'}>
                    <Button
                      cta={t('cta_save')}
                      look={'save'}
                      width={'minimal'}
                      action={() => updateConfig(config)}
                      active={!locked}
                      inactiveMessage={t('inactiveMessage')}
                      isLoading={isSubmitting}
                    />
                  </div>
                </div>
              ) : (
                <Button
                  cta={t('cta_configure')}
                  look={'secondary'}
                  width={'minimal'}
                  action={() =>
                    setConfig(newConfig(integrationSubModuleId, manufacturerId))
                  }
                  active={!locked}
                  inactiveMessage={t('inactiveMessage')}
                />
              )
            ) : (
              <LoadingContainer />
            )}
          </CardSection>
        </Card>
      </div>
    </div>
  );
};

export default InpettoClientOrderDeliveryInformationImport;
