import './categories.css';
import { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Store } from 'react-notifications-component';
import { ProductCategoryResponse } from '../../../api/petcloudapi/api';
import Popup from '../../../elements/popup/Popup';
import Button from '../../../elements/button/Button';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import {
  Error,
  useErrorHandler,
} from '../../../contexts/errorhandler/ErrorHandler';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { CardTitle } from '../../../elements/card/Card';
import { LoadingContainer } from '../../../elements/loading/Loading';
import CategoryHierarchy from '../../../features/categoryhierarchy/CategoryHierarchy';
import { EmptyState, NoResults } from '../../../elements/emptystate/EmptyState';
import categoriesempty from '../../../../assets/img/categories_empty.svg';
import ErrorCheck from '../../../elements/errorcheck/ErrorCheck';
import { Has, useAuthorization } from '../../../contexts/auth/Authorization';
import usePersistInCookies from '../../../hooks/useStorageData';
import TagWithHeaders from '../../../elements/tagwithheaders/TagWithHeaders';

interface CategoriesProps {
  productId?: string;
  productVersionId?: string;
  inheritedProductCategories?: ProductCategoryResponse[] | null;
  callback?: (categories: ProductCategoryResponse[]) => void;
  readonly?: boolean;
  errors?: Error[];
  productGroupId: string | null | undefined;
  animalSpeciesIds: string[];
  mainCategoryId?: string | null;
  setMainCategoryId?: (categoryId: string | null) => void;
  onSubmitProduct?: () => void;
}

const Categories: React.FC<CategoriesProps> = ({
  productId,
  productVersionId,
  inheritedProductCategories,
  callback,
  readonly,
  errors,
  productGroupId,
  animalSpeciesIds,
  mainCategoryId,
  setMainCategoryId,
  onSubmitProduct,
}) => {
  const { t, i18n } = useTranslation();
  const { authorizations } = useAuthorization();
  const [popup, setPopup] = useState(false);
  const errorHandler = useErrorHandler();
  const api = usePetCloudApi();
  const productCategoriesApi = api.productCategoriesApi();
  const productsApi = api.productsApi();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [productCategories, setProductCategories] = useState<
    ProductCategoryResponse[] | null
  >(null);
  const [originalProductCategories, setOriginalProductCategories] = useState<
    ProductCategoryResponse[] | null
  >(null);
  const [availableProductCategories, setAvailableProductCategories] = useState<
    ProductCategoryResponse[] | null
  >(null);
  const { persistObject, getPersistedObject } = usePersistInCookies();
  const originalMainCategory = useRef(mainCategoryId);

  useEffect(() => {
    getProductCategories();
    getAvailableProductCategories();
  }, [authorizations]);

  const getAvailableProductCategories = () => {
    if (authorizations?.includes('product_categories:list')) {
      productCategoriesApi
        .productCategoriesGetProductCategories(
          true,
          undefined,
          productGroupId ? [productGroupId] : undefined,
          animalSpeciesIds
        )
        .then((response) => {
          console.log(response);
          setAvailableProductCategories(response.data);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  };

  const getProductCategories = () => {
    if (productId) {
      productsApi
        .productsGetProductCategories(productId, undefined, productVersionId)
        .then((response) => {
          console.log(response);
          setProductCategories(response.data);
          setOriginalProductCategories(response.data);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    } else {
      const persisted = getPersistedObject('new_product_categories');
      setProductCategories(persisted ?? []);
      if (callback) {
        callback(persisted);
      }
      setOriginalProductCategories([]);
    }
  };

  const submitUpdatedProductCategories = () => {
    if (productId && productCategories) {
      setIsSubmitting(true);
      let productCategoryIds: string[] = [];
      productCategories.forEach((category) => {
        if (!category.isDisabledForProducts)
          productCategoryIds.push(category.id);
      });
      productsApi
        .productsUpdateProductCategories(productId, {
          productCategoryIds: productCategoryIds,
        })
        .then((response) => {
          console.log(response);
          Store.addNotification({
            message: t(
              'view.product.notifications.categoriesUpdate_successful'
            ),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
          setIsSubmitting(false);
          setPopup(false);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  };

  const renderCategories = (
    categories: ProductCategoryResponse[],
    inherited?: boolean
  ) => {
    return (
      <div className="categories-categories">
        {categories.map((category, i) => {
          const categoryName =
            category.name[i18n.language as TranslatedStringIndex] ??
            'missing translation';
          return (
            <div
              key={i}
              className={`categories-category ${
                inherited ? 'categories-category-inherited' : ''
              }`}
            >
              <TagWithHeaders
                tag={categoryName}
                headers={category.animalSpecies?.map(
                  (x) =>
                    x.name[i18n.language as TranslatedStringIndex] ??
                    'missing translation'
                )}
                highlight={mainCategoryId === category.id}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const handleSaveButton = () => {
    if (productCategories !== originalProductCategories && !readonly) {
      submitUpdatedProductCategories();
    }
    if (onSubmitProduct && originalMainCategory.current !== mainCategoryId) {
      onSubmitProduct();
      setPopup(false);
    }
  };

  if (authorizations) {
    return (
      <Has authorizations={['product_categories:list']}>
        {productCategories && availableProductCategories ? (
          <div className="categories">
            <CardTitle
              title={t('view.product.categories.title')}
              inherited={
                inheritedProductCategories
                  ? productCategories && productCategories.length === 0
                  : undefined
              }
              required={true}
              hasBottomMargin
            />
            <ErrorCheck errors={errors} checkFor={['Categories']} />
            {productCategories.length === 0 && inheritedProductCategories ? (
              renderCategories(inheritedProductCategories, true)
            ) : productCategories.length > 0 ? (
              renderCategories(productCategories)
            ) : (
              <EmptyState img={categoriesempty} small size={50} />
            )}
            {!readonly ? (
              <div className="categories-actions">
                <Button
                  cta={t('view.product.categories.categories_edit')}
                  look={'secondary'}
                  action={() => setPopup(true)}
                  active={!readonly}
                />
              </div>
            ) : null}
            <Popup toggled={popup} width="40%" close={() => setPopup(false)}>
              <div className="categories-popup-title">
                {t('view.product.categories.popup.title')}
              </div>
              {availableProductCategories.length > 0 ? (
                <CategoryHierarchy
                  availableProductCategories={availableProductCategories}
                  currentProductCategories={productCategories}
                  updateCurrentProductCategories={(categories) => {
                    setProductCategories(categories);
                    if (callback) {
                      callback(categories);
                      console.log(categories);
                      persistObject([...categories], 'new_product_categories');
                    }
                  }}
                  mainCategoryId={mainCategoryId}
                  setMainCategoryId={setMainCategoryId}
                />
              ) : (
                <NoResults />
              )}
              {productId ? (
                <div className="categories-popup-actions">
                  <Button
                    cta={t('actions.save')}
                    look="save"
                    action={handleSaveButton}
                    isLoading={isSubmitting}
                    active={
                      (productCategories !== originalProductCategories ||
                        originalMainCategory.current !== mainCategoryId) &&
                      !readonly
                    }
                    width={'minimal'}
                  />
                </div>
              ) : null}
            </Popup>
          </div>
        ) : (
          <LoadingContainer />
        )}
      </Has>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default memo(Categories);
