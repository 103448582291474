import { Navigator, Stage } from '../../layout/stage/Stage';
import { useTranslation } from 'react-i18next';
import KnowledgeBase from '../../sections/knowledgebase/KnowledgeBase';

const Help = () => {
  const { t } = useTranslation();

  return (
    <Stage>
      <Navigator title={t('view.help.title')} allowBackButton />
      <KnowledgeBase />
    </Stage>
  );
};

export default Help;
