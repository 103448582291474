import './transferinstrument.css';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../../contexts/errorhandler/ErrorHandler';
import useNotifications from '../../../../../hooks/useNotifications';
import useCommonDropDownOptions from '../../../../../hooks/useCommonDropDownOptions';
import { AdyenWizardStep } from '../AdyenWizard';
import {
  AdyenTransferInstrumentResponse,
  CreateAdyenTransferInstrumentRequest,
  TypeEnum2,
  TypeEnum9,
} from '../../../../../api/petcloudapi/api';
import { useState } from 'react';
import MenuList, {
  MenuListItem,
} from '../../../../../elements/menulist/MenuList';
import Popup from '../../../../../elements/popup/Popup';
import FormBuilder from '../../../../../elements/formbuilder/FormBuilder';
import Button from '../../../../../elements/button/Button';

const emptyTransferInstrumentRequest: CreateAdyenTransferInstrumentRequest = {
  legalEntityId: '',
  type: TypeEnum9.BankAccount,
  bankAccount: {
    accountIdentification: {
      iban: null,
      type: TypeEnum2.Iban,
    },
  },
};

interface TransferInstrumentProps {
  setStep: (step: AdyenWizardStep) => void;
  legalEntityId: string;
  fetchedTransferInstruments: AdyenTransferInstrumentResponse[] | null;
}

const TransferInstrument: React.FC<TransferInstrumentProps> = ({
  setStep,
  legalEntityId,
  fetchedTransferInstruments,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.manufacturer.adyenWizard.transferInstrument',
  });
  const api = usePetCloudApi();
  const adyenTransferInstrumentsApi = api.adyenTransferInstrumentsApi();
  const errorHandler = useErrorHandler();
  const { pushNotification } = useNotifications();
  const { countryOptions, currencyOptions } = useCommonDropDownOptions();

  const [transferInstruments, setTransferInstruments] = useState<
    AdyenTransferInstrumentResponse[]
  >(fetchedTransferInstruments ?? []);

  const [newTransferInstrument, setNewTransferInstrument] =
    useState<CreateAdyenTransferInstrumentRequest | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const createTransferInstrument = () => {
    if (newTransferInstrument) {
      setIsSubmitting(true);
      adyenTransferInstrumentsApi
        .adyenTransferInstrumentsCreateTransferInstrument({
          ...newTransferInstrument,
          legalEntityId: legalEntityId,
        })
        .then((response) => {
          console.log(response);

          // push to be rendered in legal entities list
          const updateL = fetchedTransferInstruments ?? [];
          updateL.push(response.data);
          setTransferInstruments(updateL);

          setIsSubmitting(false);
          setNewTransferInstrument(null);
          pushNotification(t('notifications.create_successful'));
        })
        .catch((error: any) => {
          console.log(error);
          errorHandler.addError(error.response);
          setIsSubmitting(false);
        });
    }
  };

  const getMenuListItems = (): MenuListItem[] => {
    return transferInstruments.map((inst) => {
      return {
        title: inst?.bankAccount?.accountIdentification?.iban ?? 'Error - IBAN missing',
      };
    });
  };

  return (
    <div className="transferInstrument">
      <div className={'popup-title'}>{t('title')}</div>
      <div className={'global-textElement global-textElement'}>{t('text')}</div>
      <MenuList
        items={getMenuListItems()}
        addButton={{
          cta: t('cta_add'),
          look: 'primary',
          action: () =>
            setNewTransferInstrument(emptyTransferInstrumentRequest),
        }}
      />
      {newTransferInstrument ? (
        <Popup
          toggled={true}
          width={'30%'}
          close={() => setNewTransferInstrument(null)}
        >
          <div className={'popup-title'}>{t('form.title')}</div>
          <div className={'transferInstrument-form'}>
            <FormBuilder
              contentObject={newTransferInstrument}
              setContentObject={setNewTransferInstrument}
              elements={[
                {
                  type: 'input',
                  title: t('form.iban'),
                  objectPath: 'bankAccount.accountIdentification.iban',
                  cleanupFunc: 'removeSpaces',
                },
                {
                  type: 'dropdownMenu',
                  title: t('form.currencyCode'),
                  objectPath: 'bankAccount.currencyCode',
                  optionObjects: currencyOptions,
                },
                {
                  type: 'dropdownMenu',
                  title: t('form.countryCode'),
                  objectPath: 'bankAccount.countryCode',
                  optionObjects: countryOptions,
                },
              ]}
            />
          </div>
          <div className={'global-cardActions'}>
            <Button
              cta={t('form.cta')}
              look={'save'}
              action={createTransferInstrument}
              isLoading={isSubmitting}
            />
          </div>
        </Popup>
      ) : null}
      <div className={'global-cardActions global-cardActions-margin'}>
        <Button
          cta={t('cta_next')}
          action={() => setStep(AdyenWizardStep.AccountHolder)}
          look={'secondary'}
          width={'minimal'}
          active={transferInstruments.length > 0}
        />
      </div>
    </div>
  );
};

export default TransferInstrument;
