import './productunits.css';
import { useEffect, useState } from 'react';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import {
  CreateProductUnitRequest,
  ProductUnitResponse,
} from '../../../api/petcloudapi/api';
import { LoadingContainer } from '../../../elements/loading/Loading';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import Button from '../../../elements/button/Button';
import { useTranslation } from 'react-i18next';
import Popup from '../../../elements/popup/Popup';
import List from '../../../features/list/List';
import Input from '../../../elements/input/Input';
import { Dropdown } from '../../../elements/selectors/Selectors';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import useNotifications from '../../../hooks/useNotifications';
import useListRenderObjects from '../../../hooks/list/useListRenderObjects';
import ToggleSwitch from '../../../elements/toggleswitch/ToggleSwitch';

const createProductUnitRequest: CreateProductUnitRequest = {
  name: {
    'de-DE': null,
    'en-GB': null,
  },
  shortCode: {
    'de-DE': null,
    'en-GB': null,
  },
  isBasePriceUnit: false,
  formatPadding: null,
  formatPrecision: null,
};

const ProductUnits = () => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'view.admin.productUnits',
  });
  const api = usePetCloudApi();
  const errorHandler = useErrorHandler();
  const productUnitsApi = api.productUnitsApi();
  const { pushNotification } = useNotifications();
  const { renderBoolean } = useListRenderObjects();
  const [productUnits, setProductUnits] = useState<
    ProductUnitResponse[] | null
  >(null);
  const [selectedProductUnits, setSelectedProductUnits] = useState<
    ProductUnitResponse[]
  >([]);
  const [newProductUnit, setNewProductUnit] =
    useState<CreateProductUnitRequest>({ ...createProductUnitRequest });
  const [dangerPopup, setDangerPopup] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newPopup, setNewPopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [selectedLocale, setSelectedLocale] = useState<TranslatedStringIndex>(
    i18n.language as TranslatedStringIndex
  );
  const [unitToBeEdited, setUnitToBeEdited] =
    useState<ProductUnitResponse | null>(null);

  useEffect(() => {
    getProductUnits();
  }, []);

  const getProductUnits = () => {
    productUnitsApi
      .productUnitsGetProductUnits()
      .then((response) => {
        console.log(response.data);
        setProductUnits([...response.data]);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const selectUnit = (item: ProductUnitResponse) => {
    const update = selectedProductUnits;
    const i = update.findIndex((unit) => unit.id === item.id);
    if (i !== -1) {
      update.splice(i, 1);
    } else {
      update.push(item);
    }
    setSelectedProductUnits([...update]);
  };

  const selectAllUnits = () => {
    if (productUnits && selectedProductUnits.length !== productUnits.length) {
      setSelectedProductUnits([...productUnits]);
    } else {
      setSelectedProductUnits([]);
    }
  };

  const deleteSelectedProductUnits = () => {
    setIsSubmitting(true);
    const promises = selectedProductUnits.map((unit) => {
      return deleteUnit(unit.id);
    });

    Promise.all(promises)
      .then(() => {
        pushNotification(t('notifications.delete_successful'));
        setDangerPopup(false);
        setIsSubmitting(false);
        setSelectedProductUnits([]);
        getProductUnits();
      })
      .catch(() => {
        pushNotification(t('notifications.delete_failed'));
        setDangerPopup(false);
        setIsSubmitting(false);
        setSelectedProductUnits([]);
        getProductUnits();
      });
  };

  const deleteUnit = (id: string) => {
    return new Promise((resolve, reject) => {
      productUnitsApi
        .productUnitsDeleteProductUnit(id)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          reject(error);
        });
    });
  };

  const submitNewProductUnit = () => {
    setIsSubmitting(true);
    productUnitsApi
      .productUnitsCreateProductUnit(newProductUnit)
      .then((response) => {
        console.log(response);
        pushNotification(t('notifications.create_successful'));
        setIsSubmitting(false);
        setNewProductUnit({
          ...createProductUnitRequest,
        });
        getProductUnits();
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSubmitting(false);
      });
  };

  const submitEditedGroup = () => {
    if (unitToBeEdited) {
      setIsSubmitting(true);
      productUnitsApi
        .productUnitsUpdateProductUnit(unitToBeEdited.id, unitToBeEdited)
        .then((response) => {
          console.log(response);
          pushNotification(t('notifications.edit_successful'));
          setIsSubmitting(false);
          getProductUnits();
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          setIsSubmitting(false);
        });
    }
  };

  if (productUnits) {
    return (
      <div className="productunits">
        <List
          items={productUnits}
          selectedItems={selectedProductUnits}
          onSelect={selectUnit}
          onSelectAll={selectAllUnits}
          ignore={[
            'updatedAt',
            'shopReferenceId',
            'id',
            'formatPadding',
            'formatPrecision',
          ]}
          dateStrings={['createdAt', 'syncedAt']}
          translatedStrings={['name', 'shortCode']}
          selectedLocale={selectedLocale}
          monoSpaceStrings={['id']}
          onRowClick={(item: ProductUnitResponse) => {
            setUnitToBeEdited({ ...item });
            setEditPopup(true);
          }}
          sortValueFunctions={{
            name: (unit) =>
              unit.name[i18n.language as TranslatedStringIndex].toLowerCase(),
            shortCode: (unit) =>
              unit.shortCode[
                i18n.language as TranslatedStringIndex
              ].toLowerCase(),
          }}
          actions={[
            {
              cta: 'edit',
              look: 'blue',
              action: (item: ProductUnitResponse) => {
                setUnitToBeEdited({ ...item });
                setEditPopup(true);
              },
            },
            {
              cta: 'delete',
              look: 'danger',
              action: (item: ProductUnitResponse) => {
                setSelectedProductUnits([item]);
                setDangerPopup(true);
              },
            },
          ]}
          renderObjects={[
            {
              key: 'isBasePriceUnit',
              renderMethod: renderBoolean,
              receiveNullValues: true,
            },
          ]}
          adjustHeightToViewport
          adjustHeightToViewportOffset={120}
          tableHeadContrast
          listControls={{
            children: (
              <>
                <div className="listFilters-filter">
                  <Dropdown
                    options={['de-DE', 'en-GB']}
                    selected={selectedLocale}
                    update={(e) => {
                      const val =
                        e.target.selectedOptions[0].getAttribute('data-value');
                      if (val) {
                        setSelectedLocale(val as TranslatedStringIndex);
                      }
                    }}
                  />
                </div>
                {selectedProductUnits.length > 0 ? (
                  <div className="listFilters-filter">
                    <Button
                      cta={t('actions.delete')}
                      width={'minimal'}
                      look="secondary-danger"
                      action={() => setDangerPopup(true)}
                      margin="right"
                    />
                  </div>
                ) : null}
              </>
            ),
          }}
        />
        <div className="global-cardActions-postBorder">
          <Button
            width="minimal"
            look={'secondary'}
            cta={t('new')}
            action={() => setNewPopup(true)}
          />
        </div>
        <Popup
          width="30%"
          toggled={dangerPopup}
          close={() => setDangerPopup(false)}
        >
          <div className="popup-title">{t('dangerPopup.title')}</div>
          <div className="productunits-dangerPopup-message">
            {t('dangerPopup.message')}
          </div>
          <Button
            width="full"
            look="danger"
            cta={t('dangerPopup.cta')}
            action={deleteSelectedProductUnits}
            isLoading={isSubmitting}
          />
        </Popup>
        <Popup width="40%" toggled={newPopup} close={() => setNewPopup(false)}>
          <div className="popup-title">{t('newPopup.title')}</div>
          <ProductUnitForm
            unit={newProductUnit}
            setProductUnit={setNewProductUnit}
          />
          <div className="global-cardActions">
            <Button
              width="minimal"
              look="save"
              cta={t('newPopup.submit')}
              action={submitNewProductUnit}
              isLoading={isSubmitting}
            />
          </div>
        </Popup>
        <Popup
          width="40%"
          toggled={editPopup}
          close={() => setEditPopup(false)}
        >
          <div className="popup-title">{t('editPopup.title')}</div>
          {unitToBeEdited ? (
            <ProductUnitForm
              unit={unitToBeEdited}
              setProductUnit={(
                unit: ProductUnitResponse | CreateProductUnitRequest
              ) => setUnitToBeEdited(unit as ProductUnitResponse)}
            />
          ) : null}
          <div className="global-cardActions">
            <Button
              width="minimal"
              look="save"
              cta={t('editPopup.cta')}
              action={submitEditedGroup}
              isLoading={isSubmitting}
            />
          </div>
        </Popup>
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default ProductUnits;

interface ProductUnitFormProps {
  unit: ProductUnitResponse | CreateProductUnitRequest;
  setProductUnit: (
    unit: ProductUnitResponse | CreateProductUnitRequest
  ) => void;
}

const ProductUnitForm: React.FC<ProductUnitFormProps> = ({
  unit,
  setProductUnit,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.admin.productUnits.newPopup',
  });
  return (
    <div className="productunits-groupForm">
      <div className="global-inputGroup">
        <div className="global-inputGroup-input">
          <Input
            title={t('nameDE')}
            content={unit.name['de-DE']}
            update={(e) => {
              setProductUnit({
                ...unit,
                name: {
                  ...unit.name,
                  'de-DE': e,
                },
              });
            }}
          />
        </div>
        <div className="global-inputGroup-input">
          <Input
            title={t('nameEN')}
            content={unit.name['en-GB']}
            update={(e) => {
              setProductUnit({
                ...unit,
                name: {
                  ...unit.name,
                  'en-GB': e,
                },
              });
            }}
          />
        </div>
      </div>
      <div className="global-inputGroup">
        <div className="global-inputGroup-input">
          <Input
            title={t('shortCodeDE')}
            content={unit.shortCode ? unit.shortCode['de-DE'] : null}
            update={(e) => {
              setProductUnit({
                ...unit,
                shortCode: {
                  ...unit.shortCode,
                  'de-DE': e,
                },
              });
            }}
          />
        </div>
        <div className="global-inputGroup-input">
          <Input
            title={t('shortCodeEN')}
            content={unit.shortCode ? unit.shortCode['en-GB'] : null}
            update={(e) => {
              setProductUnit({
                ...unit,
                shortCode: {
                  ...unit.shortCode,
                  'en-GB': e,
                },
              });
            }}
          />
        </div>
      </div>
      <div className="global-inputGroup">
        <div className="global-inputGroup-input">
          <Input
            title={t('formatPrecision')}
            hint={t('formatPrecision_hint')}
            content={unit.formatPrecision?.toString()}
            update={(e) => {
              setProductUnit({
                ...unit,
                formatPrecision: parseInt(e),
              });
            }}
            type={'number'}
          />
        </div>
        <div className="global-inputGroup-input">
          <Input
            title={t('formatPadding')}
            hint={t('formatPadding_hint')}
            content={unit.formatPadding?.toString()}
            update={(e) => {
              setProductUnit({
                ...unit,
                formatPadding: parseInt(e),
              });
            }}
            type={'number'}
          />
        </div>
      </div>
      <div className="global-inputGroup">
        <div className="global-inputGroup-input">
          <ToggleSwitch
            toggled={unit.isBasePriceUnit}
            toggle={() => {
              setProductUnit({
                ...unit,
                isBasePriceUnit: !unit.isBasePriceUnit,
              });
            }}
            title={t('isBasePriceUnit')}
            hint={t('isBasePriceUnit_hint')}
          />
        </div>
      </div>
    </div>
  );
};
