import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Has } from '../../contexts/auth/Authorization';
import { Navigator, Stage } from '../../layout/stage/Stage';
import Card, { CardSection } from '../../elements/card/Card';
import List from '../../features/list/List';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { LoadingContainer } from '../../elements/loading/Loading';
import MinifiedCustomer from '../../types/MinifiedCustomer';
import { displayStringHighlighted } from '../../elements/productsearch/ProductSearch';
import { ReactComponent as IconGuest } from '../../../assets/icon/guest.svg';
import { LoyaltyRankCustomerHistoryResponse } from '../../api/petcloudapi/api';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import useListControlsSearch from '../../features/list/listcontrols/hooks/useListControlsSearch';

const Customers = () => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'view.customers',
  });
  const api = usePetCloudApi();
  const customersApi = api.customersApi();
  const errorHandler = useErrorHandler();
  const { listControlSearch, query, currentItems, setOriginalItems } =
    useListControlsSearch<MinifiedCustomer>(t('search'), null, [
      'name',
      'email',
      'customerNumber',
    ]);

  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = () => {
    customersApi
      .customersGetCustomers()
      .then((response) => {
        console.log(response);
        const minifiedCustomers: MinifiedCustomer[] = response.data.map(
          (customer) => {
            return {
              id: customer.id,
              name:
                customer.salutation +
                ' ' +
                (customer.title ?? '') +
                ' ' +
                customer.firstName +
                ' ' +
                customer.lastName,
              email: customer.email,
              customerNumber: customer.customerNumber,
              activeRank: customer.latestActiveRank,
              createdAt: customer.createdAt,
              isGuest: customer.isGuest,
            };
          }
        );
        setOriginalItems(minifiedCustomers);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const renderCustomerName = useCallback(
    (customerName: string, customer: MinifiedCustomer) => {
      return (
        <span className={'customer-name'}>
          {displayStringHighlighted(customerName, query)}
          {customer.isGuest ? (
            <IconGuest
              className={'customer-name-isGuest'}
              fill={'var(--color-primary)'}
            />
          ) : null}
        </span>
      );
    },
    [query]
  );

  const renderEmail = useCallback((email: string) => {
    return <div className={'list-table-td-link'}>{email}</div>;
  }, []);

  const renderRank = useCallback((rank: LoyaltyRankCustomerHistoryResponse) => {
    return (
      <img
        src={
          rank.loyaltyRank?.asset?.[i18n.language as TranslatedStringIndex]
            ?.uri ?? undefined
        }
        className={'list-table-td-img-img'}
        alt={'Rank Icon'}
      />
    );
  }, []);

  return (
    <Has authorizations={['customers:list']} showNoAuthorization>
      {currentItems !== null ? (
        <Stage>
          <Navigator title={t('title')}></Navigator>
          <Card bigScreenWidth="100%">
            <CardSection>
              <List
                items={currentItems}
                ignore={['id', 'isGuest']}
                dateStrings={['createdAt']}
                monoSpaceStrings={['customerNumber']}
                linkedKey={'id'}
                linkPrefix={() => '/customers/'}
                queryString={query}
                queryKeys={['name', 'email', 'customerNumber']}
                renderObjects={[
                  {
                    key: 'name',
                    renderMethod: renderCustomerName,
                  },
                  {
                    key: 'email',
                    renderMethod: renderEmail,
                  },
                  {
                    key: 'activeRank',
                    renderMethod: renderRank,
                  },
                ]}
                pageItemCountOptions={[25, 50, 100]}
                adjustHeightToViewport
                adjustHeightToViewportOffset={140}
                listControls={{
                  search: listControlSearch,
                }}
              />
            </CardSection>
          </Card>
        </Stage>
      ) : (
        <LoadingContainer />
      )}
    </Has>
  );
};

export default Customers;
