import './columnheadermapping.css';
import Badge from '../../../../../elements/badge/Badge';
import Hoverable from '../../../../../elements/hoverable/Hoverable';
import DropdownMenu from '../../../../../elements/dropdownmenu/DropdownMenu';
import Button from '../../../../../elements/button/Button';
import {
  ColumnHeaderMappingError,
  ColumnHeaderMappingType,
} from '../hooks/useDefaultMappings';
import { useTranslation } from 'react-i18next';
import { SheetData } from '../../ProductImporter';
import { ReactNode, useCallback, useState } from 'react';
import { ReactComponent as IconError } from '../../../../../../assets/icon/warning.svg';
import { ReactComponent as IconArrow } from '../../../../../../assets/icon/right-arrow.svg';
import { ReactComponent as IconTrash } from '../../../../../../assets/icon/trash.svg';
import { ReactComponent as IconString } from '../../../../../../assets/icon/jsontable/textedit.svg';
import { ReactComponent as IconTranslated } from '../../../../../../assets/icon/jsontable/translate.svg';
import { ReactComponent as IconNumber } from '../../../../../../assets/icon/jsontable/123.svg';
import { ReactComponent as IconA } from '../../../../../../assets/icon/jsontable/a.svg';
import { ReactComponent as IconCheckbox } from '../../../../../../assets/icon/jsontable/checkbox.svg';
import { ReactComponent as IconLink } from '../../../../../../assets/icon/jsontable/link.svg';
import { ReactComponent as IconStar } from '../../../../../../assets/icon/special.svg';
import { Check } from '../../../../../elements/selectors/Selectors';

interface ColumnHeaderMappingProps {
  mapping: ColumnHeaderMappingType;
  active: boolean;
  onSetActiveMappingKey: (key: string) => void;
  sheetData: SheetData;
  index: number;
  completedMappings: ColumnHeaderMappingType[];
  onMapping: (mappedKey: string, index: number) => void;
  onDeleteMapping: (index: number) => void;
}

const ColumnHeaderMapping: React.FC<ColumnHeaderMappingProps> = ({
  mapping,
  active,
  onSetActiveMappingKey,
  sheetData,
  index,
  completedMappings,
  onDeleteMapping,
  onMapping,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.productBulkEditor.productImporter.columnHeaderConfig',
  });

  const getInitialIsEnabled = () => {
    if (mapping.locked) {
      return false;
    } else {
      return !sheetData.productIdKeyMappedKey;
    }
  };

  const [isEnabled, setIsEnabled] = useState(getInitialIsEnabled());
  const mapped = !!mapping.mappedKey;

  const renderMappingErrors = useCallback(
    (errors: ColumnHeaderMappingError[]) => {
      // combine errors that have the exact same messages
      const combinedErrors: (ColumnHeaderMappingError & {
        count: number;
      })[] = [];
      errors.forEach((error) => {
        const i = combinedErrors.findIndex(
          (err) =>
            err.identifier === error.identifier &&
            JSON.stringify(err.messages) === JSON.stringify(error.messages)
        );
        if (i > -1) {
          combinedErrors[i].count += 1;
        } else {
          combinedErrors.push({ ...error, count: 1 });
        }
      });

      return (
        <div className={'columnHeaderMapping-mapping-errors'}>
          {combinedErrors.map((err) => (
            <div className={'columnHeaderMapping-mapping-error'}>
              <div className={'columnHeaderMapping-mapping-error-messages'}>
                {err.messages.map((msg) => (
                  <div className={'columnHeaderMapping-mapping-error-message'}>
                    {msg}
                  </div>
                ))}
              </div>
              {err.count > 1 ? (
                <div className={'columnHeaderMapping-mapping-error-count'}>
                  {err.count}x
                </div>
              ) : null}
            </div>
          ))}
        </div>
      );
    },
    []
  );

  return (
    <div
      key={mapping.key}
      className={`columnHeaderMapping ${
        !isEnabled || mapping.locked
          ? 'columnHeaderMapping_disabled'
          : active
          ? 'columnHeaderMapping_selected'
          : ''
      } ${mapping.locked ? 'columnHeaderMapping_locked' : ''}`}
      onClick={() => {
        onSetActiveMappingKey(mapping.key);
        if (!mapping.locked && !isEnabled) {
          setIsEnabled(true);
        }
      }}
    >
      <div className={'columnHeaderMapping-title'}>
        {sheetData.productIdKeyMappedKey ? (
          <Check
            checked={isEnabled || !!mapping.locked}
            update={() => setIsEnabled(!isEnabled)}
            disabled={mapping.locked}
            helperCSSClass={'columnHeaderMapping-title-check'}
          />
        ) : null}
        {t(`headers.${mapping.key}`)}
        {sheetData.productIdKey === mapping.key ? (
          <Badge title={t('idKey')} color={'var(--color-danger)'} />
        ) : null}
      </div>
      <div className={'columnHeaderMapping-mapping'}>
        {mapping.errors ? (
          <Hoverable
            onHoverNode={renderMappingErrors(mapping.errors)}
            helperClass={'columnHeaderMapping-mapping-icon'}
            sticky
          >
            <IconError
              className={
                'columnHeaderMapping-mapping-icon columnHeaderMapping-mapping-icon__error'
              }
            />
          </Hoverable>
        ) : (
          <IconArrow
            className={'columnHeaderMapping-mapping-icon'}
            fill={'var(--color-text_tertiary)'}
          />
        )}
        <div className={'columnHeaderMapping-mapping-dropdown'}>
          <DropdownMenu
            options={[...sheetData.headers]}
            selected={mapping.mappedKey}
            onSelect={(value) => onMapping(value, index)}
            highlightedOptions={completedMappings.map((x) => x.mappedKey ?? '')}
            errors={
              mapping.errors?.find((err) => err.identifier === 'mappedKey')
                ?.messages ?? undefined
            }
            disabled={!isEnabled || mapping.locked}
          />
        </div>
        {!mapping.locked && mapped ? (
          <div className={'columnHeaderMapping-mapping-button'}>
            <Button
              type={'icon'}
              look={'secondary'}
              margin={'left'}
              action={() => onDeleteMapping(index)}
            >
              <IconTrash className={'button-icon button-icon-danger'} />
            </Button>
          </div>
        ) : null}
      </div>
      <div className={'columnHeaderMapping-icon'}>
        {columnHeaderMappingContentTypeIcons[mapping.contentType]}
      </div>
    </div>
  );
};

export default ColumnHeaderMapping;

export const columnHeaderMappingContentTypeIcons: {
  [key: string]: ReactNode;
} = {
  0: (
    <IconString
      fill={'var(--color-text_tertiary)'}
      className={'columnHeaderConfig-contentTypeIcon'}
    />
  ),
  1: (
    <IconTranslated
      fill={'var(--color-text_tertiary)'}
      className={'columnHeaderConfig-contentTypeIcon'}
    />
  ),
  2: (
    <IconNumber
      fill={'var(--color-text_tertiary)'}
      className={'columnHeaderConfig-contentTypeIcon'}
    />
  ),
  3: (
    <IconA
      fill={'var(--color-text_tertiary)'}
      className={'columnHeaderConfig-contentTypeIcon'}
    />
  ),
  4: (
    <IconTranslated
      fill={'var(--color-text_tertiary)'}
      className={'columnHeaderConfig-contentTypeIcon'}
    />
  ),
  5: (
    <IconCheckbox
      fill={'var(--color-text_tertiary)'}
      className={'columnHeaderConfig-contentTypeIcon'}
    />
  ),
  6: (
    <IconLink
      fill={'var(--color-text_tertiary)'}
      className={'columnHeaderConfig-contentTypeIcon'}
    />
  ),
  7: (
    <IconStar
      fill={'var(--color-text_tertiary)'}
      className={'columnHeaderConfig-contentTypeIcon'}
    />
  ),
};
