import './adyenwizard.css';
import Card, { CardSection } from '../../../../elements/card/Card';
import { EmptyState } from '../../../../elements/emptystate/EmptyState';
import Button from '../../../../elements/button/Button';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Popup from '../../../../elements/popup/Popup';
import CompanyType from './companytype/CompanyType';
import Shareholders, { TempShareholderInfo } from './shareholders/Shareholders';
import {
  AdyenAccountHolderResponse,
  AdyenLegalEntityResponse,
  AdyenTransferInstrumentResponse,
} from '../../../../api/petcloudapi/api';
import CompanyInfo from './companyinfo/CompanyInfo';
import TransferInstrument from './bankaccount/TransferInstrument';
import AccountHolder from './accountholder/AccountHolder';
import BalanceAccount from './balanceaccount/BalanceAccount';
import done from '../../../../../assets/img/done.svg';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import useNotifications from '../../../../hooks/useNotifications';
import SoleProprietorship from './soleproprietorship/SoleProprietorship';
import SoleProprietorshipIndividual from './soleproprietorshipindividual/SoleProprietorshipIndividual';

export enum AdyenWizardStep {
  CompanyType,
  Shareholders,
  CompanyInfo,
  SoleProprietorshipCompanyInfo,
  SoleProprietorshipIndividual,
  TransferInstrument,
  AccountHolder,
  BalanceAccount,
}

interface AdyenWizardProps {
  manufacturerId: string;
  legalEntities: AdyenLegalEntityResponse[] | null;
  transferInstruments: AdyenTransferInstrumentResponse[] | null;
  accountHolders: AdyenAccountHolderResponse[] | null;
  isComplete: boolean;
}

const AdyenWizard: React.FC<AdyenWizardProps> = ({
  manufacturerId,
  legalEntities,
  transferInstruments,
  accountHolders,
  isComplete,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.manufacturer.adyenWizard',
  });
  const api = usePetCloudApi();
  const manufacturersApi = api.manufacturersApi();
  const errorHandler = useErrorHandler();
  const { pushNotification } = useNotifications();

  const [popup, setPopup] = useState(false);
  const [popupWidth, setPopupWidth] = useState('50%');
  const [step, setStep] = useState<AdyenWizardStep>(
    AdyenWizardStep.CompanyType
  );
  const [tempShareholderInfo, setTempShareholderInfo] = useState<
    TempShareholderInfo[]
  >([]);
  // legalEntityId required for creating transfer instrument and account holder
  const [legalEntityId, setLegalEntityId] = useState('');
  const [soleProprietorshipId, setSoleProprietorshipId] = useState('');
  const [legalEntityName, setLegalEntityName] = useState('');
  const [accountHolderId, setAccountHolderId] = useState('');

  // called after the wizard concludes to ensure the manufacturer is properly updated
  const refreshManufacturer = () => {
    manufacturersApi
      .manufacturersGetManufacturerById(manufacturerId)
      .then((response) => {
        console.log(response);
        manufacturersApi
          .manufacturersUpdateManufacturer(manufacturerId, response.data)
          .then((response) => {
            console.log(response);
            pushNotification(t('notifications.manufacturerRefresh_successful'));
          })
          .catch((error) => {
            console.log(error);
            errorHandler.addError(error.response);
          });
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const renderStep = () => {
    switch (step) {
      case AdyenWizardStep.CompanyType:
        return <CompanyType setStep={setStep} setPopupWidth={setPopupWidth} />;
      case AdyenWizardStep.Shareholders:
        return (
          <Shareholders
            setStep={setStep}
            setPopupWidth={setPopupWidth}
            manufacturerId={manufacturerId}
            commitTempShareholderInfo={setTempShareholderInfo}
            fetchedLegalEntities={legalEntities}
          />
        );
      case AdyenWizardStep.SoleProprietorshipCompanyInfo:
        return (
          <SoleProprietorship
            setStep={setStep}
            manufacturerId={manufacturerId}
            setSoleProprietorshipLegalEntityId={setSoleProprietorshipId}
            setLegalEntityName={setLegalEntityName}
            fetchedLegalEntities={legalEntities}
          />
        );
      case AdyenWizardStep.SoleProprietorshipIndividual:
        return (
          <SoleProprietorshipIndividual
            setStep={setStep}
            manufacturerId={manufacturerId}
            soleProprietorshipLegalEntityId={soleProprietorshipId}
            fetchedLegalEntities={legalEntities}
            setLegalEntityId={setLegalEntityId}
          />
        );
      case AdyenWizardStep.CompanyInfo:
        return (
          <CompanyInfo
            setStep={setStep}
            manufacturerId={manufacturerId}
            tempShareholderInfo={tempShareholderInfo}
            fetchedLegalEntities={legalEntities}
            setLegalEntityId={setLegalEntityId}
            setLegalEntityName={setLegalEntityName}
          />
        );
      case AdyenWizardStep.TransferInstrument:
        return (
          <TransferInstrument
            setStep={setStep}
            legalEntityId={legalEntityId}
            fetchedTransferInstruments={transferInstruments}
          />
        );
      case AdyenWizardStep.AccountHolder:
        return (
          <AccountHolder
            setStep={setStep}
            legalEntityId={legalEntityId}
            legalEntityName={legalEntityName}
            manufacturerId={manufacturerId}
            fetchedAccountHolders={accountHolders}
            setAccountHolderId={setAccountHolderId}
          />
        );
      case AdyenWizardStep.BalanceAccount:
        return (
          <BalanceAccount
            legalEntityName={legalEntityName}
            closePopup={() => setPopup(false)}
            accountHolderId={accountHolderId}
            refreshManufacturer={refreshManufacturer}
          />
        );
    }
  };

  return (
    <div className="adyenWizard">
      <Card bigScreenWidth={'100%'}>
        <CardSection>
          <EmptyState
            img={isComplete ? done : undefined}
            size={160}
            message={isComplete ? t('isComplete') : undefined}
          />
          {!isComplete ? (
            <div className={'adyenWizard-startButton'}>
              <Button
                cta={
                  legalEntities && legalEntities.length > 0
                    ? t('cta_continue')
                    : t('cta_start')
                }
                look={!isComplete ? 'primary' : 'secondary'}
                width={'minimal'}
                action={() => setPopup(true)}
              />
            </div>
          ) : null}
        </CardSection>
      </Card>
      <Popup
        toggled={popup}
        width={popupWidth}
        close={() => {
          setPopup(false);
          setStep(AdyenWizardStep.CompanyType);
        }}
      >
        {renderStep()}
      </Popup>
    </div>
  );
};

export default AdyenWizard;
