import { Navigator, Stage } from '../../layout/stage/Stage';
import { useTranslation } from 'react-i18next';
import Card, { CardSection } from '../../elements/card/Card';
import SupportInfo from '../../sections/settings/supportinfo/SupportInfo';

const Support: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Stage>
      <Navigator title={t('view.support.title')}></Navigator>
      <Card bigScreenWidth={'100%'}>
        <CardSection>
          <SupportInfo />
        </CardSection>
      </Card>
    </Stage>
  );
};

export default Support;
