import './productmapping.css';
import { useTranslation } from 'react-i18next';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import useNotifications from '../../../../hooks/useNotifications';
import { useCallback, useEffect, useState } from 'react';
import {
  ProductMapping,
  ProductMappingItem,
  ProductMappingStrategy,
} from '../../../../api/shopifyapi';
import ProductMappingTuple from './productmappingtuple/ProductMappingTuple';
import Searchbar from '../../../../elements/searchbar/Searchbar';
import Button from '../../../../elements/button/Button';
import { Check } from '../../../../elements/selectors/Selectors';
import { LoadingContainer } from '../../../../elements/loading/Loading';
import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { NoResults } from '../../../../elements/emptystate/EmptyState';
import ToggleSwitch from '../../../../elements/toggleswitch/ToggleSwitch';
import _ from 'lodash';
import useIntegrationProductsApi, {
  CommonProductImportConfig,
} from './hooks/useIntegrationProductsApi';
import useProductMappingHelpers from './hooks/useProductMappingHelpers';
import { IntegrationIdentifier } from '../../integrationsubmodules/inpettoclientbased/productimport/InpettoClientBasedProductImport';

export type ProductMappingStatus =
  | 'matched'
  | 'update'
  | 'unlinked'
  | 'create'
  | 'proposal';

export type ExtendedProductMapping = ProductMapping & {
  selected: boolean;
  disabled?: boolean;
  id: string;
  status: ProductMappingStatus;
};

interface ProductMappingProps {
  manufacturerId: string;
  integrationSubModuleId: string;
  integrationIdentifier: IntegrationIdentifier;
}

const ProductMappings: React.FC<ProductMappingProps> = ({
  manufacturerId,
  integrationSubModuleId,
  integrationIdentifier,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.integration.common.productMapping',
  });
  const {
    productsConfigGet,
    productsMappingsGet,
    productsMappingsPost,
    productsConfigPost,
  } = useIntegrationProductsApi(integrationIdentifier, manufacturerId);
  const { getMatchStatus } = useProductMappingHelpers();
  const errorHandler = useErrorHandler();
  const { pushNotification } = useNotifications();

  const [mappings, setMappings] = useState<ExtendedProductMapping[] | null>(
    null
  );
  const [hideMatches, setHideMatches] = useState(false);
  const [sortByMatches, setSortByMatches] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [config, setConfig] = useState<CommonProductImportConfig | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const [query, setQuery] = useState<string | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 5,
      },
    })
  );

  const getProductMappings = (config: CommonProductImportConfig) => {
    productsMappingsGet(config).then((result) => {
      setMappings(result);
    });
  };

  useEffect(() => {
    productsConfigGet(manufacturerId)
      .then((response) => {
        setConfig(response);
        getProductMappings(response);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const config = {
            enabled: false,
            manufacturerId: manufacturerId,
            integrationSubModuleId: integrationSubModuleId,
            productVariantIds: [],
            productIds: [],
          };
          setConfig(config);
          getProductMappings(config);
        } else {
          errorHandler.addError(error.response);
        }
      });
  }, []);

  const getFilteredMappings = (
    query: string,
    mappings: ExtendedProductMapping[]
  ) => {
    return [
      ...mappings.filter((mapping) => {
        const { externalProduct } = mapping;
        const { domainProduct } = mapping;
        return (
          searchProduct(query, externalProduct) ||
          searchProduct(query, domainProduct)
        );
      }),
    ];
  };

  const toggleMapping = (mappingId: string) => {
    if (mappings) {
      const update = [...mappings];
      const index = update.findIndex((x) => x.id === mappingId);
      if (index > -1) {
        const { selected } = update[index];
        update[index] = {
          ...update[index],
          selected: !selected,
        };

        const groupBracket = getGroupBracket(update[index], mappings, index);

        //if the selected tuple is a parent tuple, also toggle all its children
        if (groupBracket === 'start') {
          for (let i = index + 1; i < mappings.length; i++) {
            const g = getGroupBracket(update[i], mappings, i);
            if (g === 'middle' || g === 'end') {
              update[i] = {
                ...update[i],
                selected: !selected,
              };
            } else {
              break;
            }
          }
        }

        //if the selected tuple is a variant tuple, also select its parent
        if (groupBracket === 'middle' || groupBracket === 'end') {
          for (let i = index - 1; i >= 0; i--) {
            const g = getGroupBracket(update[i], mappings, i);
            if (g === 'start') {
              update[i] = {
                ...update[i],
                selected: true,
              };
              break;
            }
          }
        }
      }
      setMappings(update);
    }
  };

  const selectAllTuples = () => {
    if (mappings) {
      let bool = true;
      if (mappings.every((mapping) => mapping.selected)) {
        bool = false;
      }

      const update = [...mappings];
      update.forEach((mapping) => {
        mapping.selected = bool;
      });
      setMappings(update);
    }
  };

  const unlinkDomainProduct = (mappingId: string) => {
    if (mappings) {
      const update = [...mappings];
      const index = update.findIndex((x) => x.id === mappingId);
      if (index > -1) {
        const externalProduct = mappings[index].externalProduct;
        const domainProduct = mappings[index].domainProduct;

        if (externalProduct) {
          update[index] = {
            ...update[index],
            domainProduct: undefined,
            status: 'create',
          };
          update.push({
            externalProduct: undefined,
            domainProduct: domainProduct,
            id: crypto.randomUUID(),
            disabled: true,
            selected: false,
            status: 'unlinked',
          });
          setMappings(update);
        }
      }
    }
  };

  const submitMappings = () => {
    if (mappings) {
      setIsSubmitting(true);
      productsMappingsPost(mappings)
        .then(() => {
          submitConfig();
        })
        .catch(() => {
          setIsSubmitting(false);
        });
    }
  };

  const submitConfig = () => {
    if (mappings) {
      const selectedMappings = mappings.filter((mapping) => mapping.selected);
      const productIds: string[] = [];
      const productVariantIds: string[] = [];
      selectedMappings.forEach((mapping) => {
        const shopifyProductId = mapping.externalProduct?.id;
        const shopifyParentProductId = mapping.externalProduct?.parentId;
        if (shopifyProductId) {
          if (shopifyParentProductId) {
            productVariantIds.push(shopifyProductId);
          } else {
            productIds.push(shopifyProductId);
          }
        }
      });
      const request: CommonProductImportConfig = {
        ...config,
        productIds: productIds,
        productVariantIds: productVariantIds,
        enabled: productIds.length > 0 && productVariantIds.length > 0,
        integrationSubModuleId: integrationSubModuleId,
      };

      productsConfigPost(request)
        .then(() => {
          pushNotification(t('notifications.update_successful'));
          setIsSubmitting(false);
        })
        .catch(() => {
          setIsSubmitting(false);
        });
    }
  };

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over) {
      const activeMappingId = active.id.split('_')[0];
      const overMappingId = over.id.split('_')[0];
      mapProduct(activeMappingId, overMappingId);
    }
  };

  const mapProduct = (activeMappingId: string, overMappingId: string) => {
    console.log(activeMappingId, overMappingId);
    if (mappings) {
      const update = [...mappings];

      const activeMappingIndex = mappings?.findIndex(
        (x) => x.id === activeMappingId
      );

      const overMappingIndex = mappings?.findIndex(
        (x) => x.id === overMappingId
      );

      const activeDomainProduct = update[activeMappingIndex].domainProduct;
      const overDomainProduct = update[overMappingIndex].domainProduct;

      const updatedActiveMapping = {
        ...update[activeMappingIndex],
        domainProduct: overDomainProduct,
        strategy: ProductMappingStrategy.Unknown,
      };
      updatedActiveMapping.status = getMatchStatus(updatedActiveMapping);

      // if the tuple is empty, remove it
      if (
        !updatedActiveMapping.externalProduct &&
        !updatedActiveMapping.domainProduct
      ) {
        update.splice(activeMappingIndex, 1);
      } else {
        update[activeMappingIndex] = updatedActiveMapping;
      }

      const updatedOverMapping = {
        ...update[overMappingIndex],
        domainProduct: activeDomainProduct,
      };
      updatedOverMapping.status = 'update';
      update[overMappingIndex] = updatedOverMapping;

      setMappings(update);
    }
  };

  const getGroupBracket = useCallback(
    (
      mapping: ProductMapping,
      mappings: ExtendedProductMapping[],
      currentIndex: number
    ) => {
      const nextMapping = mappings[currentIndex + 1];
      const previousMapping = mappings[currentIndex - 1] ?? undefined;
      const previousMappingId = previousMapping?.externalProduct?.id;
      const previousMappingParentId =
        previousMapping?.externalProduct?.parentId;
      const parentId = mapping.externalProduct?.parentId;
      if (parentId) {
        const nextMappingParentId = nextMapping?.externalProduct?.parentId;
        if (nextMappingParentId && nextMappingParentId === parentId) {
          return 'middle';
        } else {
          if (
            previousMappingParentId === parentId ||
            previousMappingId === parentId
          ) {
            return 'end';
          }
          return undefined;
        }
      } else {
        const isParent =
          !!mapping.externalProduct?.id &&
          nextMapping?.externalProduct?.parentId ===
            mapping.externalProduct?.id;
        if (isParent) {
          return 'start';
        } else {
          return undefined;
        }
      }
    },
    [mappings]
  );

  const isSomeSelected = mappings?.some((mapping) => mapping.selected);

  const shouldRender = useCallback(
    (mapping: ProductMapping) => {
      if (hideMatches) {
        return !(mapping.externalProduct && mapping.domainProduct);
      } else {
        return true;
      }
    },
    [hideMatches]
  );

  const renderTuples = (mappings: ExtendedProductMapping[]) => {
    if (mappings.length === 0) {
      return (
        <div className={'productMapping-list-tuples-noResults'}>
          <NoResults />
        </div>
      );
    } else {
      let data = mappings;

      if (sortByMatches) {
        data = _.sortBy(
          data,
          (mapping) => mapping.domainProduct && mapping.externalProduct
        );
      }

      return data.map((mapping, index) => {
        if (shouldRender(mapping)) {
          return (
            <ProductMappingTuple
              key={index}
              mapping={mapping}
              groupBracket={getGroupBracket(mapping, data, index)}
              onSelect={() => toggleMapping(mapping.id)}
              onProposalConfirm={() => {
                setMappings((prev) => {
                  if (prev) {
                    const update = [...prev];
                    const i = update.findIndex((x) => x.id === mapping.id);
                    if (i > 0) {
                      update[i] = {
                        ...update[i],
                        status: 'matched',
                      };
                    }
                    return update;
                  } else {
                    return prev;
                  }
                });
              }}
              disabled={!!mapping.disabled}
              selected={mapping.selected}
              setIsDragging={setIsDragging}
              isDragging={isDragging}
              unlinkDomainProduct={() => unlinkDomainProduct(mapping.id)}
              mappings={data}
              mapProduct={mapProduct}
            />
          );
        } else {
          return null;
        }
      });
    }
  };

  if (mappings && config) {
    return (
      <div className={'productMapping'}>
        <div className={'popup-title'}>{t('title')}</div>
        <div className={'productMapping-list'}>
          <div className={'productMapping-list-header'}>
            <div className={'productMapping-list-header-main'}>
              <div className={'productMapping-list-header-main-group'}>
                <Searchbar
                  cta={t('search.cta_searchShopify')}
                  height={'normal'}
                  onDebouncedSearch={(q) => {
                    console.log(q);
                    setQuery(q);
                  }}
                />
                <ToggleSwitch
                  toggled={sortByMatches}
                  label={t('sortByMatches')}
                  toggle={() => setSortByMatches(!sortByMatches)}
                  smallSwitch
                  smallLabel
                />
                <ToggleSwitch
                  toggled={hideMatches}
                  label={t('hideMatches')}
                  toggle={() => setHideMatches(!hideMatches)}
                  smallSwitch
                  smallLabel
                />
              </div>
              <Button
                cta={t('save')}
                look={'save'}
                width={'minimal'}
                action={submitMappings}
                isLoading={isSubmitting}
              />
            </div>
            <div className={'productMapping-list-header-sub'}>
              <div className={'productMapping-list-header-sub-half'}>
                <div
                  className={'productMapping-list-header-sub-half-selectAll'}
                >
                  <Check
                    checked={mappings.every((mapping) => mapping.selected)}
                    halfChecked={isSomeSelected}
                    update={selectAllTuples}
                  />
                  {isSomeSelected ? (
                    <div
                      className={
                        'productMapping-list-header-sub-half-selectAll-count'
                      }
                    >
                      {`${mappings.filter((x) => x.selected).length} ${t(
                        'select_count'
                      )}`}
                    </div>
                  ) : null}
                </div>
                <div className={'productMapping-list-header-sub-half-title'}>
                  {t('products_shopify')}
                </div>
              </div>
              <div
                className={
                  'productMapping-list-header-sub-half productMapping-list-header-sub-half__right'
                }
              >
                <div className={'productMapping-list-header-sub-half-title'}>
                  {t('products_portal')}
                </div>
              </div>
            </div>
          </div>
          <div className={'productMapping-list-tuples-wrapper'}>
            <div className={'productMapping-list-tuples'}>
              <DndContext onDragEnd={onDragEnd} sensors={sensors}>
                {query && query !== ''
                  ? renderTuples(getFilteredMappings(query, mappings))
                  : renderTuples(mappings)}
              </DndContext>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <LoadingContainer message={t('loading')} />;
  }
};

export default ProductMappings;

export const searchProduct = (query: string, item?: ProductMappingItem) => {
  if (item) {
    const { name, description, sku, ean } = item;
    const searchQuery = query.toLowerCase();
    return (
      name?.toLowerCase().includes(searchQuery) ||
      description?.toLowerCase().includes(searchQuery) ||
      sku?.toLowerCase().includes(searchQuery) ||
      ean?.toLowerCase().includes(searchQuery)
    );
  } else {
    return false;
  }
};
