import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Has, useAuthorization } from '../../contexts/auth/Authorization';
import {
  Navigator,
  Stage,
  StageColumn,
  StageColumns,
} from '../../layout/stage/Stage';
import Button from '../../elements/button/Button';
import Card, { CardSection, Tab, Tabs } from '../../elements/card/Card';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { useParams } from 'react-router-dom';
import ManufacturerLogo from '../../sections/manufacturer/manufacturerlogo/ManufacturerLogo';
import ManufacturerInfo from '../../sections/manufacturer/manufacturerinfo/ManufacturerInfo';
import ManufacturerAddress from '../../sections/manufacturer/manufactureraddress/ManufacturerAddress';
import ManufacturerConfiguration from '../../sections/manufacturer/manufacturerconfiguration/ManufacturerConfiguration';
import TeamMembers from '../../sections/settings/teammembers/TeamMembers';
import { Dropdown } from '../../elements/selectors/Selectors';
import { Store } from 'react-notifications-component';
import {
  IntegrationResponse,
  ManufacturerResponse,
  UpdateManufacturerRequest,
} from '../../api/petcloudapi/api';
import { LoadingContainer } from '../../elements/loading/Loading';
import { NoAuthorization } from '../../elements/emptystate/EmptyState';
import ManufacturerConditions from '../../sections/manufacturer/manufacturerconditions/ManufacturerConditions';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import AdyenOverview from '../../sections/manufacturer/adyenoverview/AdyenOverview';
import DocumentLayout from '../../sections/manufacturer/documentlayout/DocumentLayout';
import ShopifySetup from '../../sections/manufacturer/integrationsetups/shopifysetup/ShopifySetup';

const Manufacturer = () => {
  const { t } = useTranslation();
  const { authorizations } = useAuthorization();
  const [manufacturer, setManufacturer] = useState<
    UpdateManufacturerRequest | ManufacturerResponse | null
  >(null);
  const [integrations, setIntegrations] = useState<
    IntegrationResponse[] | null
  >(null);
  const [submitting, setSubmitting] = useState(false);
  const [manufacturerHasChanged, setManufacturerHasChanged] = useState(false);

  const { id } = useParams();
  const api = usePetCloudApi();
  const manufacturersApi = api.manufacturersApi();
  const integrationsApi = api.integrationsApi();
  const errorHandler = useErrorHandler();

  useEffect(() => {
    if (!manufacturer) {
      getManufacturer();
    }
    getIntegrations();
  }, [manufacturer]);

  const getManufacturer = () => {
    if (id) {
      manufacturersApi
        .manufacturersGetManufacturerById(id)
        .then((response) => {
          console.log(response);
          setManufacturer({ ...response.data });
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  };

  const getIntegrations = () => {
    integrationsApi
      .integrationsGetIntegrations(id)
      .then((response) => {
        console.log(response);
        setIntegrations(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const update = (manufacturer: UpdateManufacturerRequest) => {
    setManufacturer({ ...manufacturer });
    setManufacturerHasChanged(true);
  };

  const updateStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.currentTarget.value === 'active') {
      if (manufacturer) {
        setManufacturer({ ...manufacturer, enabled: true });
        setManufacturerHasChanged(true);
      }
    } else {
      if (manufacturer) {
        setManufacturer({ ...manufacturer, enabled: false });
        setManufacturerHasChanged(true);
      }
    }
  };

  const save = () => {
    const submissionObject = manufacturer;
    setSubmitting(true);

    if (submissionObject?.companyAddress) {
      const emptyAddress = Object.values(submissionObject.companyAddress).every(
        (e) => e === null || e === ''
      );
      if (emptyAddress) {
        submissionObject.companyAddress = null;
      }
    }

    if (id && submissionObject) {
      manufacturersApi
        .manufacturersUpdateManufacturer(id, submissionObject)
        .then((response) => {
          console.log(response);
          setSubmitting(false);
          setManufacturerHasChanged(false);
          Store.addNotification({
            message: 'Erfolgreich geändert!',
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
        })
        .catch((error) => {
          console.log(error);
          setSubmitting(false);
          setManufacturerHasChanged(false);
          errorHandler.addError(error.response);
        });
    }
  };

  const tabs = [
    { title: t('view.manufacturer.tabs.general'), key: 'general' },
    { title: t('view.manufacturer.tabs.adyen_config'), key: 'adyen_config' },
    {
      title: t('view.manufacturer.tabs.documentLayout'),
      key: 'document_layout',
    },
    {
      title: t('view.manufacturer.tabs.integrations'),
      key: 'integrations',
    },
  ];

  if (authorizations?.includes('manufacturers:detail')) {
    return (
      <Stage>
        {manufacturer ? (
          <>
            <Navigator
              title={manufacturer.companyName}
              backLink="/manufacturers"
              allowBackButton
            ></Navigator>
            <Tabs tabs={tabs} look="card">
              <Tab>
                <StageColumns>
                  <StageColumn size={'two'}>
                    <Card bigScreenWidth="100%">
                      <CardSection
                        title={t('view.manufacturer.meta.logo.title')}
                      >
                        <ManufacturerLogo
                          manufacturer={manufacturer}
                          logo={
                            (manufacturer as ManufacturerResponse).logoAsset
                              ?.uri ?? null
                          }
                          updateCurrentManufacturer={update}
                        />
                      </CardSection>
                      <CardSection
                        title={t('view.manufacturer.meta.info.title')}
                      >
                        <ManufacturerInfo
                          t={t}
                          manufacturer={manufacturer}
                          updateCurrentManufacturer={update}
                        />
                      </CardSection>
                      <CardSection
                        title={t('view.manufacturer.meta.configuration.title')}
                      >
                        <ManufacturerConfiguration
                          t={t}
                          manufacturer={manufacturer}
                          updateCurrentManufacturer={update}
                        />
                      </CardSection>
                      <CardSection
                        title={t('view.manufacturer.meta.address.title')}
                      >
                        <ManufacturerAddress
                          t={t}
                          manufacturer={manufacturer}
                          updateCurrentManufacturer={update}
                        />
                      </CardSection>
                      <Has authorizations={['manufacturers:edit']}>
                        <CardSection noTopPadding>
                          <div className={'global-cardActions-postBorder'}>
                            <Button
                              cta={t('actions.save')}
                              margin="left"
                              look="save"
                              action={manufacturerHasChanged ? save : undefined}
                              isLoading={submitting}
                              active={manufacturerHasChanged}
                            ></Button>
                          </div>
                        </CardSection>
                      </Has>
                    </Card>
                    <Card
                      bigScreenWidth="100%"
                      title={t('view.manufacturer.members.title')}
                    >
                      <CardSection>
                        <TeamMembers
                          manufacturer={manufacturer}
                          t={t}
                          refreshManufacturer={getManufacturer}
                          manufacturerId={id}
                        />
                      </CardSection>
                    </Card>
                    <Card
                      bigScreenWidth="100%"
                      title={t('view.manufacturer.conditions.title')}
                    >
                      <CardSection>
                        <ManufacturerConditions manufacturerId={id} />
                      </CardSection>
                    </Card>
                  </StageColumn>
                  <StageColumn size="one">
                    <Card bigScreenWidth="100%">
                      <CardSection>
                        <Dropdown
                          options={['active', 'inactive']}
                          selected={
                            manufacturer.enabled ? 'active' : 'inactive'
                          }
                          translationPath={'status.'}
                          title={t('view.manufacturer.sidebar.status')}
                          update={updateStatus}
                        />
                      </CardSection>
                    </Card>
                  </StageColumn>
                </StageColumns>
              </Tab>
              <Tab>
                <AdyenOverview
                  manufacturerId={(manufacturer as ManufacturerResponse).id}
                />
              </Tab>
              <Tab>
                <DocumentLayout
                  manufacturer={manufacturer as ManufacturerResponse}
                />
              </Tab>
              <Tab>
                {integrations && id ? (
                  <>
                    <ShopifySetup
                      integration={integrations.find(
                        (x) => x.identifier === 'Shopify'
                      )}
                      manufacturerId={id}
                    />
                  </>
                ) : (
                  <LoadingContainer />
                )}
              </Tab>
            </Tabs>
          </>
        ) : (
          <LoadingContainer />
        )}
      </Stage>
    );
  } else {
    return <NoAuthorization />;
  }
};

export default Manufacturer;
