import { Has } from '../../contexts/auth/Authorization';
import { Navigator, Stage } from '../../layout/stage/Stage';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { useEffect, useState } from 'react';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { ManufacturerDataExchangeResponse } from '../../api/petcloudapi/api';
import { LoadingContainer } from '../../elements/loading/Loading';
import { NoResults } from '../../elements/emptystate/EmptyState';
import List from '../../features/list/List';
import Card, { CardSection } from '../../elements/card/Card';
import Badge from '../../elements/badge/Badge';

const DataExchanges = () => {
  const { t } = useTranslation();
  const api = usePetCloudApi();
  const manufacturerDataExchangesApi = api.manufacturerDataExchangesApi();
  const errorHandler = useErrorHandler();
  const [dataExchanges, setDataExchanges] = useState<
    ManufacturerDataExchangeResponse[] | null
  >(null);

  useEffect(() => {
    getDataExchanges();
  }, []);

  const getDataExchanges = () => {
    manufacturerDataExchangesApi
      .manufacturerDataExchangesGetManufacturerDataExchanges()
      .then((response) => {
        console.log(response);
        setDataExchanges(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const renderDirection = (value: string) => {
    return (
      <Badge
        title={t('list.states.' + value)}
        color={value === 'In' ? 'var(--color-blue)' : 'var(--color-primary)'}
      />
    );
  };

  const renderErrors = (count: number) => {
    if (count === 0) {
      return (
        <Badge
          title={t('view.dataExchanges.noErrors')}
          color={'var(--color-success)'}
        />
      );
    } else {
      return <Badge title={count.toString()} color={'var(--color-danger)'} />;
    }
  };

  return (
    <Has
      authorizations={['manufacturer_data_exchanges:list']}
      showNoAuthorization
    >
      {dataExchanges ? (
        <Stage>
          <Navigator title={t('view.dataExchanges.title')}></Navigator>
          {dataExchanges.length > 0 ? (
            <Card bigScreenWidth={'full'}>
              <CardSection>
                <List
                  items={dataExchanges}
                  ignore={['manufacturerId', 'updatedAt']}
                  dateStrings={['createdAt']}
                  monoSpaceStrings={['id', 'itemCount']}
                  linkedKey={'id'}
                  renderObjects={[
                    {
                      key: 'direction',
                      renderMethod: renderDirection,
                    },
                    {
                      key: 'errorCount',
                      renderMethod: renderErrors,
                    },
                  ]}
                />
              </CardSection>
            </Card>
          ) : (
            <NoResults />
          )}
        </Stage>
      ) : (
        <LoadingContainer />
      )}
    </Has>
  );
};

export default DataExchanges;
