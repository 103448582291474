import { useTranslation } from 'react-i18next';
import { Navigator, Stage } from '../../layout/stage/Stage';
import Card, { CardSection } from '../../elements/card/Card';
import { NoAuthorization } from '../../elements/emptystate/EmptyState';
import { useUser } from '../../contexts/auth/User';
import { default as Config } from '../../sections/admin/productgroupmappings/ProductGroupMappings';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { useEffect, useState } from 'react';
import {
  ProductGroupMappingResponse,
  ProductGroupResponse,
  PropertyValidationRuleResponse,
} from '../../api/petcloudapi/api';
import { LoadingContainer } from '../../elements/loading/Loading';
import Button from '../../elements/button/Button';
import NewProductGroupMapping from '../../sections/admin/productgroupmappings/newproductgroupmapping/NewProductGroupMapping';
import useUserTools from '../../hooks/useUserTools';

const ProductGroupMappings = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.admin.productGroupMappings',
  });
  const { user } = useUser();
  const api = usePetCloudApi();
  const productGroupMappingsApi = api.productGroupMappingsApi();
  const productGroupsApi = api.productGroupsApi();
  const propertyValidationRulesApi = api.propertyValidationRulesApi();
  const errorHandler = useErrorHandler();
  const { excludeUnlockedOptions } = useUserTools();

  const [popup, setPopup] = useState(false);
  const [productGroupMappings, setProductGroupMappings] = useState<
    ProductGroupMappingResponse[] | null
  >(null);
  const [availableProductGroups, setAvailableProductGroups] = useState<
    ProductGroupResponse[] | null
  >(null);
  const [
    availablePropertyValidationRules,
    setAvailablePropertyValidationRules,
  ] = useState<PropertyValidationRuleResponse[] | null>(null);

  useEffect(() => {
    getProductGroupMappings();
    getAvailableProductGroups();
    getAvailablePropertyValidationRules();
  }, []);

  const getProductGroupMappings = () => {
    setProductGroupMappings(null);
    productGroupMappingsApi
      .productGroupMappingsGetProductGroupMappings(
        undefined,
        undefined,
        excludeUnlockedOptions()
      )
      .then((response) => {
        console.log(response);
        setProductGroupMappings(
          response.data.map((m) => {
            const { name, propertyGroup, productGroups, ...rest } = m;
            return { name, propertyGroup, productGroups, ...rest };
          })
        );
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getAvailableProductGroups = () => {
    productGroupsApi
      .productGroupsGetProductGroups()
      .then((response) => {
        console.log(response);
        setAvailableProductGroups(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getAvailablePropertyValidationRules = () => {
    propertyValidationRulesApi
      .propertyValidationRulesGetPropertyValidationRules()
      .then((response) => {
        console.log(response);
        setAvailablePropertyValidationRules(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  if (user?.isProductOwner) {
    if (
      productGroupMappings &&
      availableProductGroups &&
      availablePropertyValidationRules
    ) {
      return (
        <Stage>
          <Navigator title={t('title')}>
            <Button
              cta={t('new.title')}
              width={'minimal'}
              look={'primary'}
              action={() => setPopup(true)}
            />
          </Navigator>
          <Card bigScreenWidth={'full'}>
            <CardSection>
              <Config
                productGroupMappings={productGroupMappings}
                availableProductGroups={availableProductGroups}
                availablePropertyValidationRules={
                  availablePropertyValidationRules
                }
                refreshMappings={getProductGroupMappings}
                refreshAvailablePropertyValidationRules={
                  getAvailablePropertyValidationRules
                }
              />
            </CardSection>
          </Card>
          <NewProductGroupMapping
            availableProductGroups={availableProductGroups}
            availablePropertyValidationRules={availablePropertyValidationRules}
            popup={popup}
            closePopup={() => setPopup(false)}
            refreshMappings={getProductGroupMappings}
            refreshAvailablePropertyValidationRules={
              getAvailablePropertyValidationRules
            }
          />
        </Stage>
      );
    } else {
      return <LoadingContainer />;
    }
  } else {
    return <NoAuthorization />;
  }
};

export default ProductGroupMappings;
