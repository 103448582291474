import './popup.css';
import { ReactComponent as CloseIcon } from '../../../assets/icon/close.svg';
import Button from '../../elements/button/Button';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import useClickOutsideHandler from '../../hooks/useClickOutsideHandler';
import ReactDOM from 'react-dom';

type PopupContextProps = {
  popups: PopupType[];
  addPopup: (popup: PopupType) => void;
  removePopup: (popupId: string) => void;
};

type PopupType = {
  id: string;
  close: () => void;
};

const PopupContext = createContext<PopupContextProps | undefined>(undefined);

export const PopupProvider: React.FC = ({ children }) => {
  const [popups, setPopups] = useState<PopupType[]>([]);

  const addPopup = (popup: PopupType) =>
    setPopups((currentPopups) => [...currentPopups, popup]);

  const removePopup = (popupId: string) =>
    setPopups((currentPopups) =>
      currentPopups.filter((popup) => popup.id !== popupId)
    );

  return (
    <PopupContext.Provider value={{ popups, addPopup, removePopup }}>
      {children}
    </PopupContext.Provider>
  );
};

export const usePopupContext = (): PopupContextProps => {
  const context = useContext(PopupContext);
  if (!context) {
    throw new Error('usePopupContext must be used within an PopupProvider.');
  }
  return context;
};

interface PopupProps {
  toggled: boolean;
  width: string;
  lockWidth?: boolean;
  close: () => void;
  noPadding?: boolean;
  title?: string;
}

const Popup: React.FC<PopupProps> = ({
  toggled,
  width,
  lockWidth,
  close,
  title,
  noPadding,
  children,
}) => {
  const cardRef = useRef(null);
  const { addPopup, removePopup } = usePopupContext();
  const popupId = useRef(crypto.randomUUID());
  useClickOutsideHandler(cardRef, close, popupId.current);

  useEffect(() => {
    if (toggled) {
      addPopup({ id: popupId.current, close });
    } else {
      removePopup(popupId.current);
    }
    return () => removePopup(popupId.current);
  }, [toggled]);

  const modalRoot = document.getElementById('root-popup');

  if (toggled && modalRoot) {
    return ReactDOM.createPortal(
      <div className="popup">
        <div
          ref={cardRef}
          className="popup-card"
          style={{ minWidth: width, width: lockWidth ? width : 'none' }}
        >
          <CloseIcon
            fill="var(--color-gray)"
            className="popup-card-close"
            onClick={(e) => {
              e.stopPropagation();
              close();
            }}
          />
          <div
            className="popup-card-content"
            style={{ padding: noPadding ? 0 : undefined }}
          >
            {title ? (
              <div className="popup-card-content-title">{title}</div>
            ) : null}
            {children}
          </div>
        </div>
      </div>,
      modalRoot
    );
  } else {
    return null;
  }
};

interface DeleteConfirmationProps {
  message: string;
  action: () => void;
  cta: string;
}

export const DeleteConfirmation: React.FC<DeleteConfirmationProps> = ({
  message,
  action,
  cta,
}) => {
  return (
    <div className="popup-card-content-section">
      <div className="popup-card-content-section-text">{message}</div>
      <Button look="danger" action={action} cta={cta} />
    </div>
  );
};

export default Popup;
