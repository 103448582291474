import { useTranslation } from 'react-i18next';
import { Navigator, Stage } from '../../layout/stage/Stage';
import Card, { CardSection } from '../../elements/card/Card';
import { NoAuthorization } from '../../elements/emptystate/EmptyState';
import { useUser } from '../../contexts/auth/User';
import { default as Config } from '../../sections/admin/propertyvalidationrules/PropertyValidationRules';
import { useEffect, useState } from 'react';
import Button from '../../elements/button/Button';
import { PropertyValidationRuleResponse } from '../../api/petcloudapi/api';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import NewPropertyValidationRule from '../../sections/admin/propertyvalidationrules/newpropertyvalidationrule/NewPropertyValidationRule';

const PropertyValidationRules = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.admin.propertyValidationRules',
  });
  const { user } = useUser();
  const api = usePetCloudApi();
  const propertyValidationRulesApi = api.propertyValidationRulesApi();
  const errorHandler = useErrorHandler();
  const [popup, setPopup] = useState(false);
  const [validationRules, setValidationRules] = useState<
    PropertyValidationRuleResponse[] | null
  >(null);

  useEffect(() => {
    getValidationRules();
  }, []);

  const getValidationRules = () => {
    propertyValidationRulesApi
      .propertyValidationRulesGetPropertyValidationRules()
      .then((response) => {
        console.log(response);
        setValidationRules(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  if (user?.isProductOwner) {
    return (
      <Stage>
        <Navigator title={t('title')}>
          <Button
            cta={t('cta')}
            look={'primary'}
            action={() => setPopup(true)}
            width={'minimal'}
          />
        </Navigator>
        <Card bigScreenWidth={'full'}>
          <CardSection>
            <Config
              validationRules={validationRules}
              getValidationRules={getValidationRules}
            />
          </CardSection>
        </Card>
        <NewPropertyValidationRule
          popup={popup}
          closePopup={() => setPopup(false)}
        />
      </Stage>
    );
  } else {
    return <NoAuthorization />;
  }
};

export default PropertyValidationRules;
