import { Navigator, Stage } from '../../layout/stage/Stage';
import { LoadingContainer } from '../../elements/loading/Loading';
import { Has } from '../../contexts/auth/Authorization';
import Card, { CardSection } from '../../elements/card/Card';
import { useEffect, useState } from 'react';
import { PromotionResponse } from '../../api/petcloudapi/api';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { NoAuthorization } from '../../elements/emptystate/EmptyState';
import Button from '../../elements/button/Button';
import { useNavigate } from 'react-router-dom';
import { Store } from 'react-notifications-component';
import useManufacturerConditions from '../../hooks/data/useManufacturerConditions';
import PromotionsList from '../../sections/promotions/promotionslist/PromotionsList';

const Promotions = () => {
  const { t } = useTranslation();
  const { isPromotionAware } = useManufacturerConditions();
  const link = useNavigate();
  const api = usePetCloudApi();
  const promotionsApi = api.promotionsApi();
  const errorHandler = useErrorHandler();
  const [promotions, setPromotions] = useState<PromotionResponse[] | null>(
    null
  );

  useEffect(() => {
    getPromotions();
  }, []);

  const getPromotions = () => {
    promotionsApi
      .promotionsGetPromotions()
      .then((response) => {
        console.log(response);
        setPromotions(
          response.data.map((promotion) => {
            const { name, code, manufacturer, ...rest } = promotion;
            return {
              name: name,
              code: code,
              ...rest,
              manufacturer: manufacturer,
            };
          })
        );
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const deletePromotion = (id: string) => {
    promotionsApi
      .promotionsDeletePromotionById(id)
      .then((response) => {
        console.log(response);
        Store.addNotification({
          message: t('view.promotion.notifications.delete_successful'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
          },
        });
        getPromotions();
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  if (isPromotionAware()) {
    return (
      <Has authorizations={['promotions:list']} showNoAuthorization>
        {promotions !== null ? (
          <Stage>
            <Navigator title={t('view.promotions.title')}>
              <Button
                cta={t('view.promotions.new')}
                look={'primary'}
                width={'minimal'}
                action={() => link('new')}
              />
            </Navigator>
            <Card bigScreenWidth="100%">
              <CardSection>
                <PromotionsList
                  promotions={promotions}
                  deletePromotion={deletePromotion}
                  linked={'id'}
                />
              </CardSection>
            </Card>
          </Stage>
        ) : (
          <LoadingContainer />
        )}
      </Has>
    );
  } else {
    return <NoAuthorization />;
  }
};

export default Promotions;
