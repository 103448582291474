import './shopcustomization.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card, {
  CardSection,
  CardTitle,
  setErrorTab,
  setMultipleErrorTabs,
  Tab,
  TabConfig,
  Tabs,
} from '../../elements/card/Card';
import Input from '../../elements/input/Input';
import Button from '../../elements/button/Button';
import LabelText from '../../elements/labeltext/LabelText';
import { LoadingContainer } from '../../elements/loading/Loading';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import Dropzone from '../../elements/dropzone/Dropzone';
import ColorPicker from '../../elements/colorpicker/ColorPicker';
import {
  AssetResponse,
  BrandResponse,
  BrandStoreResponse,
  CreateBrandStoreRequest,
  ManufacturerResponse,
  UpdateBrandStoreRequest,
} from '../../api/petcloudapi/api';
import { usePetCloudApi } from '../../api/PetCloudApi';
import {
  Error,
  hasError,
  useErrorHandler,
} from '../../contexts/errorhandler/ErrorHandler';
import Faq from './faq/Faq';
import { Dropdown, DropdownOption } from '../../elements/selectors/Selectors';
import ErrorCheck from '../../elements/errorcheck/ErrorCheck';
import languageTabs from '../../../locate/LanguageTabs';
import { useUser } from '../../contexts/auth/User';
import FullEditor from '../../elements/fulleditor/FullEditor';
import { Has, useAuthorization } from '../../contexts/auth/Authorization';
import MockShop from './mockshop/MockShop';

interface ShopCustomizationProps {
  shop: CreateBrandStoreRequest | UpdateBrandStoreRequest | BrandStoreResponse;
  updateShop: (shop: any) => void;
  errors?: Error[] | null;
}

const ShopCustomization: React.FC<ShopCustomizationProps> = ({
  shop,
  updateShop,
  errors,
}) => {
  const { t, i18n } = useTranslation();
  const api = usePetCloudApi();
  const manufacturersApi = api.manufacturersApi();
  const brandsApi = api.brandsApi();
  const { authorizations } = useAuthorization();
  const errorHandler = useErrorHandler();
  const { user } = useUser();
  const [manufacturer, setManufacturer] = useState<ManufacturerResponse | null>(
    null
  );
  const [brands, setBrands] = useState<BrandResponse[] | null>(null);
  const [brandOptions, setBrandOptions] = useState<DropdownOption[] | null>(
    null
  );
  const [heroBannerAssetUri, setHeroBannerAssetUri] = useState<{
    'de-DE': string | null;
    'en-GB': string | null;
  }>({
    'de-DE': null,
    'en-GB': null,
  });

  useEffect(() => {
    getManufacturer();
    getHeroBannerAssetUri();
    getBrandOptions();
  }, []);

  const getBrandOptions = () => {
    brandsApi
      .brandsGetBrands()
      .then((response) => {
        console.log(response);
        setBrands(response.data);
        setBrandOptions(
          response.data.map((brand) => {
            return {
              id: brand.id,
              name:
                brand.name[i18n.language as TranslatedStringIndex] ??
                'brand name missing for your localization',
            };
          })
        );
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const [infoTabs, setInfoTabs] = useState<TabConfig[]>(languageTabs);
  const [heroBannerTabs, setHeroBannerTabs] =
    useState<TabConfig[]>(languageTabs);
  const [faqTabs] = useState<TabConfig[]>(languageTabs);

  useEffect(() => {
    const heroBannerTabsWithErrors: string[] = [];
    hasError(errors, ['$.heroBannerAssetId.de-DE'], () =>
      heroBannerTabsWithErrors.push('de-DE')
    );
    hasError(errors, ['$.heroBannerAssetId.en-GB'], () =>
      heroBannerTabsWithErrors.push('en-GB')
    );
    setMultipleErrorTabs(
      languageTabs,
      setHeroBannerTabs,
      heroBannerTabsWithErrors
    );
  }, [errors]);

  const getHeroBannerAssetUri = () => {
    const uriDE = (shop as BrandStoreResponse).heroBannerAsset
      ? (shop as BrandStoreResponse).heroBannerAsset['de-DE']?.uri
      : null;
    const uriEN = (shop as BrandStoreResponse).heroBannerAsset
      ? (shop as BrandStoreResponse).heroBannerAsset['en-GB']?.uri
      : null;
    setHeroBannerAssetUri({
      'de-DE': uriDE ?? null,
      'en-GB': uriEN ?? null,
    });
  };

  const getManufacturerId = () => {
    if (user?.manufacturerId) {
      return user?.manufacturerId;
    } else if ((shop as BrandStoreResponse)?.manufacturerId) {
      return (shop as BrandStoreResponse)?.manufacturerId;
    }
  };

  const getManufacturer = () => {
    const manufacturerId = getManufacturerId();
    if (manufacturerId) {
      manufacturersApi
        .manufacturersGetManufacturerById(manufacturerId)
        .then((response) => {
          console.log(response);
          setManufacturer(response.data);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  };

  const handleHeroUpload = (
    responses: AssetResponse[],
    lang: TranslatedStringIndex
  ) => {
    updateShop({
      ...shop,
      heroBannerAssetId: {
        ...(shop as CreateBrandStoreRequest | UpdateBrandStoreRequest)
          .heroBannerAssetId,
        [lang]: responses[0].id,
      },
    });
    setHeroBannerAssetUri({
      ...heroBannerAssetUri,
      [lang]: responses[0].uri,
    });
  };

  const deleteHero = (lang: TranslatedStringIndex) => {
    setHeroBannerAssetUri({
      ...heroBannerAssetUri,
      [lang]: null,
    });
    updateShop({
      ...shop,
      heroBannerAssetId: {
        ...(shop as CreateBrandStoreRequest | UpdateBrandStoreRequest)
          .heroBannerAssetId,
        [lang]: null,
      },
    });
  };

  const renderInfoTab = (lang: TranslatedStringIndex) => {
    return (
      <div className="card-tab-content">
        <div className="global-input">
          <Input
            title={t('view.shopcustomization.edit.meta.claim')}
            content={shop.claim ? shop.claim[lang] : null}
            update={(e) =>
              updateShop({
                ...shop,
                claim: {
                  ...shop.claim,
                  [lang]: e,
                },
              })
            }
          />
        </div>
        <FullEditor
          title={t('view.shopcustomization.edit.meta.description')}
          initialHTMLValue={shop.description[lang]}
          onUpdate={(content) => {
            console.log(content);
            updateShop({
              ...shop,
              description: {
                ...shop.description,
                [lang]: content,
              },
            });
          }}
          errors={hasError(
            errors,
            ['Description.' + (lang === 'de-DE' ? 'German' : 'English')],
            () => {
              setErrorTab(infoTabs, setInfoTabs, lang);
            }
          )}
        />
      </div>
    );
  };

  const renderHeroBannerTab = (lang: TranslatedStringIndex) => {
    return (
      <div className="card-tab-content">
        <ErrorCheck
          errors={errors}
          checkFor={[
            `$.heroBannerAssetId.${lang}`,
            'HeroBannerAssetId.' + (lang === 'de-DE' ? 'German' : 'English'),
          ]}
          errorCallback={() =>
            setErrorTab(heroBannerTabs, setHeroBannerTabs, lang)
          }
        />
        {heroBannerAssetUri[lang] ? (
          <>
            <img
              src={heroBannerAssetUri[lang] ?? undefined}
              alt="hero"
              className="shopcustomization-edit-hero-img"
            />
            <Button
              cta={t('actions.delete')}
              action={() => deleteHero(lang)}
              look={'secondary'}
              width="minimal"
            />
          </>
        ) : (
          <Dropzone
            height={200}
            maxFiles={1}
            callback={(responses) => handleHeroUpload(responses, lang)}
            assetContext="OnBoarding"
          />
        )}
      </div>
    );
  };

  const renderFaqTab = (lang: TranslatedStringIndex) => {
    return (
      <div className="card-tab-content">
        <Faq
          faq={shop.faq}
          updateFaq={(updatedFaq) =>
            updateShop({
              ...shop,
              faq: updatedFaq,
            })
          }
          selectedLocale={lang}
        />
      </div>
    );
  };

  const stateOptions = ['Active', 'Inactive'];
  if (shop.state === 'Draft') stateOptions.push('Draft');

  if (authorizations) {
    return (
      <Has
        authorizations={['brands:list', 'manufacturers:detail']}
        showNoAuthorization
      >
        {manufacturer && brandOptions && brands ? (
          <div className="shopcustomization">
            <Card bigScreenWidth="45%">
              <div className="shopcustomization-edit">
                <CardSection
                  title={t('view.shopcustomization.edit.meta.title')}
                >
                  <div className="global-input">
                    <div className="global-input">
                      <LabelText
                        title={t(
                          'view.shopcustomization.edit.meta.companyName'
                        )}
                        content={manufacturer.companyName}
                      />
                    </div>
                    <Dropdown
                      title={t('view.shopcustomization.edit.meta.brand')}
                      optionObjects={brandOptions}
                      selected={
                        brandOptions.find((o) => o.id === shop.brandId)?.name
                      }
                      update={(e) => {
                        const id =
                          e.target.selectedOptions[0].getAttribute(
                            'data-value'
                          );
                        updateShop({
                          ...shop,
                          brandId: id,
                          brand: brands.find((b) => b.id === id),
                        });
                      }}
                      errors={hasError(errors, ['$.brandId'])}
                      required
                    />
                  </div>
                  <div className="global-input">
                    <Input
                      title={t('view.shopcustomization.edit.meta.name')}
                      content={shop.name}
                      update={(e) =>
                        updateShop({
                          ...shop,
                          name: e,
                        })
                      }
                      errors={hasError(errors, ['Name'])}
                    />
                  </div>
                  <div className="global-input">
                    <Dropdown
                      title={t('view.shop.state.title')}
                      options={stateOptions}
                      translationPath={'view.shop.state.'}
                      selected={shop.state}
                      update={(e) =>
                        updateShop({
                          ...shop,
                          state: e.target.selectedOptions[0].value,
                        })
                      }
                    />
                  </div>
                  <div className="card-tabs-wrapper">
                    <Tabs tabs={infoTabs}>
                      <Tab>{renderInfoTab('de-DE')}</Tab>
                      <Tab>{renderInfoTab('en-GB')}</Tab>
                    </Tabs>
                  </div>
                </CardSection>
                <CardSection>
                  <CardTitle
                    title={t('view.shopcustomization.edit.hero')}
                    required={true}
                    hint={{
                      message: t('view.shopcustomization.edit.hero_hint'),
                    }}
                  />
                  <div className="card-tabs-wrapper">
                    <Tabs tabs={heroBannerTabs}>
                      <Tab>{renderHeroBannerTab('de-DE')}</Tab>
                      <Tab>{renderHeroBannerTab('en-GB')}</Tab>
                    </Tabs>
                  </div>
                </CardSection>
                <CardSection
                  title={t('view.shopcustomization.edit.companyColors.title')}
                >
                  <div className="shopcustomization-edit-color">
                    <ColorPicker
                      title={t(
                        'view.shopcustomization.edit.companyColors.primaryColorHex'
                      )}
                      hint={t(
                        'view.shopcustomization.edit.companyColors.primaryColorHex_hint'
                      )}
                      colorHex={shop.primaryColorHex}
                      onSelect={(hex) =>
                        updateShop({
                          ...shop,
                          primaryColorHex: hex,
                        })
                      }
                    />
                  </div>
                  {/*<div className="shopcustomization-edit-color">*/}
                  {/*  <ColorPicker*/}
                  {/*    title={t(*/}
                  {/*      'view.shopcustomization.edit.companyColors.primaryContrastColorHex'*/}
                  {/*    )}*/}
                  {/*    hint={t(*/}
                  {/*      'view.shopcustomization.edit.companyColors.primaryContrastColorHex_hint'*/}
                  {/*    )}*/}
                  {/*    colorHex={shop.primaryContrastColorHex}*/}
                  {/*    onSelect={(hex) =>*/}
                  {/*      updateShop({*/}
                  {/*        ...shop,*/}
                  {/*        primaryContrastColorHex: hex,*/}
                  {/*      })*/}
                  {/*    }*/}
                  {/*  />*/}
                  {/*</div>*/}
                </CardSection>
                <CardSection title={t('view.shopcustomization.edit.faq.title')}>
                  <div className="card-tabs-wrapper">
                    <Tabs tabs={faqTabs}>
                      <Tab>{renderFaqTab('de-DE')}</Tab>
                      <Tab>{renderFaqTab('en-GB')}</Tab>
                    </Tabs>
                  </div>
                </CardSection>
              </div>
            </Card>
            <div className="shopcustomization-preview">
              <MockShop
                shop={shop as BrandStoreResponse}
                heroBannerAssetUri={heroBannerAssetUri}
              />
            </div>
          </div>
        ) : (
          <LoadingContainer />
        )}
      </Has>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default ShopCustomization;
