import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './datetime.css';

interface DateTimeProps {
  dateString: string;
  show: string[];
  shortMonths?: boolean;
  shortDays?: boolean;
}

const DateTime: React.FC<DateTimeProps> = ({
  dateString,
  show,
  shortMonths,
  shortDays,
}) => {
  const { t } = useTranslation();
  const [date, setDate] = useState<Date | null>(null);

  useEffect(() => {
    setDate(new Date(Date.parse(dateString)));
  }, []);

  const month = (m: number) => {
    if (shortMonths) {
      return t('components.datetime.shortMonths.' + m.toString());
    } else {
      return t('components.datetime.months.' + m.toString());
    }
  };

  const weekDay = (d: number) => {
    if (shortDays) {
      return t('components.datetime.shortDays.' + d.toString());
    } else {
      return t('components.datetime.days.' + d.toString());
    }
  };

  if (date) {
    return (
      <div className="datetime">
        {show.includes('weekday') ? (
          <div className="datetime-position">{weekDay(date.getDay())}</div>
        ) : null}
        {show.includes('day') ? (
          <div className="datetime-position">{date.getDate().toString()}.</div>
        ) : null}
        {show.includes('month') ? (
          <div className="datetime-position">{month(date.getMonth())}</div>
        ) : null}
        {show.includes('year') ? (
          <div className="datetime-position">
            {date.getFullYear().toString()}
          </div>
        ) : null}
        {show.includes('time') ? (
          <div className="datetime-position">
            {`${date.getHours().toString()}:${date
              .getMinutes()
              .toString()
              .padStart(2, '0')}${
              show.includes('seconds')
                ? `:${date.getSeconds().toString().padStart(2, '0')}`
                : ''
            }`}
          </div>
        ) : null}
      </div>
    );
  } else {
    return null;
  }
};

export default DateTime;
