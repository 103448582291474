import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Store } from 'react-notifications-component';
import { useParams } from 'react-router-dom';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { AssetResponse, BrandResponse } from '../../api/petcloudapi/api';
import Button from '../../elements/button/Button';
import { LoadingContainer } from '../../elements/loading/Loading';
import {
  Error,
  useErrorHandler,
} from '../../contexts/errorhandler/ErrorHandler';
import { Navigator, Stage } from '../../layout/stage/Stage';
import BrandProfile from '../../sections/brand/brandprofile/BrandProfile';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import { Has, useAuthorization } from '../../contexts/auth/Authorization';
import BrandContactAddresses from '../../sections/brand/brandcontactaddresses/BrandContactAddresses';
import { EmptyState } from '../../elements/emptystate/EmptyState';

const Brand = () => {
  const { t, i18n } = useTranslation();
  const { authorizations } = useAuthorization();
  const params = useParams();
  const brandId = params.brandId;
  const api = usePetCloudApi();
  const brandsApi = api.brandsApi();
  const errorHandler = useErrorHandler();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [originalBrand, setOriginalBrand] = useState<BrandResponse | null>(
    null
  );
  const [brand, setBrand] = useState<BrandResponse | null>(null);
  const [errors, setErrors] = useState<Error[]>([]);

  useEffect(() => {
    getBrand();
  }, []);

  const getBrand = () => {
    if (brandId) {
      brandsApi
        .brandsGetBrandById(brandId)
        .then((response) => {
          console.log(response);
          setBrand(response.data);
          setOriginalBrand(response.data);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  };

  const deleteLogo = (lang: TranslatedStringIndex) => {
    if (brand) {
      setBrand({
        ...brand,
        logo: {
          ...brand.logo,
          [lang]: null,
        },
        logoId: {
          ...brand.logoId,
          [lang]: null,
        },
      });
    }
  };

  const addLogo = (lang: TranslatedStringIndex, asset: AssetResponse) => {
    if (brand) {
      setBrand({
        ...brand,
        logo: {
          ...brand.logo,
          [lang]: asset,
        },
        logoId: {
          ...brand.logoId,
          [lang]: asset.id,
        },
      });
    }
  };

  const submit = () => {
    if (brand && brandId) {
      setIsSubmitting(true);
      setErrors([]);
      const payload = { ...brand };
      if (
        !(brand.earliestDeliveryTimeInDays && brand.latestDeliveryTimeInDays)
      ) {
        payload.overrideProductDeliveryTime = false;
      }
      brandsApi
        .brandsUpdateBrand(brandId, payload)
        .then((response) => {
          console.log(response);
          Store.addNotification({
            message: t('view.brand.notifications.update_successful'),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
          setIsSubmitting(false);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response, (errs) => {
            setErrors(errs);
          });
          setIsSubmitting(false);
        });
    }
  };

  if (authorizations) {
    return (
      <Has authorizations={['brands:detail']} showNoAuthorization>
        {brand ? (
          <Stage>
            <Navigator
              allowBackButton
              backLink="/brands"
              titlePrefix={t('view.brand.titlePrefix')}
              title={brand.name[i18n.language as TranslatedStringIndex]}
            >
              <Button
                cta={t('actions.save')}
                look="save"
                width="minimal"
                action={submit}
                isLoading={isSubmitting}
                active={brand !== originalBrand}
                hasPermission={authorizations.includes('brands:edit')}
              />
            </Navigator>
            <BrandProfile
              brand={brand}
              updateBrand={(b) => setBrand(b as BrandResponse)}
              logoAssets={brand.logo}
              deleteLogo={deleteLogo}
              addLogo={addLogo}
              errors={errors}
            />
            {brandId ? (
              <BrandContactAddresses brandId={brandId} />
            ) : (
              <EmptyState message={'Brand ID missing'} />
            )}
          </Stage>
        ) : (
          <LoadingContainer />
        )}
      </Has>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default Brand;
