import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Store } from 'react-notifications-component';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import {
  OrderPositionResponse,
  OrderResponse,
  OrderReturnResponse,
  OrderReturnState,
  ProductStockQuality,
  UpsertOrderReturnPositionRequest,
} from '../../../../api/petcloudapi/api';
import Button from '../../../../elements/button/Button';
import Popup from '../../../../elements/popup/Popup';
import { Dropdown } from '../../../../elements/selectors/Selectors';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import PositionEntry from '../../../../types/PositionEntry';
import TranslatedStringIndex from '../../../../types/TranslatedStringIndex';
import {
  getOrderReturnPositionEntries,
  getRefundPositionEntries,
} from '../../positions/Positions';
import Refunding from '../../refunding/Refunding';
import ReturnPositionsEdit from '../returnpositionsedit/ReturnPositionsEdit';
import './returnedit.css';
import RefundPositionEntry from '../../../../types/RefundPositionEntry';

interface ReturnEditProps {
  order: OrderResponse;
  orderPositions: OrderPositionResponse[];
  orderReturn: OrderReturnResponse;
  closePopup: () => void;
  getOrder: () => void;
  isViableForRefunding?: boolean;
  isEditable?: boolean;
}

const ReturnEdit: React.FC<ReturnEditProps> = ({
  order,
  orderPositions,
  orderReturn,
  closePopup,
  getOrder,
  isViableForRefunding,
  isEditable,
}) => {
  const { t, i18n } = useTranslation();
  const api = usePetCloudApi();
  const orderReturnsApi = api.orderReturnsApi();
  const errorHandler = useErrorHandler();
  const [positions, setPositions] = useState<PositionEntry[] | null>(null);
  const [originalPositions, setOriginalPositions] = useState<
    PositionEntry[] | null
  >(null);
  const [refundingPositions, setRefundingPositions] = useState<
    RefundPositionEntry[] | null
  >(null);
  const [request, setRequest] = useState<OrderReturnResponse>(orderReturn);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refundPopup, setRefundPopup] = useState(false);

  useEffect(() => {
    getPositions();
    setRefundingPositions(
      getRefundPositionEntries(
        orderPositions,
        i18n.language as TranslatedStringIndex,
        order.currency?.symbol
      )
    );
  }, []);

  const getPositions = () => {
    const ps = getOrderReturnPositionEntries(
      orderPositions,
      orderReturn.orderReturnPositions,
      i18n.language as TranslatedStringIndex
    );
    setPositions(ps);
    setOriginalPositions([...ps]);
  };

  const submit = () => {
    if (positions && originalPositions) {
      setIsSubmitting(true);
      const promises: Promise<void>[] = [];
      promises.push(submitOrderReturn());
      positions.forEach((position, i) => {
        if (JSON.stringify(position) !== JSON.stringify(originalPositions[i])) {
          promises.push(
            submitOrderReturnPosition(orderReturn.id, {
              id: position.id,
              quantity: position.quantity,
              orderPositionId: position.positionId,
              batchNumber: position.batchNumber,
              bestBefore: position.bestBefore,
              logisticsReturnReason: position.logisticsReturnReason,
              quality:
                position.quality !== ('Normal' as ProductStockQuality)
                  ? position.quality
                  : undefined,
              notes: position.notes,
              returnDate: position.returnDate,
            })
          );
        }
      });
      Promise.all(promises)
        .then((response) => {
          Store.addNotification({
            message: t('view.order.returns.notifications.update_successful'),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
          console.log(response);
          setIsSubmitting(false);
          closePopup();
          getOrder();
        })
        .catch((error) => {
          console.log(error);
          Store.addNotification({
            message: t('view.order.returns.notifications.update_unsuccessful'),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
        });
    }
  };

  const submitOrderReturn = () => {
    return orderReturnsApi
      .orderReturnsUpdateOrderReturn(orderReturn.id, request)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const submitOrderReturnPosition = (
    orderReturnId: string,
    position: UpsertOrderReturnPositionRequest
  ) => {
    return orderReturnsApi
      .orderReturnsUpsertOrderReturnPositionPUT(orderReturnId, position)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const stateOptions = [
    {
      id: 'Open',
      name: t('list.states.Open'),
    },
    {
      id: 'PartiallyReturned',
      name: t('list.states.PartiallyReturned'),
    },
    {
      id: 'PartiallyReturnedWithDamages',
      name: t('list.states.PartiallyReturnedWithDamages'),
    },
    {
      id: 'Returned',
      name: t('list.states.Returned'),
    },
    {
      id: 'ReturnedWithDamages',
      name: t('list.states.ReturnedWithDamages'),
    },
    {
      id: 'Closed',
      name: t('list.states.Closed'),
    },
  ];

  if (positions && refundingPositions) {
    return (
      <div className="returnedit">
        <div className="global-inputGroup">
          <div className="global-inputGroup-input">
            <Dropdown
              title={t('view.order.returns.edit.state')}
              selected={stateOptions.find((o) => o.id === request.state)?.name}
              optionObjects={stateOptions}
              update={(e) => {
                const state =
                  e.target.selectedOptions[0].getAttribute('data-value');
                if (state) {
                  const update = {
                    ...request,
                    state: state as OrderReturnState,
                  };
                  setRequest(update);
                }
              }}
            />
          </div>
        </div>
        <ReturnPositionsEdit
          returnPositions={positions}
          updatePositions={(updatedPositions) => setPositions(updatedPositions)}
        />
        <div className="returnedit-actions">
          {orderReturn.locked ? (
            <div>{t('view.order.returns.edit.lockedDisclaimer')}</div>
          ) : (
            <Button
              cta={t('view.order.returns.edit.triggerRefund')}
              look="secondary-danger"
              width="minimal"
              action={() => setRefundPopup(true)}
              active={!orderReturn.locked && isViableForRefunding}
            />
          )}
          <Button
            cta={t('view.order.returns.edit.cta')}
            look="save"
            width="minimal"
            action={submit}
            isLoading={isSubmitting}
            active={isEditable}
          />
        </div>
        <Popup
          width={'70%'}
          toggled={refundPopup}
          close={() => setRefundPopup(false)}
        >
          <div className="refunding-title">
            {t('view.order.refunding.title')}
          </div>
          <div className="refunding-prompt">
            {t('view.order.refunding.prompt')}
          </div>
          <Refunding
            order={order}
            currency={order.currency?.symbol}
            positions={refundingPositions}
            refreshOrder={getOrder}
          />
        </Popup>
      </div>
    );
  } else {
    return null;
  }
};

export default ReturnEdit;
