import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AssetResponse, CreateBrandRequest } from '../../api/petcloudapi/api';
import { usePetCloudApi } from '../../api/PetCloudApi';
import Button from '../../elements/button/Button';
import {
  Error,
  useErrorHandler,
} from '../../contexts/errorhandler/ErrorHandler';
import { Navigator, Stage } from '../../layout/stage/Stage';
import { useUser } from '../../contexts/auth/User';
import Card, { CardSection } from '../../elements/card/Card';
import BrandProfile from '../../sections/brand/brandprofile/BrandProfile';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import { Store } from 'react-notifications-component';
import slugify from '../../hooks/Slugify';
import { DropdownOption } from '../../elements/selectors/Selectors';
import { Has } from '../../contexts/auth/Authorization';
import { useNavigate } from 'react-router-dom';
import { LoadingContainer } from '../../elements/loading/Loading';
import DropdownMenu from '../../elements/dropdownmenu/DropdownMenu';

const NewBrand = () => {
  const { t } = useTranslation();
  const api = usePetCloudApi();
  const brandsApi = api.brandsApi();
  const manufacturersApi = api.manufacturersApi();
  const errorHandler = useErrorHandler();
  const link = useNavigate();
  const { user } = useUser();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [logoAsset, setLogoAsset] = useState({
    'de-DE': null,
    'en-GB': null,
  });
  const [brand, setBrand] = useState<CreateBrandRequest>({
    isActiveInStorefront: false,
    slug: '',
    name: {
      'de-DE': null,
      'en-GB': null,
    },
    claim: {
      'de-DE': null,
      'en-GB': null,
    },
    logoId: {
      'de-DE': null,
      'en-GB': null,
    },
    manufacturerId: user?.manufacturerId,
  });
  const [errors, setErrors] = useState<Error[]>([]);
  const [manufacturerOptions, setManufacturerOptions] = useState<
    DropdownOption[] | null
  >(null);

  useEffect(() => {
    if (user?.isProductOwner) {
      getManufacturerOptions();
    }
  }, []);

  const getManufacturerOptions = () => {
    manufacturersApi
      .manufacturersGetSimpleManufacturers()
      .then((response) => {
        console.log(response);
        setManufacturerOptions(
          response.data.map((manufacturer) => {
            return {
              id: manufacturer.id,
              name: manufacturer.companyName,
            };
          })
        );
      })
      .catch((error) => {
        errorHandler.addError(error.response);
      });
  };

  const addLogo = (lang: TranslatedStringIndex, asset: AssetResponse) => {
    setLogoAsset({
      ...logoAsset,
      [lang]: asset,
    });
    setBrand({
      ...brand,
      logoId: {
        ...brand.logoId,
        [lang]: asset.id,
      },
    });
  };

  const deleteLogo = (lang: TranslatedStringIndex) => {
    setLogoAsset({
      ...logoAsset,
      [lang]: null,
    });
    setBrand({
      ...brand,
      logoId: {
        ...brand.logoId,
        [lang]: null,
      },
    });
  };

  const submit = () => {
    setIsSubmitting(true);
    setErrors([]);
    brandsApi
      .brandsCreateBrand(brand)
      .then((response) => {
        console.log(response);
        Store.addNotification({
          message: t('view.brand.notifications.create_successful'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
          },
        });
        setIsSubmitting(false);
        link('/brands/' + response.data.id);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response, (errs) => {
          setErrors(errs);
        });
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    setBrand({
      ...brand,
      slug: slugify(brand.name['de-DE']),
    });
  }, [brand.name]);

  return (
    <Has authorizations={['brands:detail']} showNoAuthorization>
      <Stage>
        <Navigator
          allowBackButton
          backLink="/brands"
          title={t('view.brand.new')}
        >
          <Button
            cta={t('actions.save')}
            look="save"
            width="minimal"
            action={submit}
            isLoading={isSubmitting}
          />
        </Navigator>
        {user?.isProductOwner ? (
          manufacturerOptions ? (
            <Card bigScreenWidth="full">
              <CardSection>
                <DropdownMenu
                  title={t('view.brand.profile.manufacturer')}
                  optionObjects={manufacturerOptions}
                  selected={
                    manufacturerOptions.find(
                      (m) => m.id === brand.manufacturerId
                    )?.name
                  }
                  onSelect={(value, id) => {
                    setBrand({
                      ...brand,
                      manufacturerId: id,
                    });
                  }}
                />
              </CardSection>
            </Card>
          ) : (
            <LoadingContainer />
          )
        ) : null}
        <BrandProfile
          brand={brand}
          updateBrand={(b) => setBrand(b as CreateBrandRequest)}
          logoAssets={logoAsset}
          deleteLogo={deleteLogo}
          addLogo={addLogo}
          errors={errors}
        />
      </Stage>
    </Has>
  );
};

export default NewBrand;
