import './stocks.css';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthorization } from '../../../contexts/auth/Authorization';
import {
  InlineCreateProductStockRequest,
  ProductStockResponse,
  WarehouseResponse,
} from '../../../api/petcloudapi/api';
import Button from '../../../elements/button/Button';
import { DropdownOption } from '../../../elements/selectors/Selectors';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import {
  Error,
  useErrorHandler,
} from '../../../contexts/errorhandler/ErrorHandler';
import { LoadingContainer } from '../../../elements/loading/Loading';
import ErrorCheck from '../../../elements/errorcheck/ErrorCheck';
import { useUser } from '../../../contexts/auth/User';
import usePersistInCookies from '../../../hooks/useStorageData';
import UniversalProductStock from '../../../types/UniversalProductStock';
import Stock from './Stock';
import useNotifications from '../../../hooks/useNotifications';

export const newStock = (prio: number) => ({
  id: crypto.randomUUID(),
  priority: prio,
  stock: 0,
  available: false,
  warehouseId: '',
  warehouse: null,
  productId: null,
});

interface StocksProps {
  productId?: string;
  callback?: (stocks: InlineCreateProductStockRequest[]) => void;
  readonly?: boolean;
  prohibitAddingCentralWarehouse?: boolean;
  errors?: Error[];
  updateProductViewTabs?: (
    array: { tabKey: string; safe: boolean; error?: boolean }[]
  ) => void;
}

const Stocks: React.FC<StocksProps> = ({
  productId,
  callback,
  readonly,
  prohibitAddingCentralWarehouse,
  errors,
  updateProductViewTabs,
}) => {
  const { t } = useTranslation();
  const api = usePetCloudApi();
  const productStocksApi = api.productStocksApi();
  const warehousesApi = api.warehousesApi();
  const errorHandler = useErrorHandler();
  const { user } = useUser();
  const { pushNotification } = useNotifications();
  const [warehouseOptions, setWarehouseOptions] = useState<
    DropdownOption[] | null
  >(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [productStocks, setProductStocks] = useState<
    UniversalProductStock[] | null
  >(null);
  const [originalProductStocks, setOriginalProductStocks] = useState<
    ProductStockResponse[] | null
  >(null);
  const [newProductStocks, setNewProductStocks] = useState<
    UniversalProductStock[]
  >([]);
  const [newInlineProductStocks, setNewInlineProductStocks] = useState<
    UniversalProductStock[]
  >([]);
  const [warehouses, setWarehouses] = useState<WarehouseResponse[] | null>(
    null
  );
  const { persistObject, getPersistedObject } = usePersistInCookies();

  const { authorizations: userAuthorizations } = useAuthorization();

  useEffect(() => {
    getProductStocks();
    getWarehouseOptions();
  }, []);

  useEffect(() => {
    if (updateProductViewTabs && productStocks && originalProductStocks) {
      if (
        JSON.stringify(productStocks) !==
          JSON.stringify(originalProductStocks) ||
        newProductStocks.length > 0
      ) {
        updateProductViewTabs([{ tabKey: 'stock', safe: false }]);
      }
    }
  }, [productStocks, newProductStocks]);

  const getWarehouseOptions = () => {
    warehousesApi
      .warehousesGetWarehouses()
      .then((response) => {
        console.log(response);
        setWarehouses(response.data);
        const options: DropdownOption[] = [];
        response.data.forEach((warehouse) => {
          if (
            prohibitAddingCentralWarehouse ||
            user?.manufacturerConditions?.find(
              (con) => con.key === 'WarehouseUsage'
            )?.value === 'Custom'
          ) {
            if (warehouse.type !== 'CentralWarehouse') {
              options.push({
                id: warehouse.id,
                name: warehouse.name,
              });
            }
          } else {
            options.push({
              id: warehouse.id,
              name: warehouse.name,
            });
          }
        });
        setWarehouseOptions(options);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getProductStocks = () => {
    if (productId) {
      setProductStocks(null);
      setOriginalProductStocks(null);
      productStocksApi
        .productStocksGetProductStocks(undefined, productId)
        .then((response) => {
          console.log(response);
          setProductStocks(response.data);
          setOriginalProductStocks(response.data);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    } else {
      const persisted = getPersistedObject('new_product_stocks');
      setNewInlineProductStocks(persisted ?? []);
      if (callback) {
        callback(persisted);
      }
      setProductStocks([]);
      setOriginalProductStocks([]);
    }
  };

  const addProductStock = () => {
    if (productId) {
      const update = [...newProductStocks];
      update.push({
        ...newStock(getTotalStocksCount() + 1),
        productId: productId,
      });
      setNewProductStocks(update);
    } else {
      const update = [...newInlineProductStocks];
      update.push({ ...newStock(getTotalStocksCount() + 1) });
      if (callback) {
        callback([...update]);
        persistObject([...update], 'new_product_stocks');
      }
      setNewInlineProductStocks(update);
    }
  };

  const updateNewInlineProductStocks = (
    productStocks: UniversalProductStock[]
  ) => {
    setNewInlineProductStocks([...productStocks]);
    if (callback) {
      callback([...productStocks]);
      persistObject([...productStocks], 'new_product_stocks');
    }
  };

  const submitNewProductStock = (stock: UniversalProductStock) => {
    return new Promise((resolve, reject) => {
      productStocksApi
        .productStocksCreateProductStock({
          ...stock,
          productId: stock.productId ?? 'missing productId',
        })
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          reject();
        });
    });
  };

  const deleteProductStock = (stockId: string) => {
    return new Promise((resolve, reject) => {
      productStocksApi
        .productStocksDeleteProductStock(stockId)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          reject();
        });
    });
  };

  // only removes stock locally - submitting will then actually trigger the deletion via API
  const removeProductStock = (id: string) => {
    if (productStocks) {
      const update = [...productStocks];
      const i = update.findIndex((stock) => stock.id === id);
      if (i !== -1) {
        update.splice(i, 1);
        setProductStocks(update);
      }
    }
  };

  const deleteNewProductStock = (index: number) => {
    if (productId) {
      const update = newProductStocks;
      update.splice(index, 1);
      setNewProductStocks([...update]);
    } else {
      const update = newInlineProductStocks;
      update.splice(index, 1);
      updateNewInlineProductStocks([...update]);
    }
  };

  const submit = (productStocksArray: UniversalProductStock[] | null) => {
    if (productStocksArray) {
      const promises: Promise<unknown>[] = [];
      setIsSubmitting(true);
      originalProductStocks?.forEach((productStock) => {
        const productStockToBeUpdated = productStocksArray.find(
          (s) => s.id === productStock.id
        );
        if (productStockToBeUpdated) {
          if (productStockToBeUpdated !== productStock) {
            promises.push(submitProductStock(productStockToBeUpdated));
          }
        } else {
          promises.push(deleteProductStock(productStock.id));
        }
      });

      newProductStocks.forEach((newProductStock) => {
        promises.push(submitNewProductStock(newProductStock));
      });

      Promise.all(promises)
        .then(() => {
          pushNotification(
            t('view.product.notifications.stockUpdate_successful')
          );
          getProductStocks();
          setIsSubmitting(false);
          setNewProductStocks([]);
          if (updateProductViewTabs) {
            updateProductViewTabs([
              { tabKey: 'stock', safe: true, error: false },
            ]);
          }
        })
        .catch(() => {
          pushNotification(t('view.product.notifications.stockUpdate_failed'));
          setIsSubmitting(false);
        });
    }
  };

  const submitProductStock = (productStock: UniversalProductStock) => {
    return new Promise((resolve, reject) => {
      productStocksApi
        .productStocksUpdateProductStock(productStock.id, productStock)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          reject();
        });
    });
  };

  const returnStocks = (updatesNew: boolean) => {
    if (updatesNew) {
      if (productId) {
        return [...newProductStocks];
      } else {
        return [...newInlineProductStocks];
      }
    } else {
      return productStocks ? [...productStocks] : null;
    }
  };

  const updateProductStock = (
    stock: UniversalProductStock,
    updatesNew: boolean
  ) => {
    const updatedStocks = returnStocks(updatesNew);
    if (updatedStocks) {
      const i = updatedStocks.findIndex((s) => s.id === stock.id);
      console.log(i);
      if (i !== -1) {
        updatedStocks[i] = {
          ...stock,
        };
        if (updatesNew) {
          if (productId) {
            setNewProductStocks(updatedStocks);
          } else {
            updateNewInlineProductStocks(updatedStocks);
          }
        } else {
          setProductStocks(updatedStocks as ProductStockResponse[]);
        }
      }
    }
  };

  const constructUnusedWarehouses = (
    warehouseArray: DropdownOption[],
    stocksArray: UniversalProductStock[]
  ) => {
    const unusedWarehouses: DropdownOption[] = [];
    console.log(warehouseArray);
    warehouseArray.forEach((warehouse) => {
      if (!stocksArray?.find((stock) => stock.warehouseId === warehouse.id)) {
        unusedWarehouses.push(warehouse);
      }
    });
    return unusedWarehouses;
  };

  const getTotalStocksCount = () => {
    return (
      (productStocks?.length ?? 0) +
      newProductStocks.length +
      newInlineProductStocks.length
    );
  };

  if (warehouses && warehouseOptions) {
    return (
      <div className="stocks">
        <ErrorCheck errors={errors} checkFor={['ProductStock']} />
        {productStocks ? (
          productStocks.map((stock, i) => {
            return (
              <Stock
                stock={stock}
                isNewStock={false}
                warehouses={warehouseOptions}
                unusedWarehouseOptions={constructUnusedWarehouses(
                  warehouseOptions,
                  productStocks
                )}
                update={updateProductStock}
                totalStocksLength={getTotalStocksCount()}
                deleteStock={() => removeProductStock(stock.id)}
              />
            );
          })
        ) : (
          <LoadingContainer />
        )}
        {productStocks && productStocks.length > 1 ? (
          <div className="stocks-priorityDisclaimer">
            {t('view.product.stocks.priorityDisclaimer')}
          </div>
        ) : null}
        {newProductStocks.map((stock, i) => {
          return (
            <Stock
              stock={stock}
              isNewStock={true}
              warehouses={warehouseOptions}
              unusedWarehouseOptions={constructUnusedWarehouses(
                warehouseOptions,
                productStocks?.concat(newProductStocks) ?? []
              )}
              update={updateProductStock}
              totalStocksLength={getTotalStocksCount()}
              deleteStock={() => deleteNewProductStock(i)}
            />
          );
        })}
        {newInlineProductStocks.map((stock, i) => {
          return (
            <Stock
              stock={stock}
              isNewStock={true}
              warehouses={warehouseOptions}
              unusedWarehouseOptions={constructUnusedWarehouses(
                warehouseOptions,
                newInlineProductStocks
              )}
              update={updateProductStock}
              totalStocksLength={getTotalStocksCount()}
              deleteStock={() => deleteNewProductStock(i)}
            />
          );
        })}
        {!readonly ? (
          <div className="stocks-actions">
            <Button
              cta={t('view.product.stocks.new')}
              look={'secondary'}
              action={addProductStock}
              width="minimal"
              active={
                (productStocks ? productStocks.length : 0) +
                  newProductStocks.length <
                  warehouses.length && !readonly
              }
              hasPermission={userAuthorizations?.includes(
                'product_stocks:create'
              )}
            />
            {productId ? (
              <Button
                cta={t('actions.save')}
                look="save"
                action={() => {
                  submit(productStocks);
                }}
                width="minimal"
                active={
                  (productStocks !== originalProductStocks ||
                    newProductStocks.length > 0) &&
                  !readonly
                }
                isLoading={isSubmitting}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export const getUnusedWarehouses = (
  warehouseArray: DropdownOption[] | WarehouseResponse[],
  stocks: ProductStockResponse[] | UniversalProductStock[]
) => {
  const unusedWarehouses: DropdownOption[] = [];
  warehouseArray.forEach((warehouse) => {
    if (!stocks.find((stock) => stock.warehouseId === warehouse.id)) {
      unusedWarehouses.push(warehouse);
    }
  });
  console.log(unusedWarehouses);
  return unusedWarehouses;
};

export default memo(Stocks);
