import './sessionhistories.css';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import React, { useEffect, useState } from 'react';
import {
  BulkEditorHistoryResponse,
  BulkEditorSessionResponse,
} from '../../../../api/petcloudapi/api';
import { LoadingContainer } from '../../../../elements/loading/Loading';
import { EmptyState } from '../../../../elements/emptystate/EmptyState';
import Popup from '../../../../elements/popup/Popup';
import {
  BulkEditorProduct,
  SessionInfo,
} from '../../../../view/products/BulkEditor';
import useManufacturerOptions from '../../../../hooks/data/useManufacturerOptions';
import { DropdownOption } from '../../../../elements/selectors/Selectors';
import DropdownMenu from '../../../../elements/dropdownmenu/DropdownMenu';
import PaginatedElements from '../../../../elements/paginatedelements/PaginatedElements';
import Button from '../../../../elements/button/Button';
import { ReactComponent as IconHistory } from '../../../../../assets/icon/history.svg';
import { ReactComponent as IconTrash } from '../../../../../assets/icon/trash.svg';
import useNotifications from '../../../../hooks/useNotifications';
import useDateTools from '../../../../hooks/useDateTools';
import { useUser } from '../../../../contexts/auth/User';
import Hoverable from '../../../../elements/hoverable/Hoverable';
import Badge from '../../../../elements/badge/Badge';

interface SessionHistoriesProps {
  setSessionInfo: (info: SessionInfo) => void;
  setProducts: (products: BulkEditorProduct[]) => void;
}

const SessionHistories: React.FC<SessionHistoriesProps> = ({
  setSessionInfo,
  setProducts,
}) => {
  const { user } = useUser();
  const { manufacturerOptions } = useManufacturerOptions();
  const api = usePetCloudApi();
  const bulkEditorSessionsApi = api.bulkEditorSessionApi();
  const bulkEditorHistoryApi = api.bulkEditorHistoryApi();
  const errorHandler = useErrorHandler();
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.productBulkEditor.stepSelector.sessionHistories',
  });
  const { pushNotification } = useNotifications();
  const { displayReadableDate } = useDateTools();

  const [bulkEditorSessions, setBulkEditorSessions] = useState<
    BulkEditorSessionResponse[] | null
  >(null);
  const [histories, setHistories] = useState<
    BulkEditorHistoryResponse[] | null
  >(null);
  const [session, setSession] = useState<BulkEditorSessionResponse | null>(
    null
  );
  const [sessionToDelete, setSessionToDelete] =
    useState<BulkEditorSessionResponse | null>(null);
  const [historyToDelete, setHistoryToDelete] =
    useState<BulkEditorHistoryResponse | null>(null);
  const [selectedManufacturer, setSelectedManufacturer] =
    useState<DropdownOption | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user?.isProductOwner) {
      if (selectedManufacturer) {
        getSessions();
      }
    } else {
      getSessions();
    }
  }, [selectedManufacturer]);

  const getSessions = () => {
    setBulkEditorSessions(null);
    setSession(null);
    bulkEditorSessionsApi
      .bulkEditorSessionGetBulkEditorSessions(selectedManufacturer?.id)
      .then((response) => {
        console.log(response);
        setBulkEditorSessions(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getSessionHistories = (sessionId: string) => {
    bulkEditorHistoryApi
      .bulkEditorHistoryGetBulkEditorHistories(sessionId)
      .then((response) => {
        console.log(response);
        setHistories(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const deleteSession = (id: string) => {
    setIsDeleting(true);
    bulkEditorSessionsApi
      .bulkEditorSessionDeleteBulkEditorSessionById(id)
      .then((response) => {
        console.log(response);
        pushNotification(t('notifications.session_delete_success'));
        setIsDeleting(false);
        getSessions();
        setSessionToDelete(null);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsDeleting(false);
      });
  };

  const deleteHistory = (id: string) => {
    setIsDeleting(true);
    bulkEditorHistoryApi
      .bulkEditorHistoryDeleteBulkEditorHistoryById(id)
      .then((response) => {
        console.log(response);
        pushNotification(t('notifications.history_delete_success'));
        setIsDeleting(false);
        getSessionHistories(session?.id ?? 'error');
        setHistoryToDelete(null);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsDeleting(false);
      });
  };

  const loadMostRecentHistory = (session: BulkEditorSessionResponse) => {
    setIsLoading(true);
    bulkEditorHistoryApi
      .bulkEditorHistoryGetRecentBulkEditorHistoryBySessionId(session.id)
      .then((response) => {
        console.log(response);
        const history = response.data;
        setSessionInfo({
          id: session.id,
          name: session.name,
          lastSavedAt: history.createdAt,
        });
        const products = JSON.parse(history.data);
        setProducts(products);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsLoading(false);
      });
  };

  const loadHistory = (historyId: string) => {
    setIsLoading(true);
    bulkEditorHistoryApi
      .bulkEditorHistoryGetBulkEditorHistoryById(historyId)
      .then((response) => {
        console.log(response);
        const history = response.data;
        const products = JSON.parse(history.data);
        setSessionInfo({
          id: history.bulkEditorSessionId,
          name: session?.name,
          lastSavedAt: history.createdAt,
        });
        setProducts(products);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (session && !histories) {
      getSessionHistories(session.id);
    } else if (!session) {
      setHistories(null);
    }
  }, [session]);

  return (
    <div className={'sessionHistories'}>
      {manufacturerOptions && manufacturerOptions !== 'NO_PERMISSION' ? (
        <div className={'global-inputGroup'}>
          <div className={'global-inputGroup-input'}>
            <DropdownMenu
              title={t('historyPopup.manufacturer')}
              optionObjects={manufacturerOptions}
              selected={selectedManufacturer?.name}
              onSelect={(name, id) => {
                setSelectedManufacturer(
                  manufacturerOptions.find((x) => x.id === id) ?? null
                );
              }}
            />
          </div>
        </div>
      ) : null}
      {user?.isProductOwner && !selectedManufacturer ? (
        <EmptyState message={t('empty_selectManufacturer')} />
      ) : bulkEditorSessions ? (
        bulkEditorSessions.length > 0 ? (
          isLoading ? (
            <LoadingContainer />
          ) : (
            <PaginatedElements
              elementsCSSHelperClass={'sessionHistories-list'}
              elements={bulkEditorSessions.map((session) => {
                return (
                  <div
                    className={'sessionHistory'}
                    onClick={() => loadMostRecentHistory(session)}
                  >
                    <div className={'sessionHistory-info'}>
                      <Badge
                        title={displayReadableDate(session.createdAt)}
                        color={'var(--color-text_secondary)'}
                      />
                      <div className={'sessionHistory-info-name'}>
                        {session.name}
                      </div>
                    </div>
                    <div className={'sessionHistory-actions'}>
                      <Button
                        type={'icon'}
                        width={'tiny'}
                        look={'secondary'}
                        action={() => setSession(session)}
                      >
                        <IconHistory
                          className={'button-icon-tiny button-icon-secondary'}
                        />
                      </Button>
                      <Button
                        type={'icon'}
                        width={'tiny'}
                        look={'secondary-danger'}
                        action={() => setSessionToDelete(session)}
                      >
                        <IconTrash
                          className={'button-icon-danger button-icon-tiny'}
                        />
                      </Button>
                    </div>
                  </div>
                );
              })}
            />
          )
        ) : (
          <EmptyState message={t('empty')} />
        )
      ) : (
        <LoadingContainer />
      )}
      {session ? (
        <Popup toggled={true} width={'50%'} close={() => setSession(null)}>
          <div className={'popup-title'}>{t('historyPopup.title')}</div>
          <div className={'sessionHistory-entries'}>
            {histories ? (
              isLoading ? (
                <LoadingContainer />
              ) : (
                histories.map((history, i) => {
                  return (
                    <div
                      className={'sessionHistory-entry sessionHistory'}
                      onClick={() => loadHistory(history.id)}
                    >
                      <div className={'sessionHistory-entry-info'}>
                        <Badge
                          title={displayReadableDate(history.createdAt)}
                          color={'var(--color-text_secondary)'}
                        />
                        <div className={'sessionHistory-entry-info-title'}>
                          {`${t('historyPopup.elementTitle')} ${
                            histories.length - i
                          }`}
                        </div>
                        <Hoverable
                          onHoverNode={
                            <div
                              className={
                                'sessionHistory-entry-info-comment-pop'
                              }
                            >
                              {history.comment}
                            </div>
                          }
                        >
                          <div className={'sessionHistory-entry-info-comment'}>
                            {history.comment}
                          </div>
                        </Hoverable>
                      </div>
                      <div className={'sessionHistory-entry-actions'}>
                        <Button
                          type={'icon'}
                          width={'tiny'}
                          look={'secondary-danger'}
                          action={() => setHistoryToDelete(history)}
                        >
                          <IconTrash
                            className={'button-icon-danger button-icon-tiny'}
                          />
                        </Button>
                      </div>
                    </div>
                  );
                })
              )
            ) : (
              <LoadingContainer />
            )}
          </div>
        </Popup>
      ) : null}
      {sessionToDelete ? (
        <Popup
          toggled={true}
          width={'20%'}
          close={() => setSessionToDelete(null)}
        >
          <div className={'popup-title'}>{t('deleteSessionPopup.title')}</div>
          <div className={'global-textElement'}>
            {t('deleteSessionPopup.text')}
          </div>
          <div className={'sessionHistories-popup-actions'}>
            <Button
              cta={t('deleteSessionPopup.cta')}
              width={'full'}
              look={'danger'}
              action={() => deleteSession(sessionToDelete.id)}
              isLoading={isDeleting}
            />
          </div>
        </Popup>
      ) : null}
      {historyToDelete ? (
        <Popup
          toggled={true}
          width={'20%'}
          close={() => setHistoryToDelete(null)}
        >
          <div className={'popup-title'}>{t('deleteHistoryPopup.title')}</div>
          <div className={'global-textElement'}>
            {t('deleteHistoryPopup.text')}
          </div>
          <div className={'sessionHistories-popup-actions'}>
            <Button
              cta={t('deleteHistoryPopup.cta')}
              width={'full'}
              look={'danger'}
              action={() => deleteHistory(historyToDelete.id)}
              isLoading={isDeleting}
            />
          </div>
        </Popup>
      ) : null}
    </div>
  );
};

export default SessionHistories;
