import './input.css';
import { useState } from 'react';
import { ReactComponent as IconConfirm } from '../../../assets/icon/check.svg';
import { ReactComponent as IconClipboard } from '../../../assets/icon/copy.svg';
import {
  TranslatedTypeRequestOfString,
  TranslatedTypeResponseOfString,
} from '../../api/petcloudapi/api';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import { Dropdown } from '../selectors/Selectors';
import useLanguages from '../../hooks/useLanguages';
import useDebounce from '../../hooks/useDebounce';
import InputHeader from './inputheader/InputHeader';
import Button from '../button/Button';
import useNotifications from '../../hooks/useNotifications';
import { useTranslation } from 'react-i18next';

export interface InputProps {
  title?: string;
  update?: (
    value: string,
    lang?: TranslatedStringIndex | null,
    scenario?: 'update' | 'validate',
    e?: React.ChangeEvent<HTMLInputElement>
  ) => void;
  type?: string;
  minValue?: any;
  maxValue?: any;
  content?: string | null;
  translatedContent?:
    | TranslatedTypeResponseOfString
    | TranslatedTypeRequestOfString
    | null;
  maxLength?: number;
  key?: string;
  prefix?: string | null;
  unit?: string | null;
  inherited?: string | null;
  locked?: boolean;
  required?: boolean;
  optional?: boolean;
  errors?: string[] | null;
  hint?: string;
  hintTitle?: string;
  noMargin?: boolean;
  updateRequiresConfirmation?: boolean;
  withClipboard?: boolean;
  disabled?: boolean;
  minWidth?: number;
  maxWidth?: number;
  helperCSSClass?: string;
}

const Input: React.FC<InputProps> = ({
  title,
  update,
  type,
  minValue,
  maxValue,
  content,
  translatedContent,
  maxLength,
  prefix,
  unit,
  inherited,
  locked,
  required,
  optional,
  errors,
  hint,
  hintTitle,
  noMargin,
  updateRequiresConfirmation,
  withClipboard,
  disabled,
  minWidth,
  maxWidth,
  helperCSSClass,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.input',
  });
  const { pushNotification } = useNotifications();
  const { availableLanguages } = useLanguages();
  const debounced = useDebounce();
  const [selectedLang, setSelectedLang] = useState(
    translatedContent !== undefined ? availableLanguages[0] : null
  );
  const [tempValue, setTempValue] = useState<string | null>(null);
  const [key] = useState(Math.random());

  const getValue = () => {
    if (translatedContent && selectedLang) {
      return translatedContent[selectedLang];
    } else if (content) {
      return content;
    } else {
      return null;
    }
  };

  const confirmUpdate = () => {
    if (update && tempValue) {
      update(tempValue, selectedLang, 'validate');
      setTempValue(null);
    }
  };

  return (
    <div
      className={`input ${noMargin ? '' : 'global-elementMargin'} ${
        helperCSSClass ?? ''
      }`}
    >
      {title ? (
        <InputHeader
          title={title}
          inherited={!!inherited}
          brokenInheritance={!!content}
          locked={locked}
          required={required}
          optional={optional}
          hint={
            hint
              ? {
                  title: hintTitle,
                  paragraphs: [hint],
                }
              : undefined
          }
        />
      ) : null}
      <div className={'input-field-wrapper'}>
        <div
          className={`input-field ${
            content
              ? null
              : inherited
              ? 'global-inherited input-field-inherited'
              : null
          } ${errors ? ' input-field-error' : null} ${
            !update ? ' input-field-inactive' : null
          }`}
          style={{ minWidth: minWidth, maxWidth: maxWidth }}
        >
          <div className={'input-field-focusOutline'} />
          {prefix ? <div className={'input-field-prefix'}>{prefix}</div> : null}
          <input
            key={selectedLang ? key + selectedLang : key}
            className={'input-input'}
            onChange={
              update
                ? (e) => {
                    const value = `${prefix ?? ''}${e.target.value}`;
                    if (!updateRequiresConfirmation) {
                      debounced(() => update(value.trim(), selectedLang, 'update'));
                    } else {
                      setTempValue(value.trim());
                    }
                  }
                : undefined
            }
            onBlur={
              update
                ? (e) => {
                    if (e.target.value !== (getValue() ?? '')) {
                      update(e.target.value.trim(), selectedLang, 'validate');
                    }
                  }
                : undefined
            }
            type={type}
            defaultValue={getValue() ?? ''}
            placeholder={inherited ? inherited : undefined}
            maxLength={maxLength}
            disabled={!update || disabled}
            min={minValue}
            max={maxValue}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                confirmUpdate();
              }
            }}
          />
          {tempValue !== null && update ? (
            <IconConfirm
              className={'input-confirmButton'}
              onClick={confirmUpdate}
            />
          ) : null}
          {selectedLang ? (
            <div className="input-langDropdown">
              <Dropdown
                options={availableLanguages}
                selected={selectedLang}
                update={(e) => {
                  setSelectedLang(
                    e.currentTarget.value as TranslatedStringIndex
                  );
                }}
                inputEmbedded
              />
            </div>
          ) : null}
          {unit ? (
            <div
              className="input-unit"
              style={
                errors
                  ? {
                      borderColor: 'var(--color-danger)',
                      borderLeftColor: 'var(--color-border)',
                    }
                  : undefined
              }
            >
              <div className="input-unit-text">{unit}</div>
            </div>
          ) : null}
        </div>
        {withClipboard ? (
          <div className={'input-clipboard'}>
            <Button
              type={'icon'}
              width={'minimal'}
              look={'secondary'}
              action={() => {
                navigator.clipboard.writeText(getValue() ?? '');
                pushNotification(t('notifications.clipboard'), 'info');
              }}
            >
              <IconClipboard className={'button-icon button-icon-secondary'} />
            </Button>
          </div>
        ) : null}
      </div>
      {errors ? (
        <div className="input-field-error-msgs">
          {' '}
          {errors.map((error, i) => {
            return (
              <div key={i} className="input-field-error-msg">
                {error}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default Input;
