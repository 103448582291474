import './memberedit.css';
import { TFunction } from 'i18next';
import Input from '../../../../elements/input/Input';
import { Dropdown } from '../../../../elements/selectors/Selectors';
import React, { useState } from 'react';
import {
  CreateUserRequest,
  UserResponse,
  UserRoleResponse,
} from '../../../../api/petcloudapi/api';
import close from '../../../../../assets/icon/close.svg';
import {
  Error,
  hasError,
} from '../../../../contexts/errorhandler/ErrorHandler';
import RoleNameMapper from '../../roles/rolenamemapper/RoleNameMapper';

interface MemberEditProps {
  member: UserResponse | CreateUserRequest;
  updateCurrentUser?: (member: UserResponse) => void;
  updateNewUser?: (member: CreateUserRequest) => void;
  t: TFunction;
  roles?: UserRoleResponse[];
  memberRoles?: UserRoleResponse[];
  addRole?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  deleteRole?: (id: string) => void;
  errors?: Error[] | null;
}

const userNameRegex = /^[A-Za-z0-9._-]+$/;

const MemberEdit: React.FC<MemberEditProps> = ({
  member,
  updateCurrentUser,
  updateNewUser,
  t,
  roles,
  memberRoles,
  addRole,
  deleteRole,
  errors,
}) => {
  const [userNameValid, setUserNameValid] = useState(true);

  const isUserNameValid = (userName: string) => {
    const isValid = userNameRegex.test(userName);
    if (isValid) {
      setUserNameValid(true);
      return true;
    } else {
      setUserNameValid(false);
      return false;
    }
  };

  const getUserNameErrors = () => {
    const result = hasError(errors, ['User.Username']) ?? [];
    if (!userNameValid) {
      result?.push(t('view.team.team.popup.form.user_invalid'));
    }
    return result.length > 0 ? result : null;
  };

  return (
    <>
      <div className="global-inputGroup">
        <div className="global-inputGroup-input" style={{ flex: 0.5 }}>
          <Input
            title={t('view.team.team.popup.form.title')}
            content={member.title ? member.title : ''}
            update={(e) => {
              member.title = e;
              if (updateNewUser) {
                updateNewUser(member);
              } else if (updateCurrentUser) {
                updateCurrentUser(member as UserResponse);
              }
            }}
            errors={hasError(errors, ['User.Title'])}
          />
        </div>
        <div className="global-inputGroup-input" style={{ flex: 0.5 }}>
          <Input
            title={t('view.team.team.popup.form.salutation')}
            content={member.salutation}
            update={(e) => {
              member.salutation = e;
              if (updateNewUser) {
                updateNewUser(member);
              } else if (updateCurrentUser) {
                updateCurrentUser(member as UserResponse);
              }
            }}
            required={true}
            errors={hasError(errors, ['User.Salutation'])}
          />
        </div>
        <div className="global-inputGroup-input">
          <Input
            title={t('view.team.team.popup.form.firstName')}
            content={member.firstName}
            update={(e) => {
              member.firstName = e;
              if (updateNewUser) {
                updateNewUser(member);
              } else if (updateCurrentUser) {
                updateCurrentUser(member as UserResponse);
              }
            }}
            required={true}
            errors={hasError(errors, ['User.FirstName'])}
          />
        </div>
        <div className="global-inputGroup-input">
          <Input
            title={t('view.team.team.popup.form.lastName')}
            content={member.lastName}
            update={(e) => {
              member.lastName = e;
              if (updateNewUser) {
                updateNewUser(member);
              } else if (updateCurrentUser) {
                updateCurrentUser(member as UserResponse);
              }
            }}
            required={true}
            errors={hasError(errors, ['User.LastName'])}
          />
        </div>
      </div>
      <div className="global-inputGroup">
        <div className="global-inputGroup-input">
          <Input
            title={t('view.team.team.popup.form.user')}
            content={member.username}
            update={(e) => {
              if (isUserNameValid(e)) {
                member.username = e;
                if (updateNewUser) {
                  updateNewUser(member);
                } else if (updateCurrentUser) {
                  updateCurrentUser(member as UserResponse);
                }
              }
            }}
            required={true}
            errors={getUserNameErrors()}
          />
        </div>
        <div className="global-inputGroup-input">
          <Input
            title={t('view.team.team.popup.form.email')}
            content={member.email}
            update={(e) => {
              member.email = e;
              if (updateNewUser) {
                updateNewUser(member);
              } else if (updateCurrentUser) {
                updateCurrentUser(member as UserResponse);
              }
            }}
            required={true}
            errors={hasError(errors, ['User.Email'])}
          />
        </div>
      </div>
      {roles && addRole && deleteRole && memberRoles ? (
        <div className="memberedit-roles-wrapper">
          <div className="input-title memberedit-roles-title">
            {t('view.team.roles.title')}
          </div>
          <div className="memberedit-roles">
            <div className="memberedit-roles-list">
              {memberRoles.map((role, i) => {
                return (
                  <div key={i} className="memberedit-roles-list-role">
                    <RoleNameMapper role={role} />
                    <div
                      className="memberedit-roles-list-role-remove"
                      onClick={() => deleteRole(role.id)}
                    >
                      <img
                        src={close}
                        className="memberedit-roles-list-role-remove-img"
                        alt="remove role"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <Dropdown
              optionObjects={roles}
              update={addRole}
              defaultOptionText={t('components.memberedit.roles.add')}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default MemberEdit;
