import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigator, Stage } from '../../layout/stage/Stage';
import Card, { CardSection } from '../../elements/card/Card';
import FileBrowser from '../../elements/filebrowser/FileBrowser';
import { usePetCloudApi } from '../../api/PetCloudApi';
import {
  AssetFolderResponse,
  AssetResponse,
  CreateAssetFolderRequest,
} from '../../api/petcloudapi/api';
import { LoadingContainer } from '../../elements/loading/Loading';
import { useUser } from '../../contexts/auth/User';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { Has } from '../../contexts/auth/Authorization';

const Assets = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const api = usePetCloudApi();
  const assetFoldersApi = api.assetFoldersApi();
  const assetsApi = api.assetsApi();
  const manufacturersApi = api.manufacturersApi();
  const errorHandler = useErrorHandler();

  const [assetFolders, setAssetFolders] = useState<
    AssetFolderResponse[] | null
  >(null);

  // get all asset folders
  useEffect(() => {
    getAssetFolders();
  }, []);

  const initStructure = (folders: AssetFolderResponse[]) => {
    if (folders) {
      let homeFolder: AssetFolderResponse | undefined;
      if (user?.isProductOwner) {
        homeFolder = folders.find(
          (folder) => folder.isRoot && !folder.manufacturerId
        );
        if (homeFolder) {
          reconfigureAssetFoldersForProductOwner(folders, homeFolder.id);
        }
      }
    }
  };

  const reconfigureAssetFoldersForProductOwner = (
    folders: AssetFolderResponse[],
    homeId: string
  ) => {
    manufacturersApi
      .manufacturersGetSimpleManufacturers()
      .then((response) => {
        console.log(response);
        const result = folders.map((folder) => {
          if (folder.isRoot && !folder.parentId && folder.manufacturerId) {
            const manufacturer = response.data.find(
              (m) => m.id === folder.manufacturerId
            );
            return {
              ...folder,
              parentId: homeId,
              name: manufacturer ? manufacturer.companyName : folder.name,
            };
          } else {
            return folder;
          }
        });
        setAssetFolders(result);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getAssetFolders = () => {
    setAssetFolders(null);
    assetFoldersApi
      .assetFoldersGetAssetFolders()
      .then((response) => {
        console.log(response);
        setAssetFolders(response.data);
        initStructure(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  // create new asset folder
  const createNewFolder = (payload: CreateAssetFolderRequest) => {
    setAssetFolders(null);
    assetFoldersApi
      .assetFoldersCreateAssetFolder(payload)
      .then((response) => {
        console.log(response);
        getAssetFolders();
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  // delete asset folder
  const deleteFolder = (id: string) => {
    return new Promise((resolve, reject) => {
      setAssetFolders(null);
      assetFoldersApi
        .assetFoldersDeleteAssetFolder(id)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
          errorHandler.addError(error.response);
        });
    });
  };

  // move asset folder
  const moveFolder = (folder: AssetFolderResponse, newParentId: string) => {
    return new Promise((resolve) => {
      setAssetFolders(null);
      assetFoldersApi
        .assetFoldersUpdateAssetFolder(folder.id, {
          ...folder,
          parentId: newParentId,
        })
        .then((response) => {
          console.log(response);
          resolve('MOVED');
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    });
  };

  // select file(s)

  // delete a file
  const deleteAsset = (id: string) => {
    return new Promise((resolve) => {
      setAssetFolders(null);
      assetsApi
        .assetsDeleteAsset(id)
        .then((response) => {
          console.log(response);
          resolve('DELETED');
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    });
  };

  // move a file / assign asset to folder
  const moveAsset = (asset: AssetResponse, newParentId: string) => {
    return new Promise((resolve) => {
      setAssetFolders(null);
      assetsApi
        .assetsUpdateAsset(asset.id, { ...asset, assetFolderId: newParentId })
        .then((response) => {
          console.log(response);
          resolve('MOVED');
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    });
  };

  return (
    <Has
      authorizations={['assets:list', 'asset_folders:list']}
      showNoAuthorization
    >
      {assetFolders !== null ? (
        <Stage>
          <Navigator title={t('view.assets.title')}></Navigator>
          <Card bigScreenWidth="full">
            <CardSection>
              <FileBrowser
                assetFolders={assetFolders}
                allowUrlNavigation={true}
                createNewFolder={createNewFolder}
                deleteFolder={deleteFolder}
                moveFolder={moveFolder}
                deleteAsset={deleteAsset}
                moveAsset={moveAsset}
                reloadAssetFolders={getAssetFolders}
                allowAssetUpload={true}
              />
            </CardSection>
          </Card>
        </Stage>
      ) : (
        <LoadingContainer />
      )}
    </Has>
  );
};

export default Assets;
