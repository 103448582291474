import Card, { CardSection } from '../../../../../elements/card/Card';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useErrorHandler } from '../../../../../contexts/errorhandler/ErrorHandler';
import useNotifications from '../../../../../hooks/useNotifications';
import { LoadingContainer } from '../../../../../elements/loading/Loading';
import { IntegrationSubModuleConfigResponse } from '../../../../../api/petcloudapi/api';
import { BadgeProps } from '../../../../../elements/badge/Badge';
import Button from '../../../../../elements/button/Button';
import Popup from '../../../../../elements/popup/Popup';
import ProductMappings from '../../../common/productmappings/ProductMappings';
import { ReactComponent as IconProduct } from '../../../../../../assets/icon/sidebar/products.svg';
import { ReactComponent as IconSync } from '../../../../../../assets/icon/convert.svg';
import dataProcessing from '../../../../../../assets/img/data_processing.svg';
import InformationBox from '../../../../../elements/informationbox/InformationBox';
import useJsonParseCamelcase from '../../../../../hooks/useJsonParseCamelcase';
import { InpettoClientBasedProductImportConfigResponse } from '../../../../../api/magentoapi';
import useInpettoClientBasedIntegrationApi from '../../../setup/inpettoclientbased/hooks/useInpettoClientBasedIntegrationApi';

export type IntegrationIdentifier = 'Shopify' | 'Magento' | 'Shopware';

interface ShopifyProductImportProps {
  integrationIdentifier: IntegrationIdentifier;
  manufacturerId: string;
  integrationSubModuleId: string;
  fetchedConfig?: IntegrationSubModuleConfigResponse | null;
  locked: boolean;
}

const InpettoClientBasedProductImport: React.FC<ShopifyProductImportProps> = ({
  integrationIdentifier,
  manufacturerId,
  integrationSubModuleId,
  fetchedConfig,
  locked,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.integration.common.productImport',
  });
  const { productsActionSyncPost } = useInpettoClientBasedIntegrationApi(
    integrationIdentifier
  );
  const errorHandler = useErrorHandler();
  const { pushNotification } = useNotifications();
  const { toCamelCase } = useJsonParseCamelcase();

  const [config, setConfig] = useState<
    InpettoClientBasedProductImportConfigResponse | null | undefined
  >(null);
  const [mappingPopup, setMappingPopup] = useState(false);
  const [syncPopup, setSyncPopup] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    getConfig();
  }, []);

  const getConfig = () => {
    if (fetchedConfig && fetchedConfig.config) {
      const config = JSON.parse(
        fetchedConfig?.config,
        toCamelCase
      ) as InpettoClientBasedProductImportConfigResponse;
      console.log(config);
      setConfig(config);
    } else {
      setConfig(undefined);
    }
  };

  const syncProducts = () => {
    setIsSubmitting(true);
    productsActionSyncPost({ manufacturerId: manufacturerId })
      .then((response) => {
        console.log(response);
        pushNotification(t('notifications.sync_successful'));
        setIsSubmitting(false);
        setIsSubmitted(true);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSubmitting(false);
      });
  };

  const getBadges = () => {
    const nodes: BadgeProps[] = [];
    if (config === undefined) {
      nodes.push({
        title: t('badge.notConfigured'),
        color: 'var(--color-text_secondary)',
      });
    } else {
      nodes.push({
        title: t('badge.configured'),
        color: 'var(--color-success)',
      });
    }
    return nodes;
  };

  return (
    <div className={'shopifyProductImport'}>
      <Card bigScreenWidth={'100%'}>
        <CardSection title={t('title')} badges={getBadges()} locked={locked}>
          {config !== null ? (
            config !== undefined ? (
              <div className={'shopifyProductImport-actions'}>
                <Button
                  type={'icon-text'}
                  cta={t('cta_configure')}
                  look={'secondary'}
                  width={'minimal'}
                  action={() => setMappingPopup(true)}
                >
                  <IconProduct
                    className={'button-icon button-icon-secondary'}
                  />
                </Button>
                <Button
                  type={'icon-text'}
                  cta={t('cta_sync')}
                  look={'secondary'}
                  width={'minimal'}
                  action={() => setSyncPopup(true)}
                  isLoading={isSubmitting}
                  active={
                    !!(
                      (config.productIds && config.productIds.length > 0) ||
                      (config.productVariantIds &&
                        config.productVariantIds.length > 0)
                    )
                  }
                >
                  <IconSync className={'button-icon button-icon-secondary'} />
                </Button>
              </div>
            ) : (
              <Button
                cta={t('cta_configure')}
                look={'secondary'}
                width={'minimal'}
                action={() => setMappingPopup(true)}
                active={!locked}
              />
            )
          ) : (
            <LoadingContainer />
          )}
        </CardSection>
      </Card>
      <Popup
        toggled={mappingPopup}
        width={'80%'}
        close={() => setMappingPopup(false)}
      >
        <ProductMappings
          manufacturerId={manufacturerId}
          integrationSubModuleId={integrationSubModuleId}
          integrationIdentifier={integrationIdentifier}
        />
      </Popup>
      <Popup
        toggled={syncPopup}
        width={'30%'}
        close={() => {
          setSyncPopup(false);
          setIsSubmitted(false);
        }}
      >
        <div className={'popup-title'}>{t('syncPopup.title')}</div>
        {isSubmitted ? (
          <>
            <img
              src={dataProcessing}
              alt={'in progress'}
              className={'shopifyProductImport-dataProcessingImg'}
            />
            <div className={'global-textElement'}>{t('syncPopup.success')}</div>
            <Button
              cta={t('cta_close')}
              look={'secondary'}
              width={'full'}
              action={() => {
                setSyncPopup(false);
                setIsSubmitted(false);
              }}
            />
          </>
        ) : (
          <>
            <InformationBox
              type={'warning'}
              title={t('syncPopup.info.title')}
              content={t('syncPopup.info.text')}
              maxWidth={500}
            />
            <Button
              type={'icon-text'}
              cta={t('cta_sync')}
              look={'secondary'}
              width={'full'}
              action={syncProducts}
              isLoading={isSubmitting}
            >
              <IconSync className={'button-icon button-icon-secondary'} />
            </Button>
          </>
        )}
      </Popup>
    </div>
  );
};

export default InpettoClientBasedProductImport;
