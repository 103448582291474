import './sidebar.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as UnderConstruction } from '../../../assets/icon/underConstruction.svg';
import { NavElement } from '../../view/View';
import { useUser } from '../../contexts/auth/User';
import ProfileImage from '../../elements/dogtag/profileimage/ProfileImage';
import { memo, useState } from 'react';
import SettingsMenu from './settingsmenu/SettingsMenu';

interface SidebarProps {
  navElements: NavElement[];
  mobileMenu: boolean;
  toggleMobileMenu: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  navElements,
  mobileMenu,
  toggleMobileMenu,
}) => {
  const { t } = useTranslation();
  const { user } = useUser();

  const [settingsMenu, setSettingsMenu] = useState(false);

  return (
    <>
      <div
        className={
          mobileMenu
            ? 'sidebar sidebar-mobileActive'
            : 'sidebar sidebar-mobileInactive'
        }
      >
        <div className="sidebar-items">
          {navElements.map((element, i) => {
            if (element.condition === undefined || element.condition) {
              if (!element.children) {
                return <SidebarElement key={i} element={element} />;
              } else if (
                element.children &&
                element.children.some((child) => child.active)
              ) {
                return (
                  <div key={i}>
                    <SidebarElement element={element} />
                    {element.children.map((child, i) => {
                      if (child.condition === undefined || child.condition) {
                        return <SidebarElement key={i} element={child} />;
                      } else {
                        return null;
                      }
                    })}
                  </div>
                );
              } else {
                return <SidebarElement key={i} element={element} />;
              }
            } else {
              return null;
            }
          })}
        </div>
        <div
          className={'sidebar-settings'}
          onClick={() => setSettingsMenu(true)}
        >
          <ProfileImage name={user?.firstName} lastName={user?.lastName} />
          <div className="sidebar-settings-title">
            {t('sidebar.settings.title')}
          </div>
          <SettingsMenu
            toggled={settingsMenu}
            close={() => {
              setSettingsMenu(false);
            }}
          />
        </div>
      </div>
      {mobileMenu ? (
        <div className={'sidebar-overlay'} onClick={toggleMobileMenu}></div>
      ) : null}
    </>
  );
};

interface SidebarElementProps {
  element: NavElement;
}

const SidebarElement: React.FC<SidebarElementProps> = ({ element }) => {
  const { t } = useTranslation();
  return (
    <Link key={element.title} to={element.link}>
      <div
        className="sidebar-item"
        style={{
          color: element.active
            ? 'var(--color-primary)'
            : 'var(--color-text_secondary)',
          backgroundColor: element.active
            ? 'rgba(0, 0, 0, 0.04)'
            : 'transparent',
        }}
      >
        <div
          className="sidebar-item-highlight"
          style={{
            backgroundColor: element.active
              ? 'var(--color-primary)'
              : 'transparent',
          }}
        />
        {element.icon ? (
          <div className="sidebar-item-icon">
            {element.icon(element.active)}
          </div>
        ) : null}
        {t('sidebar.' + element.title)}
        {element.notificationCount ? (
          <div className={'sidebar-item-notification'}>
            {element.notificationCount}
          </div>
        ) : null}
        {element.underConstruction ? (
          <UnderConstruction
            fill={'var(--color-gray)'}
            className="sidebar-item-underConstruction"
          />
        ) : null}
      </div>
    </Link>
  );
};

export default memo(Sidebar);
