import './adyenoverview.css';
import Card, { CardSection } from '../../../elements/card/Card';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import AdyenWizard from './adyenwizard/AdyenWizard';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import {
  AdyenAccountHolderResponse,
  AdyenBalanceAccountResponse,
  AdyenDocumentResponse,
  AdyenLegalEntityResponse,
  AdyenTransferInstrumentResponse,
} from '../../../api/petcloudapi/api';
import List from '../../../features/list/List';
import { LoadingContainer } from '../../../elements/loading/Loading';
import { StageColumn, StageColumns } from '../../../layout/stage/Stage';

interface AdyenOverviewProps {
  manufacturerId: string;
}

const AdyenOverview: React.FC<AdyenOverviewProps> = ({ manufacturerId }) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.manufacturer.adyenOverview',
  });
  const api = usePetCloudApi();
  const adyenLegalEntitiesApi = api.adyenLegalEntitiesApi();
  const adyenTransferInstrumentsApi = api.adyenTransferInstrumentsApi();
  const adyenDocumentsApi = api.adyenDocumentsApi();
  const errorHandler = useErrorHandler();

  const [legalEntities, setLegalEntities] = useState<
    AdyenLegalEntityResponse[] | null
  >(null);
  const [transferInstruments, setTransferInstruments] = useState<
      AdyenTransferInstrumentResponse[] | null
  >(null);
  const [accountHolders, setAccountHolders] = useState<
    AdyenAccountHolderResponse[] | null
  >(null);
  const [balanceAccounts, setBalanceAccounts] = useState<
    AdyenBalanceAccountResponse[] | null
  >(null);
  const [documents, setDocuments] = useState<AdyenDocumentResponse[] | null>(
    null
  );

  useEffect(() => {
    getLegalEntities();
  }, []);

  const getLegalEntities = () => {
    adyenLegalEntitiesApi
      .adyenLegalEntitiesGetLegalEntities(manufacturerId)
      .then((response) => {
        console.log(response);
        setLegalEntities(response.data);

        const transferInstrumentIds: string[] = [];
        response.data.forEach((entity) =>
          entity.transferInstrumentIds?.forEach((id) =>
            transferInstrumentIds.push(id)
          )
        );
        getTransferInstruments(transferInstrumentIds);

        const aH: AdyenAccountHolderResponse[] = [];
        const bA: AdyenBalanceAccountResponse[] = [];
        const documentIds: string[] = [];
        response.data.forEach((entity) => {
          entity.documentIds?.forEach((id) => {
            documentIds.push(id);
          });
          entity.accountHolders?.forEach((holder) => {
            aH.push(holder);
            holder.balanceAccounts?.forEach((account) => {
              bA.push(account);
            });
          });
        });
        setAccountHolders(aH);
        setBalanceAccounts(bA);
        getDocuments(documentIds);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getTransferInstruments = (transferInstrumentIds: string[]) => {
    Promise.all(transferInstrumentIds.map(getTransferInstrument)).then(
      (instruments) => {
        setTransferInstruments(instruments);
      }
    );
  };

  const getTransferInstrument = (
    transferInstrumentId: string
  ): Promise<AdyenTransferInstrumentResponse> => {
    return new Promise((resolve, reject) => {
      adyenTransferInstrumentsApi
        .adyenTransferInstrumentsGetTransferInstrumentById(transferInstrumentId)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          reject();
        });
    });
  };

  const getDocuments = (ids: string[]) => {
    Promise.all(ids.map(getDocument)).then((documents) => {
      setDocuments(documents);
    });
  };

  const getDocument = (id: string): Promise<AdyenDocumentResponse> => {
    return new Promise((resolve, reject) => {
      adyenDocumentsApi
        .adyenDocumentsGetAdyenDocumentById(id)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          reject();
        });
    });
  };

  return (
    <div className="adyenOverview">
      <StageColumns>
        <StageColumn size={'two'}>
          {legalEntities ? (
            <Card bigScreenWidth={'100%'} title={t('legalEntities.title')}>
              <CardSection>
                <List
                  items={legalEntities}
                  monoSpaceStrings={['legalEntityId', 'transferInstrumentIds']}
                  ignore={['manufacturerId', 'accountHolders', 'updatedAt']}
                  dateStrings={['createdAt']}
                  tableHeadContrast
                />
              </CardSection>
            </Card>
          ) : (
            <LoadingContainer />
          )}
          {documents ? (
            <Card bigScreenWidth={'100%'} title={t('documents.title')}>
              <CardSection>
                <List
                  items={documents}
                  dateStrings={['createdAt']}
                  tableHeadContrast
                />
              </CardSection>
            </Card>
          ) : (
            <LoadingContainer />
          )}
          {transferInstruments ? (
            <Card
              bigScreenWidth={'100%'}
              title={t('transferInstruments.title')}
            >
              <CardSection>
                <List
                  items={transferInstruments}
                  ignore={['bankAccount', 'recurringDetail']}
                  monoSpaceStrings={['legalEntityId']}
                  tableHeadContrast
                />
              </CardSection>
            </Card>
          ) : (
            <LoadingContainer />
          )}
          {accountHolders ? (
            <Card bigScreenWidth={'100%'} title={t('accountHolders.title')}>
              <CardSection>
                <List
                  items={accountHolders}
                  monoSpaceStrings={['accountHolderId', 'legalEntityId']}
                  ignore={[
                    'manufacturerId',
                    'balanceAccounts',
                    'accountHolder',
                    'updatedAt',
                    'balancePlatform',
                  ]}
                  dateStrings={['createdAt']}
                  tableHeadContrast
                />
              </CardSection>
            </Card>
          ) : (
            <LoadingContainer />
          )}
          {balanceAccounts ? (
            <Card bigScreenWidth={'100%'} title={t('balanceAccounts.title')}>
              <CardSection>
                <List
                  items={balanceAccounts}
                  ignore={['updatedAt', 'balanceAccount']}
                  monoSpaceStrings={['balanceAccountId', 'accountHolderId']}
                  dateStrings={['createdAt']}
                  tableHeadContrast
                />
              </CardSection>
            </Card>
          ) : (
            <LoadingContainer />
          )}
        </StageColumn>
        <StageColumn size={'one'}>
          <AdyenWizard
            manufacturerId={manufacturerId}
            legalEntities={legalEntities}
            transferInstruments={transferInstruments}
            accountHolders={accountHolders}
            isComplete={!!balanceAccounts && balanceAccounts.length > 0}
          />
        </StageColumn>
      </StageColumns>
    </div>
  );
};

export default AdyenOverview;
