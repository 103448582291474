import './savecreation.css';
import React, { useEffect, useState } from 'react';
import Button from '../../../elements/button/Button';
import { useTranslation } from 'react-i18next';
import Popup from '../../../elements/popup/Popup';
import Input from '../../../elements/input/Input';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import useNotifications from '../../../hooks/useNotifications';
import useManufacturerOptions from '../../../hooks/data/useManufacturerOptions';
import DropdownMenu from '../../../elements/dropdownmenu/DropdownMenu';
import { Check, DropdownOption } from '../../../elements/selectors/Selectors';
import { SessionInfo } from '../../../view/products/BulkEditor';
import Notification, {
  NotificationType,
} from '../../../elements/notification/Notification';
import { ReactComponent as IconSave } from '../../../../assets/icon/save.svg';
import { ReactComponent as IconNew } from '../../../../assets/icon/special.svg';
import useLocalStorageSettings from '../../../hooks/useLocalStorageSettings';

interface SaveCreationProps {
  sessionInfo?: SessionInfo | null;
  setSessionInfo: (info: SessionInfo) => void;
  products: any[];
}

const SaveCreation: React.FC<SaveCreationProps> = ({
  sessionInfo,
  setSessionInfo,
  products,
}) => {
  const api = usePetCloudApi();
  const bulkEditorSessionApi = api.bulkEditorSessionApi();
  const bulkEditorHistoryApi = api.bulkEditorHistoryApi();
  const errorHandler = useErrorHandler();
  const { pushNotification } = useNotifications();
  const { getToggledSetting, setToggledSetting } = useLocalStorageSettings();
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.productBulkEditor.saveCreation',
  });

  const [originalProducts, setOriginalProducts] = useState([...products]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sessionPopup, setSessionPopup] = useState(false);
  const [historyPopup, setHistoryPopup] = useState(false);
  const [newSessionName, setNewSessionName] = useState('');
  const [newHistoryComment, setNewHistoryComment] = useState('');

  const { manufacturerOptions } = useManufacturerOptions();
  const [selectedManufacturer, setSelectedManufacturer] =
    useState<DropdownOption | null>(null);
  const [saveIntervalWarning, setSaveIntervalWarning] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);

  useEffect(() => {
    setDontShowAgain(getToggledSetting('bulkEditorSaveIntervalWarning'));
  }, []);

  const toggleWarning = (bool: boolean) => {
    setToggledSetting('bulkEditorSaveIntervalWarning', bool);
    setDontShowAgain(bool);
  };

  const getStringyfiedProducts = () => {
    return JSON.stringify(products, (k, v) => (v === undefined ? null : v));
  };

  const saveSession = (name: string) => {
    setIsSubmitting(true);
    bulkEditorSessionApi
      .bulkEditorSessionCreateBulkEditorSession({
        name: name,
        manufacturerId: selectedManufacturer?.id ?? undefined,
      })
      .then((sessionResponse) => {
        console.log(sessionResponse);
        bulkEditorHistoryApi
          .bulkEditorHistoryCreateBulkEditorHistory(sessionResponse.data.id, {
            data: getStringyfiedProducts(),
            comment: t('initialComment'),
          })
          .then((historyResponse) => {
            console.log(historyResponse);
            setSessionInfo({
              id: sessionResponse.data.id,
              name: sessionResponse.data.name,
              lastSavedAt: historyResponse.data.createdAt,
            });
            setOriginalProducts(products);
            pushNotification(t('notifications.session_success'));
            setIsSubmitting(false);
            setSessionPopup(false);
          })
          .catch((error) => {
            console.log(error);
            errorHandler.addError(error.response);
            setIsSubmitting(false);
          });
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSubmitting(false);
      });
  };

  const saveHistory = (comment?: string) => {
    if (sessionInfo?.id) {
      setIsSubmitting(true);
      bulkEditorHistoryApi
        .bulkEditorHistoryCreateBulkEditorHistory(sessionInfo?.id, {
          data: getStringyfiedProducts(),
          comment: comment,
        })
        .then((response) => {
          console.log(response);
          setSessionInfo({
            ...sessionInfo,
            lastSavedAt: response.data.createdAt,
          });
          setOriginalProducts(products);
          pushNotification(t('notifications.history_success'));
          setIsSubmitting(false);
          setHistoryPopup(false);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          setIsSubmitting(false);
        });
    } else {
      console.log('No active session set!');
    }
  };

  // open popup to save history only if there is a current session to save it to
  const initHistorySave = () => {
    if (sessionInfo?.id) {
      setHistoryPopup(true);
    } else {
      setSessionPopup(true);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      console.log(
        JSON.stringify(products) !== JSON.stringify(originalProducts)
      );
      if (JSON.stringify(products) !== JSON.stringify(originalProducts)) {
        if (!dontShowAgain) {
          setSaveIntervalWarning(true);
        }
      }
    }, 300000);
    return () => clearTimeout(timeout);
  }, [products]);

  return (
    <div className={'saveCreation'}>
      <Button
        type={'icon-text'}
        cta={t('cta.save')}
        look={'secondary'}
        width={'minimal'}
        action={initHistorySave}
        subActions={[
          {
            cta: t('cta.save_newSession'),
            ctaAlreadyTranslated: true,
            action: () => setSessionPopup(true),
            look: 'save',
            icon: <IconNew />,
          },
        ]}
      >
        <IconSave className={'button-icon button-icon-secondary'} />
      </Button>
      {saveIntervalWarning ? (
        <Notification
          type={NotificationType.Info}
          text={t('saveIntervalWarning')}
          remove={() => setSaveIntervalWarning(false)}
        >
          <div className={'notification-checkbox'}>
            <Check
              checked={dontShowAgain}
              text={t('dontShowAgain')}
              update={() => toggleWarning(!dontShowAgain)}
            />
          </div>
          <div className={'notification-button'} onClick={initHistorySave}>
            {t('cta.save')}
          </div>
        </Notification>
      ) : null}
      <Popup
        toggled={sessionPopup}
        width={'30%'}
        close={() => setSessionPopup(false)}
      >
        <div className={'popup-title'}>{t('sessionPopup.title')}</div>
        {manufacturerOptions && manufacturerOptions !== 'NO_PERMISSION' ? (
          <div className={'global-inputGroup'}>
            <div className={'global-inputGroup-input'}>
              <DropdownMenu
                title={t('sessionPopup.manufacturer')}
                optionObjects={manufacturerOptions}
                selected={selectedManufacturer?.name}
                onSelect={(name, id) => {
                  setSelectedManufacturer(
                    manufacturerOptions.find((x) => x.id === id) ?? null
                  );
                }}
              />
            </div>
          </div>
        ) : null}
        <div className={'global-inputGroup'}>
          <div className={'global-inputGroup-input'}>
            <Input
              title={t('sessionPopup.name')}
              content={newSessionName}
              update={setNewSessionName}
            />
          </div>
        </div>
        <div className={'global-cardActions'}>
          <Button
            cta={t('sessionPopup.cta')}
            look={'save'}
            width={'full'}
            action={() => saveSession(newSessionName)}
            isLoading={isSubmitting}
            active={newSessionName.length > 0}
          />
        </div>
      </Popup>
      <Popup
        toggled={historyPopup}
        width={'30%'}
        close={() => setHistoryPopup(false)}
      >
        <div className={'popup-title'}>{t('historyPopup.title')}</div>
        <div className={'global-inputGroup'}>
          <div className={'global-inputGroup-input'}>
            <Input
              title={t('historyPopup.comment')}
              content={newHistoryComment}
              update={setNewHistoryComment}
              optional
            />
          </div>
        </div>
        <div className={'global-cardActions'}>
          <Button
            cta={t('historyPopup.cta')}
            look={'save'}
            width={'full'}
            action={() => saveHistory(newHistoryComment)}
            isLoading={isSubmitting}
          />
        </div>
      </Popup>
    </div>
  );
};

export default SaveCreation;
