import { useTranslation } from 'react-i18next';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import { useEffect, useState } from 'react';
import {
  IntegrationResponse,
  IntegrationSubModuleResponse,
  IntegrationSubModuleType,
} from '../../../api/petcloudapi/api';
import useNotifications from '../../../hooks/useNotifications';
import { useNavigate } from 'react-router-dom';
import IntegrationSkeleton from '../../../sections/integration/common/integrationskeleton/IntegrationSkeleton';
import { InpettoClientBasedIntegrationConfigResponse } from '../../../api/magentoapi';
import useInpettoClientBasedIntegrationApi from '../../../sections/integration/setup/inpettoclientbased/hooks/useInpettoClientBasedIntegrationApi';
import { UpsertInpettoClientBasedIntegrationConfigRequest } from '../../../api/shopwareapi';
import InpettoClientBasedProductImport from '../../../sections/integration/integrationsubmodules/inpettoclientbased/productimport/InpettoClientBasedProductImport';
import InpettoClientBasedProductStocks from '../../../sections/integration/integrationsubmodules/inpettoclientbased/productstocks/InpettoClientBasedProductStocks';
import InpettoClientBasedOrderExport from '../../../sections/integration/integrationsubmodules/inpettoclientbased/orderexport/InpettoClientBasedOrderExport';
import InpettoClientBasedOrderDeliveryInformationImport from '../../../sections/integration/integrationsubmodules/inpettoclientbased/orderdeliveryinformationimport/InpettoClientBasedOrderDeliveryInformationImport';
import InpettoClientBasedSetup from '../../../sections/integration/setup/inpettoclientbased/InpettoClientBasedSetup';
import { usePetCloudApi } from '../../../api/PetCloudApi';

interface MagentoIntegrationProps {
  integrationId: string;
  identifier: 'Shopware' | 'Magento';
  manufacturerId: string;
}

const InpettoClientBasedIntegration: React.FC<MagentoIntegrationProps> = ({
  integrationId,
  identifier,
  manufacturerId,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: `view.integration.common`,
  });
  const {
    integrationConfigGet,
    integrationConfigPost,
    integrationConfigDelete,
  } = useInpettoClientBasedIntegrationApi(identifier);
  const errorHandler = useErrorHandler();
  const { pushNotification } = useNotifications();
  const link = useNavigate();
  const api = usePetCloudApi();
  const integrationsApi = api.integrationsApi();

  const [integration, setIntegration] = useState<IntegrationResponse | null>(
    null
  );
  const [integrationConfig, setIntegrationConfig] =
    useState<InpettoClientBasedIntegrationConfigResponse | null>(null);
  const [isIntegrationLoading, setIsIntegrationLoading] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [setup, setSetup] = useState(false);

  useEffect(() => {
    getIntegrationConfig();
    getCommonIntegrationConfig();
  }, [manufacturerId]);

  // this fetches the config from the standard api required for the setup
  const getCommonIntegrationConfig = () => {
    integrationsApi
      .integrationsGetIntegrationById(integrationId, manufacturerId)
      .then((response) => {
        console.log(response);
        setIntegration(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  // this fetches the config from the integration api required by submodules
  const getIntegrationConfig = () => {
    setIsIntegrationLoading(true);
    integrationConfigGet(manufacturerId)
      .then((response) => {
        console.log(response);
        setIntegrationConfig(response);
        setIsIntegrationLoading(false);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsIntegrationLoading(false);
      });
  };

  const updateIntegration = (
    req: UpsertInpettoClientBasedIntegrationConfigRequest
  ) => {
    integrationConfigPost(req)
      .then((response) => {
        console.log(response);
        pushNotification(t('notifications.update_successful'));
        getIntegrationConfig();
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const resetConfig = () => {
    if (manufacturerId) {
      setIsResetting(true);
      integrationConfigDelete(manufacturerId)
        .then((response) => {
          console.log(response);
          pushNotification(t('notifications.reset_successful'));
          setIsResetting(false);
          link('/integrations');
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          setIsResetting(false);
        });
    }
  };

  const renderSubModules = (
    modules: IntegrationSubModuleResponse[],
    hasProductImportConfig: boolean,
    hasOrderExportConfig: boolean
  ) => {
    return modules.map((module, i) => {
      const { type, id, config } = module;
      const props = {
        manufacturerId: manufacturerId,
        integrationSubModuleId: id,
        fetchedConfig: config,
        integrationIdentifier: identifier,
      };
      switch (type) {
        case IntegrationSubModuleType.ProductImport:
          return (
            <InpettoClientBasedProductImport
              key={i}
              {...props}
              locked={
                integrationConfig && integrationConfig.installed !== undefined
                  ? !integrationConfig.installed
                  : false
              }
            />
          );
        case IntegrationSubModuleType.ProductStockImport:
          return (
            <InpettoClientBasedProductStocks
              key={i}
              {...props}
              locked={!hasProductImportConfig}
            />
          );
        case IntegrationSubModuleType.OrderExport:
          return (
            <InpettoClientBasedOrderExport
              key={i}
              {...props}
              locked={!hasProductImportConfig}
            />
          );
        case IntegrationSubModuleType.OrderDeliveryInformationImport:
          return (
            <InpettoClientBasedOrderDeliveryInformationImport
              key={i}
              {...props}
              locked={!hasOrderExportConfig}
            />
          );
        default:
          return <div>Something went wrong...</div>;
      }
    });
  };

  return (
    <>
      <IntegrationSkeleton
        translationNamespace={identifier}
        integration={integrationConfig}
        integrationId={integrationId}
        renderSubModules={renderSubModules}
        updateIntegration={updateIntegration}
        resetConfig={resetConfig}
        isResetting={isResetting}
        onSetup={() => setSetup(true)}
        manufacturerId={manufacturerId}
        isIntegrationLoading={isIntegrationLoading}
      />
      {integration && setup ? (
        <InpettoClientBasedSetup
          integration={integration}
          manufacturerId={manufacturerId}
          onPopupClose={() => setSetup(false)}
          skipPopup
        />
      ) : null}
    </>
  );
};

export default InpettoClientBasedIntegration;
