import './cartoptions.css';
import { useTranslation } from 'react-i18next';
import {
  CreateProductRequest,
  ProductResponse,
} from '../../../api/petcloudapi/api';
import Input from '../../../elements/input/Input';

interface CartOptionsProps {
  product: ProductResponse | CreateProductRequest;
  updateProduct?: (product: ProductResponse) => void;
  updateNewProduct?: (product: CreateProductRequest) => void;
}

const CartOptions: React.FC<CartOptionsProps> = ({
  product,
  updateProduct,
  updateNewProduct,
}) => {
  const { t } = useTranslation();

  return (
    <div className="cartoptions">
      <div className="global-inputGroup cartoptions-inputGroup">
        <div className="global-inputGroup-input">
          <Input
            key={product.purchaseSteps?.toString()}
            title={t('view.product.cartoptions.purchaseSteps')}
            content={
              product.purchaseSteps ? product.purchaseSteps.toString() : null
            }
            inherited={
              (product as ProductResponse).parent
                ? (product as ProductResponse).parent.purchaseSteps?.toString()
                : undefined
            }
            update={(e) => {
              if (updateProduct) {
                updateProduct({
                  ...(product as ProductResponse),
                  purchaseSteps: parseInt(e),
                });
              } else if (updateNewProduct) {
                updateNewProduct({
                  ...(product as CreateProductRequest),
                  purchaseSteps: parseInt(e),
                });
              }
            }}
            type="number"
            required={true}
          />
        </div>
        <div className="global-inputGroup-input">
          <Input
            key={product.minPurchase?.toString()}
            title={t('view.product.cartoptions.minPurchase')}
            content={
              product.minPurchase ? product.minPurchase.toString() : null
            }
            inherited={
              (product as ProductResponse).parent
                ? (product as ProductResponse).parent.minPurchase?.toString()
                : undefined
            }
            update={(e) => {
              if (updateProduct) {
                updateProduct({
                  ...(product as ProductResponse),
                  minPurchase: parseInt(e),
                });
              } else if (updateNewProduct) {
                updateNewProduct({
                  ...(product as CreateProductRequest),
                  minPurchase: parseInt(e),
                });
              }
            }}
            type="number"
            required={true}
          />
        </div>
        <div className="global-inputGroup-input">
          <Input
            key={product.maxPurchase?.toString()}
            title={t('view.product.cartoptions.maxPurchase')}
            content={
              product.maxPurchase ? product.maxPurchase.toString() : null
            }
            inherited={
              (product as ProductResponse).parent
                ? (product as ProductResponse).parent?.maxPurchase?.toString()
                : undefined
            }
            update={(e) => {
              if (updateProduct) {
                updateProduct({
                  ...(product as ProductResponse),
                  maxPurchase: parseInt(e),
                });
              } else if (updateNewProduct) {
                updateNewProduct({
                  ...(product as CreateProductRequest),
                  maxPurchase: parseInt(e),
                });
              }
            }}
            type="number"
          />
        </div>
      </div>
    </div>
  );
};

export default CartOptions;
