import './productimporter.css';
import { BulkEditorProduct } from '../../../view/products/BulkEditor';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SheetParser from './sheetparser/SheetParser';
import ColumnHeaderConfig from './columnheaderconfig/ColumnHeaderConfig';
import useDataMapper from './hooks/useDataMapper';
import ProgressBar from '../../../elements/progressbar/ProgressBar';

export enum ProductImportStep {
  Upload,
  Mapping,
  Processing,
}

export type SheetData = {
  data: any[];
  headers: string[];
  // the id key used to identify products for parent <> child creation when importing them
  idKey?: string;
  // the id key type (e.g. ean) used to fetch products when updating them
  productIdKey?: string;
  // the mapped key found in the sheet whose values will be used to fetch the products
  productIdKeyMappedKey?: string;
  parentIdKey?: string;
  manufacturerId?: string;
};

interface ProductImporterProps {
  setProducts: (products: BulkEditorProduct[]) => void;
}

const ProductImporter: React.FC<ProductImporterProps> = ({ setProducts }) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.productBulkEditor.productImporter',
  });
  const [step, setStep] = useState(ProductImportStep.Upload);
  const [sheetData, setSheetData] = useState<SheetData | null>(null);

  const { mapData, fetchExistingProducts, loadProgress } =
    useDataMapper(setProducts);

  switch (step) {
    case ProductImportStep.Upload:
      return (
        <div className={'productImporter'}>
          <div className={'productImporter-stepTitle'}>{t('upload_title')}</div>
          <SheetParser
            setSheetData={(x) => {
              setSheetData(x);
              setStep(ProductImportStep.Mapping);
            }}
          />
        </div>
      );
    case ProductImportStep.Mapping:
      return (
        <div className={'productImporter'}>
          <div className={'productImporter-stepTitle'}>
            {t('mapping_title')}
          </div>
          {sheetData ? (
            <ColumnHeaderConfig
              sheetData={sheetData}
              mapData={(...props) => {
                console.log('productIdKey: ' + sheetData?.productIdKey);
                if (sheetData?.productIdKey) {
                  setStep(ProductImportStep.Processing);
                  fetchExistingProducts(sheetData).then((responses) => {
                    mapData(...props, responses);
                  });
                } else {
                  mapData(...props);
                }
              }}
            />
          ) : (
            <div>Error: sheet data missing</div>
          )}
        </div>
      );
    case ProductImportStep.Processing:
      return (
        <div className={'productImporter'}>
          {loadProgress ? (
            <ProgressBar
              title={loadProgress.title}
              progress={loadProgress.loaded}
              total={loadProgress.total}
              withLoader
            />
          ) : null}
        </div>
      );
  }
};

export default ProductImporter;
