import './App.css';
import {
  BrowserRouter,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Routes,
} from 'react-router-dom';
import { AuthorizationProvider } from './components/contexts/auth/Authorization';
import { AuthenticationProvider } from './components/contexts/auth/Authentication';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import View from './components/view/View';
import Dashboard from './components/view/dashboard/Dashboard';
import Products from './components/view/products/Products';
import Product from './components/view/products/Product';
import NewProduct from './components/view/products/NewProduct';
import Stocks from './components/view/stocks/Stocks';
import Orders from './components/view/orders/Orders';
import Order from './components/view/orders/Order';
import Shop from './components/view/shop/Shop';
import Customers from './components/view/customers/Customers';
import Customer from './components/view/customers/Customer';
import Manufacturers from './components/view/manufacturers/Manufacturers';
import Manufacturer from './components/view/manufacturers/Manufacturer';
import NewManufacturer from './components/view/manufacturers/NewManufacturer';
import Invoices from './components/view/invoices/Invoices';
import Statistics from './components/view/statistics/Statistics';
import Assets from './components/view/assets/Assets';
import Account from './components/view/settings/Account';
import Auth from './components/page/auth/Auth';
import Shops from './components/view/shop/Shops';
import NewShop from './components/view/shop/NewShop';
import PurchaseOrders from './components/view/purchaseorders/PurchaseOrders';
import NewPurchaseOrder from './components/view/purchaseorders/NewPurchaseOrder';
import PurchaseOrder from './components/view/purchaseorders/PurchaseOrder';
import StocksOfOneProduct from './components/view/stocks/StocksOfOneProduct';
import Payments from './components/view/payments/Payments';
import Payment from './components/view/payments/Payment';
import Documents from './components/view/documents/Documents';
import { ErrorHandlerProvider } from './components/contexts/errorhandler/ErrorHandler';
import { PetCloudApiProvider } from './components/api/PetCloudApi';
import { Authentication, UserProvider } from './components/contexts/auth/User';
import Brands from './components/view/brands/Brands';
import Brand from './components/view/brands/Brand';
import NewBrand from './components/view/brands/NewBrand';
import { useDarkModeDetector } from './components/elements/themeswitcher/ThemeSwitcher';
import { useEffect, useState } from 'react';
import testFavIcon from './assets/favicon/test.png';
import stagingFavIcon from './assets/favicon/staging.png';
import NotFound from './components/view/notfound/NotFound';
import DataExchanges from './components/view/dataexchanges/DataExchanges';
import DataExchange from './components/view/dataexchanges/DataExchange';
import Help from './components/view/help/Help';
import BulkEditor from './components/view/products/BulkEditor';
import Promotions from './components/view/promotions/Promotions';
import NewPromotion from './components/view/promotions/NewPromotion';
import Promotion from './components/view/promotions/Promotion';
import Team from './components/view/settings/Team';
import Support from './components/view/settings/Support';
import Categories from './components/view/admin/Categories';
import ProductGroups from './components/view/admin/ProductGroups';
import ProductUnits from './components/view/admin/ProductUnits';
import Properties from './components/view/admin/Properties';
import Tags from './components/view/admin/Tags';
import Warehouses from './components/view/admin/Warehouses';
import AnimalSpecies from './components/view/admin/AnimalSpecies';
import PropertyValidationRules from './components/view/admin/PropertyValidationRules';
import ProductGroupMappings from './components/view/admin/ProductGroupMappings';
import Bundles from './components/view/bundles/Bundles';
import Bundle from './components/view/bundles/Bundle';
import ProductValidation from './components/view/productvalidations/ProductValidation';
import Loyalty from './components/view/admin/Loyalty';
import Integrations from './components/view/integrations/Integrations';
import Integration from './components/view/integration/Integration';
import IntegrationShopifySuccess from './components/page/integrationshopifysuccess/IntegrationShopifySuccess';
import IntegrationLogs from './components/view/integrationlogs/IntegrationLogs';
import IntegrationRedirectsShopifyBase from './components/page/integrationredirects/shopify/base/IntegrationRedirectsShopifyBase';
import IntegrationRedirectsShopifyOAuth from './components/page/integrationredirects/shopify/oauth/integrationredirectsshopifyoauth/IntegrationRedirectsShopifyOAuth';
import { PopupProvider } from './components/elements/popup/Popup';
import OrderReceipts from './components/view/documents/OrderReceipts';
import { GlobalStateProvider } from './components/contexts/globalstatemanager/GlobalStateManager';

const App: React.FC = () => {
  const darkModeDetector = useDarkModeDetector();

  const dynamicFavIcon = () => {
    if (process.env.REACT_APP_BUILD_ENV !== 'production') {
      let oldFavicon = document.getElementById('favicon');
      const link = document.createElement('link');
      link.id = 'favicon';
      link.type = 'image/x-icon';
      link.rel = 'icon';
      if (oldFavicon) {
        document.head.removeChild(oldFavicon);
      }
      if (process.env.REACT_APP_BUILD_ENV === 'staging') {
        link.href = stagingFavIcon;
        document.head.appendChild(link);
      } else {
        link.href = testFavIcon;
        document.head.appendChild(link);
      }
    }
  };

  useEffect(() => {
    darkModeDetector();
    dynamicFavIcon();
  });

  const [lockNavigation, setLockNavigation] = useState(false);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          path={'/'}
          element={
            <View>
              <Dashboard />
            </View>
          }
        />
        <Route
          path={'/products'}
          element={
            <View>
              <Products />
            </View>
          }
        />
        <Route
          path={'products/:productId'}
          element={
            <View previousUrlOverride={'productDetailView'}>
              <Product key={Math.random()} />
            </View>
          }
        />
        <Route
          path={'products/published/:productId'}
          element={
            <View>
              <Product key={Math.random()} isPublishedVersion />
            </View>
          }
        />
        <Route
          path={'products/variant/:productId'}
          element={
            <View>
              <Product key={Math.random()} isVariant />
            </View>
          }
        />
        <Route
          path={'products/new'}
          element={
            <View>
              <NewProduct />
            </View>
          }
        />
        <Route
          path={'bundles'}
          element={
            <View>
              <Bundles />
            </View>
          }
        />
        <Route
          path={'bundles/:bundleId'}
          element={
            <View>
              <Bundle />
            </View>
          }
        />
        <Route
          path={'productBulkEditor'}
          element={
            <View shouldBlock={lockNavigation}>
              <BulkEditor setLockNavigation={setLockNavigation} />
            </View>
          }
        />
        <Route
          path={'promotions'}
          element={
            <View>
              <Promotions />
            </View>
          }
        />
        <Route
          path={'promotions/:promotionId'}
          element={
            <View>
              <Promotion />
            </View>
          }
        />
        <Route
          path={'promotions/new'}
          element={
            <View>
              <NewPromotion />
            </View>
          }
        />
        <Route
          path={'productValidation'}
          element={
            <View>
              <ProductValidation />
            </View>
          }
        />
        <Route
          path={'brands'}
          element={
            <View>
              <Brands />
            </View>
          }
        />
        <Route
          path={'brands/:brandId'}
          element={
            <View>
              <Brand />
            </View>
          }
        />
        <Route
          path={'brands/new'}
          element={
            <View>
              <NewBrand />
            </View>
          }
        />
        <Route
          path={'stocks'}
          element={
            <View>
              <Stocks />
            </View>
          }
        />
        <Route
          path={'stocks/:productId'}
          element={
            <View previousUrlOverride={'stocksOfOneProduct'}>
              <StocksOfOneProduct />
            </View>
          }
        />
        <Route
          path={'purchaseorders'}
          element={
            <View>
              <PurchaseOrders />
            </View>
          }
        />
        <Route
          path={'purchaseorders/new'}
          element={
            <View>
              <NewPurchaseOrder />
            </View>
          }
        />
        <Route
          path={'purchaseorders/:purchaseOrderId'}
          element={
            <View>
              <PurchaseOrder />
            </View>
          }
        />
        <Route
          path={'orders'}
          element={
            <View>
              <Orders />
            </View>
          }
        />
        <Route
          path={'orders/:orderId'}
          element={
            <View previousUrlOverride={'orderDetailView'}>
              <Order />
            </View>
          }
        />
        <Route
          path={'customers'}
          element={
            <View>
              <Customers />
            </View>
          }
        />
        <Route
          path={'customers/:customerId'}
          element={
            <View>
              <Customer />
            </View>
          }
        />
        <Route
          path={'shop'}
          element={
            <View>
              <Shops />
            </View>
          }
        />
        <Route
          path={'shop/new'}
          element={
            <View>
              <NewShop />
            </View>
          }
        />
        <Route
          path={'shop/:shopId'}
          element={
            <View>
              <Shop />
            </View>
          }
        />
        <Route
          path={'payments'}
          element={
            <View>
              <Payments />
            </View>
          }
        />
        <Route
          path={'payments/:paymentId'}
          element={
            <View>
              <Payment />
            </View>
          }
        />
        <Route
          path={'customers'}
          element={
            <View>
              <Customers />
            </View>
          }
        />
        <Route
          path="manufacturers"
          element={
            <View>
              <Manufacturers />
            </View>
          }
        />
        <Route
          path="manufacturers/:id"
          element={
            <View>
              <Manufacturer />
            </View>
          }
        />
        <Route
          path="manufacturers/new"
          element={
            <View>
              <NewManufacturer />
            </View>
          }
        />
        <Route
          path={'invoices'}
          element={
            <View>
              <Invoices />
            </View>
          }
        />
        <Route
          path={'documents'}
          element={
            <View>
              <Documents />
            </View>
          }
        />
        <Route
          path={'documents/orderReceipts'}
          element={
            <View>
              <OrderReceipts />
            </View>
          }
        />
        <Route
          path={'statistics'}
          element={
            <View>
              <Statistics />
            </View>
          }
        />
        <Route
          path={'assets'}
          element={
            <View>
              <Assets />
            </View>
          }
        />
        <Route
          path={'integrations'}
          element={
            <View>
              <Integrations />
            </View>
          }
        />
        <Route
          path={'integration/:integrationName/:integrationId/:manufacturerId?'}
          element={
            <View>
              <Integration />
            </View>
          }
        />
        <Route
          path={'integrationlogs'}
          element={
            <View>
              <IntegrationLogs />
            </View>
          }
        />
        <Route
          path={'dataexchanges'}
          element={
            <View>
              <DataExchanges />
            </View>
          }
        />
        <Route
          path={'dataexchanges/:dataExchangeId'}
          element={
            <View>
              <DataExchange />
            </View>
          }
        />
        <Route
          path={'account'}
          element={
            <View>
              <Account />
            </View>
          }
        />
        <Route
          path={'team'}
          element={
            <View>
              <Team />
            </View>
          }
        />
        <Route
          path={'support'}
          element={
            <View>
              <Support />
            </View>
          }
        />
        <Route
          path={'admin/animalspecies'}
          element={
            <View>
              <AnimalSpecies />
            </View>
          }
        />
        <Route
          path={'admin/categories'}
          element={
            <View>
              <Categories />
            </View>
          }
        />
        <Route
          path={'admin/productgroups'}
          element={
            <View>
              <ProductGroups />
            </View>
          }
        />
        <Route
          path={'admin/productunits'}
          element={
            <View>
              <ProductUnits />
            </View>
          }
        />
        <Route
          path={'admin/properties'}
          element={
            <View>
              <Properties />
            </View>
          }
        />
        <Route
          path={'admin/propertyvalidationrules'}
          element={
            <View>
              <PropertyValidationRules />
            </View>
          }
        />
        <Route
          path={'admin/productgroupmappings'}
          element={
            <View>
              <ProductGroupMappings />
            </View>
          }
        />
        <Route
          path={'admin/loyalty'}
          element={
            <View>
              <Loyalty />
            </View>
          }
        />
        <Route
          path={'admin/tags'}
          element={
            <View>
              <Tags />
            </View>
          }
        />
        <Route
          path={'admin/warehouses'}
          element={
            <View>
              <Warehouses />
            </View>
          }
        />
        <Route
          path={'help'}
          element={
            <View>
              <Help />
            </View>
          }
        />
        <Route
          path={'*'}
          element={
            <View>
              <NotFound />
            </View>
          }
        />
        <Route
          path={'/integration-shopify-success/*'}
          element={
            <View>
              <IntegrationShopifySuccess />
            </View>
          }
        />
        <Route
          path={'/shopify/base'}
          element={
            <View>
              <IntegrationRedirectsShopifyBase />
            </View>
          }
        />
        <Route
          path={'/shopify/oauth'}
          element={
            <View>
              <IntegrationRedirectsShopifyOAuth />
            </View>
          }
        />
      </>
    )
  );

  return (
    <div className="App">
      <ReactNotifications />
      <PopupProvider>
        <ErrorHandlerProvider>
          <AuthenticationProvider>
            <PetCloudApiProvider>
              <UserProvider>
                <Authentication required="LOGGED_IN">
                  <AuthorizationProvider>
                    <GlobalStateProvider>
                      <RouterProvider router={router} />
                    </GlobalStateProvider>
                  </AuthorizationProvider>
                </Authentication>
                <Authentication required="NOT_LOGGED_IN">
                  <BrowserRouter>
                    <Routes>
                      <Route path={'*'} element={<Auth />} />
                    </Routes>
                  </BrowserRouter>
                </Authentication>
              </UserProvider>
            </PetCloudApiProvider>
          </AuthenticationProvider>
        </ErrorHandlerProvider>
      </PopupProvider>
    </div>
  );
};

export default App;
