import './manufacturerinitialadmin.css';
import { TFunction } from 'react-i18next';
import {
  CreateManufacturerRequest,
  CreateUserRequest,
  UpdateManufacturerRequest,
} from '../../../api/petcloudapi/api';
import MemberEdit from '../../settings/teammembers/memberedit/MemberEdit';
import { Error } from '../../../contexts/errorhandler/ErrorHandler';

interface ManufacturerInitialAdminProps {
  t: TFunction<'translation'>;
  manufacturer: CreateManufacturerRequest | UpdateManufacturerRequest;
  updateNewManufacturer?: (manufacturer: CreateManufacturerRequest) => void;
  errors?: Error[] | null;
}

const ManufacturerInitialAdmin: React.FC<ManufacturerInitialAdminProps> = ({
  t,
  manufacturer,
  updateNewManufacturer,
  errors,
}) => {
  const updateNewManufacturerUser = (user: CreateUserRequest) => {
    if (updateNewManufacturer !== null && updateNewManufacturer) {
      (manufacturer as CreateManufacturerRequest).user = user;
      updateNewManufacturer(manufacturer as CreateManufacturerRequest);
    }
  };

  const user = (manufacturer as CreateManufacturerRequest).user;
  // show form for adding via POST call
  return (
    <div className="manufacturerinitialadmin">
      {user !== undefined && user !== null ? (
        <MemberEdit
          t={t}
          member={user}
          updateNewUser={updateNewManufacturerUser}
          errors={errors}
        />
      ) : null}
    </div>
  );
};

export default ManufacturerInitialAdmin;
