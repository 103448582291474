import './manufactureraddress.css';
import { useContext, useEffect, useState } from 'react';
import { I18nContext, TFunction } from 'react-i18next';
import Input from '../../../elements/input/Input';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import {
  CountryResponse,
  CreateManufacturerRequest,
  UpdateManufacturerRequest,
} from '../../../api/petcloudapi/api';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import { Dropdown } from '../../../elements/selectors/Selectors';
import { Error, hasError } from '../../../contexts/errorhandler/ErrorHandler';

interface ManufacturerAddressProps {
  t: TFunction<'translation'>;
  updateNewManufacturer?: (manufacturer: CreateManufacturerRequest) => void;
  updateCurrentManufacturer?: (manufacturer: UpdateManufacturerRequest) => void;
  manufacturer: CreateManufacturerRequest | UpdateManufacturerRequest;
  errors?: Error[] | null;
}

const ManufacturerAddress: React.FC<ManufacturerAddressProps> = ({
  t,
  manufacturer,
  updateNewManufacturer,
  updateCurrentManufacturer,
  errors,
}) => {
  const { i18n } = useContext(I18nContext);
  const [countries, setCountries] = useState<CountryResponse[] | null>(null);
  const [selectedTranslatedCountry, setSelectedTranslatedCountry] = useState<
    string | null | undefined
  >(null);

  const api = usePetCloudApi().countriesApi();
  useEffect(() => {
    api.countriesGetCountries().then((response) => {
      console.log(response);
      const c = response.data;
      setCountries(c);
      setSelectedTranslatedCountry(getSelectedTranslatedCountry(c));
    });
  }, []);

  const getSelectedTranslatedCountry = (c: CountryResponse[]) => {
    const selectedCountry = c?.find(
      (country) => country.id === manufacturer.companyAddress?.countryId
    );
    const selectedTranslatedCountry =
      selectedCountry?.name[i18n.language as TranslatedStringIndex];
    if (selectedTranslatedCountry) {
      return selectedTranslatedCountry;
    } else {
      return null;
    }
  };

  const updateStreet = (e: string) => {
    if (manufacturer.companyAddress) {
      manufacturer.companyAddress.street = e;
      if (updateNewManufacturer) {
        updateNewManufacturer(manufacturer as CreateManufacturerRequest);
      } else if (updateCurrentManufacturer) {
        updateCurrentManufacturer(manufacturer as UpdateManufacturerRequest);
      }
    }
  };

  const updateStreetNumber = (e: string) => {
    if (manufacturer.companyAddress) {
      manufacturer.companyAddress.streetNumber = e;
      if (updateNewManufacturer) {
        updateNewManufacturer(manufacturer as CreateManufacturerRequest);
      } else if (updateCurrentManufacturer) {
        updateCurrentManufacturer(manufacturer as UpdateManufacturerRequest);
      }
    }
  };

  const updateZipCode = (e: string) => {
    if (manufacturer.companyAddress) {
      manufacturer.companyAddress.zipCode = e;
      if (updateNewManufacturer) {
        updateNewManufacturer(manufacturer as CreateManufacturerRequest);
      } else if (updateCurrentManufacturer) {
        updateCurrentManufacturer(manufacturer as UpdateManufacturerRequest);
      }
    }
  };

  const updateCity = (e: string) => {
    if (manufacturer.companyAddress) {
      manufacturer.companyAddress.city = e;
      if (updateNewManufacturer) {
        updateNewManufacturer(manufacturer as CreateManufacturerRequest);
      } else if (updateCurrentManufacturer) {
        updateCurrentManufacturer(manufacturer as UpdateManufacturerRequest);
      }
    }
  };

  const updateAdditional1 = (e: string) => {
    if (manufacturer.companyAddress) {
      manufacturer.companyAddress.additionalAddressLine1 = e;
      if (updateNewManufacturer) {
        updateNewManufacturer(manufacturer as CreateManufacturerRequest);
      } else if (updateCurrentManufacturer) {
        updateCurrentManufacturer(manufacturer as UpdateManufacturerRequest);
      }
    }
  };

  const updateAdditional2 = (e: string) => {
    if (manufacturer.companyAddress) {
      manufacturer.companyAddress.additionalAddressLine2 = e;
      if (updateNewManufacturer) {
        updateNewManufacturer(manufacturer as CreateManufacturerRequest);
      } else if (updateCurrentManufacturer) {
        updateCurrentManufacturer(manufacturer as UpdateManufacturerRequest);
      }
    }
  };

  const updateState = (e: string) => {
    if (manufacturer.companyAddress) {
      manufacturer.companyAddress.state = e;
      if (updateNewManufacturer) {
        updateNewManufacturer(manufacturer as CreateManufacturerRequest);
      } else if (updateCurrentManufacturer) {
        updateCurrentManufacturer(manufacturer as UpdateManufacturerRequest);
      }
    }
  };

  const updateCountry = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (manufacturer.companyAddress) {
      const id = e.target.selectedOptions[0].getAttribute('data-value');
      if (id) {
        manufacturer.companyAddress.countryId = id;
        if (updateNewManufacturer && countries) {
          updateNewManufacturer(manufacturer as CreateManufacturerRequest);
          setSelectedTranslatedCountry(getSelectedTranslatedCountry(countries));
        } else if (updateCurrentManufacturer && countries) {
          updateCurrentManufacturer(manufacturer as UpdateManufacturerRequest);
          setSelectedTranslatedCountry(getSelectedTranslatedCountry(countries));
        }
      }
    }
  };

  return (
    <div className="manufactureraddress">
      <div className="manufactureraddress-inputs">
        <div className="manufactureraddress-input" style={{ flex: 2 }}>
          <Input
            content={
              manufacturer.companyAddress && manufacturer.companyAddress.street
                ? manufacturer.companyAddress.street
                : undefined
            }
            update={updateStreet}
            title={t('view.manufacturer.meta.address.street')}
            errors={hasError(errors, ['CompanyAddress.Street'])}
          />
        </div>
        <div className="manufactureraddress-input" style={{ flex: 1 }}>
          <Input
            content={
              manufacturer.companyAddress &&
              manufacturer.companyAddress.streetNumber
                ? manufacturer.companyAddress.streetNumber
                : undefined
            }
            update={updateStreetNumber}
            title={t('view.manufacturer.meta.address.number')}
            errors={hasError(errors, ['CompanyAddress.StreetNumber'])}
          />
        </div>
      </div>
      <div className="manufactureraddress-inputs">
        <div className="manufactureraddress-input" style={{ flex: 1 }}>
          <Input
            content={
              manufacturer.companyAddress && manufacturer.companyAddress.zipCode
                ? manufacturer.companyAddress.zipCode
                : undefined
            }
            update={updateZipCode}
            title={t('view.manufacturer.meta.address.zip')}
            required={true}
            errors={hasError(errors, ['CompanyAddress.ZipCode'])}
          />
        </div>
        <div className="manufactureraddress-input" style={{ flex: 2 }}>
          <Input
            content={
              manufacturer.companyAddress && manufacturer.companyAddress.city
                ? manufacturer.companyAddress.city
                : undefined
            }
            update={updateCity}
            title={t('view.manufacturer.meta.address.city')}
            required={true}
            errors={hasError(errors, ['CompanyAddress.City'])}
          />
        </div>
      </div>
      <div className="manufactureraddress-inputs">
        <div className="manufactureraddress-input" style={{ flex: 1 }}>
          <Input
            content={
              manufacturer.companyAddress &&
              manufacturer.companyAddress.additionalAddressLine1
                ? manufacturer.companyAddress.additionalAddressLine1
                : undefined
            }
            update={updateAdditional1}
            title={t('view.manufacturer.meta.address.additional1')}
            errors={hasError(errors, ['CompanyAddress.AdditionalAddressLine1'])}
          />
        </div>
        <div className="manufactureraddress-input" style={{ flex: 1 }}>
          <Input
            content={
              manufacturer.companyAddress &&
              manufacturer.companyAddress.additionalAddressLine2
                ? manufacturer.companyAddress.additionalAddressLine2
                : undefined
            }
            update={updateAdditional2}
            title={t('view.manufacturer.meta.address.additional2')}
            errors={hasError(errors, ['CompanyAddress.AdditionalAddressLine2'])}
          />
        </div>
      </div>
      <div className="manufactureraddress-inputs">
        <div className="manufactureraddress-input" style={{ flex: 1 }}>
          <Input
            content={
              manufacturer.companyAddress && manufacturer.companyAddress.state
                ? manufacturer.companyAddress.state
                : undefined
            }
            update={updateState}
            title={t('view.manufacturer.meta.address.state')}
            errors={hasError(errors, ['CompanyAddress.State'])}
          />
        </div>
        <div className="manufactureraddress-input" style={{ flex: 1 }}>
          <div className="dropdown-wrapper">
            {countries ? (
              <Dropdown
                title={t('view.manufacturer.meta.address.country')}
                translatableOptionObjects={countries}
                update={updateCountry}
                selected={selectedTranslatedCountry}
                required={true}
                errors={
                  errors?.find(
                    (err) =>
                      err.key === 'CompanyAddress.Country' ||
                      err.key === '$.companyAddress.countryId'
                  )?.values
                }
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManufacturerAddress;
