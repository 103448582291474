import './productmappingproduct.css';
import { ProductMappingItem } from '../../../../../api/shopifyapi';
import { ReactComponent as ImgPlaceholder } from '../../../../../../assets/icon/image.svg';
import { ReactComponent as IconRemove } from '../../../../../../assets/icon/close.svg';
import LazyImage from '../../../../../elements/lazyimage/LazyImage';
import React, { useEffect } from 'react';
import Hoverable from '../../../../../elements/hoverable/Hoverable';
import { HintBox } from '../../../../../elements/hint/Hint';
import { useDraggable } from '@dnd-kit/core';

interface ProductMappingProductProps {
  item: ProductMappingItem;
  id: string;
  isMappable?: boolean;
  setIsDragging?: (bool: boolean) => void;
  unlinkDomainProduct?: () => void;
  showVariantIcon?: boolean;
}

const ProductMappingProduct: React.FC<ProductMappingProductProps> = ({
  item,
  id,
  isMappable,
  setIsDragging,
  unlinkDomainProduct,
  showVariantIcon,
}) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id,
      disabled: !isMappable,
    });

  useEffect(() => {
    if (setIsDragging) setIsDragging(isDragging);
  }, [isDragging]);

  return (
    <div
      ref={setNodeRef}
      style={{
        transform: transform
          ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
          : undefined,
      }}
      {...listeners}
      {...attributes}
      className={`productMappingProduct productMappingProduct__${
        isMappable ? 'draggable' : 'notDraggable'
      } productMappingProduct__${isDragging ? 'dragging' : 'notDragging'}`}
    >
      {showVariantIcon && item.parentId ? (
        <div className={'productMappingProduct-variantIndicator'}>v</div>
      ) : null}
      {item.imageUri ? (
        <LazyImage
          src={item.imageUri}
          className={'productMappingProduct-img'}
          alt={'Thumbnail'}
        />
      ) : (
        <ImgPlaceholder
          className={
            'productMappingProduct-img productMappingProduct-img__placeholder'
          }
          fill={'var(--color-border)'}
        />
      )}
      <div className={'productMappingProduct-title'}>{item.name}</div>
      <Hoverable
        onHoverNode={
          item.ean ? <HintBox paragraphs={[item.ean]} isToolTip /> : undefined
        }
        helperClass={'productMappingProduct-eanSku'}
      >
        <div
          className={'productMappingProduct-eanSku global-monospaced-contrast'}
        >
          {item.ean && item.ean !== ' ' ? item.ean : '-'}
        </div>
      </Hoverable>
      <Hoverable
        onHoverNode={
          item.sku ? <HintBox paragraphs={[item.sku]} isToolTip /> : undefined
        }
        helperClass={'productMappingProduct-eanSku'}
      >
        <div
          className={'productMappingProduct-eanSku global-monospaced-contrast'}
        >
          {item.sku && item.sku !== ' ' ? item.sku : '-'}
        </div>
      </Hoverable>
      <div className={'productMappingProduct-description'}>
        {item.description}
      </div>
      {isMappable && unlinkDomainProduct ? (
        <IconRemove
          className={'productMappingProduct-trash-icon'}
          onClick={unlinkDomainProduct}
        />
      ) : null}
    </div>
  );
};

export default ProductMappingProduct;
