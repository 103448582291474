/* tslint:disable */
/* eslint-disable */
/**
 * inpetto Connect API for Shopify | v1
 * Shopify
 *
 * The version of the OpenAPI document: 1
 * Contact: support@inpetto.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export enum BulkOperationErrorCode {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum BulkOperationStatus {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6
}

/**
 * 
 * @export
 * @interface DeliveryPosition
 */
export interface DeliveryPosition {
    /**
     * 
     * @type {string}
     * @memberof DeliveryPosition
     */
    'productId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeliveryPosition
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface Destination
 */
export interface Destination {
    /**
     * 
     * @type {string}
     * @memberof Destination
     */
    'first_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Destination
     */
    'address1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Destination
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Destination
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Destination
     */
    'zip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Destination
     */
    'province'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Destination
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Destination
     */
    'last_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Destination
     */
    'address2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Destination
     */
    'company'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Destination
     */
    'latitude'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Destination
     */
    'longitude'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Destination
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Destination
     */
    'country_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Destination
     */
    'province_code'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum EntityUpsertOperationEnum {
    Create = 'Create',
    Update = 'Update',
    Delete = 'Delete'
}

/**
 * 
 * @export
 * @interface EntityUpsertResult
 */
export interface EntityUpsertResult {
    /**
     * 
     * @type {boolean}
     * @memberof EntityUpsertResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof EntityUpsertResult
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof EntityUpsertResult
     */
    'entityId'?: string | null;
    /**
     * 
     * @type {EntityUpsertOperationEnum}
     * @memberof EntityUpsertResult
     */
    'operation'?: EntityUpsertOperationEnum;
}
/**
 * 
 * @export
 * @interface FulfillmentsRequest
 */
export interface FulfillmentsRequest {
    /**
     * 
     * @type {number}
     * @memberof FulfillmentsRequest
     */
    'id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FulfillmentsRequest
     */
    'order_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentsRequest
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentsRequest
     */
    'created_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentsRequest
     */
    'service'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentsRequest
     */
    'updated_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentsRequest
     */
    'tracking_company'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof FulfillmentsRequest
     */
    'shipment_status'?: any | null;
    /**
     * 
     * @type {number}
     * @memberof FulfillmentsRequest
     */
    'location_id'?: number | null;
    /**
     * 
     * @type {any}
     * @memberof FulfillmentsRequest
     */
    'origin_address'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentsRequest
     */
    'email'?: string | null;
    /**
     * 
     * @type {Destination}
     * @memberof FulfillmentsRequest
     */
    'destination'?: Destination;
    /**
     * 
     * @type {Array<LineItem>}
     * @memberof FulfillmentsRequest
     */
    'line_items'?: Array<LineItem> | null;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentsRequest
     */
    'tracking_number'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FulfillmentsRequest
     */
    'tracking_numbers'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentsRequest
     */
    'tracking_url'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FulfillmentsRequest
     */
    'tracking_urls'?: Array<string> | null;
    /**
     * 
     * @type {object}
     * @memberof FulfillmentsRequest
     */
    'receipt'?: object;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentsRequest
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentsRequest
     */
    'admin_graphql_api_id'?: string | null;
}
/**
 * 
 * @export
 * @interface GetInpettoClientBasedProductsRequest
 */
export interface GetInpettoClientBasedProductsRequest {
    /**
     * 
     * @type {string}
     * @memberof GetInpettoClientBasedProductsRequest
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @interface GetShopifyOrderDeliveryInformationNotificationConfigResponse
 */
export interface GetShopifyOrderDeliveryInformationNotificationConfigResponse {
    /**
     * 
     * @type {string}
     * @memberof GetShopifyOrderDeliveryInformationNotificationConfigResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetShopifyOrderDeliveryInformationNotificationConfigResponse
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetShopifyOrderDeliveryInformationNotificationConfigResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetShopifyOrderDeliveryInformationNotificationConfigResponse
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof GetShopifyOrderDeliveryInformationNotificationConfigResponse
     */
    'integrationSubModuleId': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetShopifyOrderDeliveryInformationNotificationConfigResponse
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetShopifyOrderDeliveryInformationNotificationConfigResponse
     */
    'config'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetShopifyOrderDeliveryInformationNotificationConfigResponse
     */
    'registered': boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GetShopifyOrderDeliveryInformationNotificationConfigResponse
     */
    'shippingProviderMapping'?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @interface GetShopifyProductStockNotificationConfigResponse
 */
export interface GetShopifyProductStockNotificationConfigResponse {
    /**
     * 
     * @type {string}
     * @memberof GetShopifyProductStockNotificationConfigResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetShopifyProductStockNotificationConfigResponse
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetShopifyProductStockNotificationConfigResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetShopifyProductStockNotificationConfigResponse
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof GetShopifyProductStockNotificationConfigResponse
     */
    'integrationSubModuleId': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetShopifyProductStockNotificationConfigResponse
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetShopifyProductStockNotificationConfigResponse
     */
    'config'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetShopifyProductStockNotificationConfigResponse
     */
    'registered': boolean;
}
/**
 * 
 * @export
 * @interface GetShopifyProductsRequest
 */
export interface GetShopifyProductsRequest {
    /**
     * 
     * @type {string}
     * @memberof GetShopifyProductsRequest
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {ShopifyProductVariantFilterCriteria}
     * @memberof GetShopifyProductsRequest
     */
    'filterCriteria'?: ShopifyProductVariantFilterCriteria;
}
/**
 * 
 * @export
 * @interface Image
 */
export interface Image {
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    '__typename': string | null;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    'id': string | null;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    'url': string | null;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    'altText': string | null;
    /**
     * 
     * @type {number}
     * @memberof Image
     */
    'height'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Image
     */
    'width'?: number | null;
}
/**
 * 
 * @export
 * @interface InpettoClientBasedIntegrationConfigResponse
 */
export interface InpettoClientBasedIntegrationConfigResponse {
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedIntegrationConfigResponse
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedIntegrationConfigResponse
     */
    'integrationId': string;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedIntegrationConfigResponse
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InpettoClientBasedIntegrationConfigResponse
     */
    'enabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InpettoClientBasedIntegrationConfigResponse
     */
    'installed': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum InpettoClientBasedIntegrationStatusPropertyName {
    IsReachable = 'isReachable',
    HasConfiguredKeys = 'hasConfiguredKeys',
    HasConfiguredManufacturerId = 'hasConfiguredManufacturerId',
    CanSignRequests = 'canSignRequests',
    CanAuthenticateResponses = 'canAuthenticateResponses'
}

/**
 * 
 * @export
 * @interface InpettoClientBasedIntegrationStatusPropertyNameStringKeyValuePair
 */
export interface InpettoClientBasedIntegrationStatusPropertyNameStringKeyValuePair {
    /**
     * 
     * @type {InpettoClientBasedIntegrationStatusPropertyName}
     * @memberof InpettoClientBasedIntegrationStatusPropertyNameStringKeyValuePair
     */
    'key'?: InpettoClientBasedIntegrationStatusPropertyName;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedIntegrationStatusPropertyNameStringKeyValuePair
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @interface InpettoClientBasedIntegrationStatusResponse
 */
export interface InpettoClientBasedIntegrationStatusResponse {
    /**
     * 
     * @type {boolean}
     * @memberof InpettoClientBasedIntegrationStatusResponse
     */
    'isReachable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InpettoClientBasedIntegrationStatusResponse
     */
    'hasConfiguredKeys'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InpettoClientBasedIntegrationStatusResponse
     */
    'hasConfiguredManufacturerId'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InpettoClientBasedIntegrationStatusResponse
     */
    'canSignRequests'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InpettoClientBasedIntegrationStatusResponse
     */
    'canAuthenticateResponses'?: boolean;
    /**
     * 
     * @type {Array<VersionKeyEnumStringKeyValuePair>}
     * @memberof InpettoClientBasedIntegrationStatusResponse
     */
    'versions'?: Array<VersionKeyEnumStringKeyValuePair> | null;
    /**
     * 
     * @type {Array<InpettoClientBasedIntegrationStatusPropertyNameStringKeyValuePair>}
     * @memberof InpettoClientBasedIntegrationStatusResponse
     */
    'errors'?: Array<InpettoClientBasedIntegrationStatusPropertyNameStringKeyValuePair> | null;
}
/**
 * 
 * @export
 * @interface InpettoClientBasedOrderDeliveryInformationNotificationConfigResponse
 */
export interface InpettoClientBasedOrderDeliveryInformationNotificationConfigResponse {
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedOrderDeliveryInformationNotificationConfigResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedOrderDeliveryInformationNotificationConfigResponse
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedOrderDeliveryInformationNotificationConfigResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedOrderDeliveryInformationNotificationConfigResponse
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedOrderDeliveryInformationNotificationConfigResponse
     */
    'integrationSubModuleId': string;
    /**
     * 
     * @type {boolean}
     * @memberof InpettoClientBasedOrderDeliveryInformationNotificationConfigResponse
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedOrderDeliveryInformationNotificationConfigResponse
     */
    'config'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InpettoClientBasedOrderDeliveryInformationNotificationConfigResponse
     */
    'registered': boolean;
}
/**
 * 
 * @export
 * @interface InpettoClientBasedOrderExportConfigResponse
 */
export interface InpettoClientBasedOrderExportConfigResponse {
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedOrderExportConfigResponse
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InpettoClientBasedOrderExportConfigResponse
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedOrderExportConfigResponse
     */
    'integrationSubModuleId': string;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedOrderExportConfigResponse
     */
    'cronExpression'?: string | null;
}
/**
 * 
 * @export
 * @interface InpettoClientBasedProductImportConfigResponse
 */
export interface InpettoClientBasedProductImportConfigResponse {
    /**
     * 
     * @type {boolean}
     * @memberof InpettoClientBasedProductImportConfigResponse
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedProductImportConfigResponse
     */
    'integrationSubModuleId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InpettoClientBasedProductImportConfigResponse
     */
    'productIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InpettoClientBasedProductImportConfigResponse
     */
    'productVariantIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedProductImportConfigResponse
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @interface InpettoClientBasedProductMappingRequest
 */
export interface InpettoClientBasedProductMappingRequest {
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedProductMappingRequest
     */
    'externalParentProductId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedProductMappingRequest
     */
    'externalVariantProductId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedProductMappingRequest
     */
    'externalVariantProductStockId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedProductMappingRequest
     */
    'domainProductId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedProductMappingRequest
     */
    'domainProductStockId'?: string | null;
}
/**
 * 
 * @export
 * @interface InpettoClientBasedProductStockNotificationConfigResponse
 */
export interface InpettoClientBasedProductStockNotificationConfigResponse {
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedProductStockNotificationConfigResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedProductStockNotificationConfigResponse
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedProductStockNotificationConfigResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedProductStockNotificationConfigResponse
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedProductStockNotificationConfigResponse
     */
    'integrationSubModuleId': string;
    /**
     * 
     * @type {boolean}
     * @memberof InpettoClientBasedProductStockNotificationConfigResponse
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedProductStockNotificationConfigResponse
     */
    'config'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InpettoClientBasedProductStockNotificationConfigResponse
     */
    'registered': boolean;
}
/**
 * 
 * @export
 * @interface InpettoClientBasedProductSyncRequest
 */
export interface InpettoClientBasedProductSyncRequest {
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedProductSyncRequest
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @interface InpettoClientHmacKeyPair
 */
export interface InpettoClientHmacKeyPair {
    /**
     * 
     * @type {string}
     * @memberof InpettoClientHmacKeyPair
     */
    'hmacSenderKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientHmacKeyPair
     */
    'hmacReceiverKey'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum IntegrationIdentifierType {
    Shopware = 'Shopware',
    Shopify = 'Shopify',
    Magento = 'Magento'
}

/**
 * 
 * @export
 * @interface IntegrationSubModuleLogDocument
 */
export interface IntegrationSubModuleLogDocument {
    /**
     * 
     * @type {string}
     * @memberof IntegrationSubModuleLogDocument
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSubModuleLogDocument
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSubModuleLogDocument
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSubModuleLogDocument
     */
    'manufacturerId'?: string;
    /**
     * 
     * @type {LogLevel}
     * @memberof IntegrationSubModuleLogDocument
     */
    'level'?: LogLevel;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSubModuleLogDocument
     */
    'message'?: string | null;
    /**
     * 
     * @type {IntegrationSubModuleType}
     * @memberof IntegrationSubModuleLogDocument
     */
    'type'?: IntegrationSubModuleType;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSubModuleLogDocument
     */
    'integrationIdentifier'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum IntegrationSubModuleType {
    Base = 'Base',
    OrderExport = 'OrderExport',
    InvoiceDocumentExport = 'InvoiceDocumentExport',
    CreditNoteDocumentExport = 'CreditNoteDocumentExport',
    OrderCancellationInvoiceExport = 'OrderCancellationInvoiceExport',
    CustomerExport = 'CustomerExport',
    OrderDeliveryInformationExport = 'OrderDeliveryInformationExport',
    ProductImport = 'ProductImport',
    InvoiceDocumentImport = 'InvoiceDocumentImport',
    ProductStockImport = 'ProductStockImport',
    OrderDeliveryInformationImport = 'OrderDeliveryInformationImport',
    WebhookRegistrar = 'WebhookRegistrar'
}

/**
 * 
 * @export
 * @interface InventoryItem
 */
export interface InventoryItem {
    /**
     * 
     * @type {string}
     * @memberof InventoryItem
     */
    'id': string | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryItem
     */
    '__typename': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryItem
     */
    'tracked'?: boolean | null;
}
/**
 * 
 * @export
 * @interface InventoryItemUpdateRequest
 */
export interface InventoryItemUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof InventoryItemUpdateRequest
     */
    'inventory_item_id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryItemUpdateRequest
     */
    'location_id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryItemUpdateRequest
     */
    'available'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryItemUpdateRequest
     */
    'updated_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryItemUpdateRequest
     */
    'admin_graphql_api_id'?: string | null;
}
/**
 * 
 * @export
 * @interface InvokeShopifyAppDeploymentRequest
 */
export interface InvokeShopifyAppDeploymentRequest {
    /**
     * 
     * @type {string}
     * @memberof InvokeShopifyAppDeploymentRequest
     */
    'shopDomain': string;
    /**
     * 
     * @type {string}
     * @memberof InvokeShopifyAppDeploymentRequest
     */
    'manufacturerId': string;
}
/**
 * 
 * @export
 * @interface LineItem
 */
export interface LineItem {
    /**
     * 
     * @type {number}
     * @memberof LineItem
     */
    'id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LineItem
     */
    'variant_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LineItem
     */
    'quantity'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'sku'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'variant_title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'vendor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'fulfillment_service'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LineItem
     */
    'product_id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof LineItem
     */
    'requires_shipping'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof LineItem
     */
    'taxable'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof LineItem
     */
    'gift_card'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'variant_inventory_management'?: string | null;
    /**
     * 
     * @type {Array<any>}
     * @memberof LineItem
     */
    'properties'?: Array<any> | null;
    /**
     * 
     * @type {boolean}
     * @memberof LineItem
     */
    'product_exists'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof LineItem
     */
    'fulfillable_quantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LineItem
     */
    'grams'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'price'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'total_discount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'fulfillment_status'?: string | null;
    /**
     * 
     * @type {Array<any>}
     * @memberof LineItem
     */
    'duties'?: Array<any> | null;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'admin_graphql_api_id'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum LogLevel {
    Trace = 'Trace',
    Debug = 'Debug',
    Information = 'Information',
    Warning = 'Warning',
    Error = 'Error',
    Critical = 'Critical',
    None = 'None'
}

/**
 * 
 * @export
 * @interface MediaImage
 */
export interface MediaImage {
    /**
     * 
     * @type {string}
     * @memberof MediaImage
     */
    '__typename': string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaImage
     */
    'id': string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaImage
     */
    'mediaContentType': string | null;
    /**
     * 
     * @type {MediaStatus}
     * @memberof MediaImage
     */
    'status': MediaStatus;
    /**
     * 
     * @type {string}
     * @memberof MediaImage
     */
    'alt': string | null;
    /**
     * 
     * @type {Image}
     * @memberof MediaImage
     */
    'image': Image;
    /**
     * 
     * @type {string}
     * @memberof MediaImage
     */
    'createdAt'?: string | null;
    /**
     * 
     * @type {OriginalSource}
     * @memberof MediaImage
     */
    'originalSource': OriginalSource;
    /**
     * 
     * @type {string}
     * @memberof MediaImage
     */
    '__parentId': string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum MediaStatus {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum Operation {
    Create = 'Create',
    Read = 'Read',
    Update = 'Update',
    Delete = 'Delete',
    Invoke = 'Invoke',
    Export = 'Export'
}

/**
 * 
 * @export
 * @interface OriginalSource
 */
export interface OriginalSource {
    /**
     * 
     * @type {number}
     * @memberof OriginalSource
     */
    'fileSize'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OriginalSource
     */
    'url': string | null;
    /**
     * 
     * @type {string}
     * @memberof OriginalSource
     */
    '__typename': string | null;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'id': string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'parentId': string | null;
    /**
     * 
     * @type {Array<Product>}
     * @memberof Product
     */
    'variants'?: Array<Product> | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'name': string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'priceGross'?: number | null;
    /**
     * 
     * @type {TaxTypeIdentifier}
     * @memberof Product
     */
    'taxTypeIdentifier'?: TaxTypeIdentifier;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'ean'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'sku'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'imageId'?: string | null;
    /**
     * 
     * @type {Array<ProductImage>}
     * @memberof Product
     */
    'productImages'?: Array<ProductImage> | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'vendor'?: string | null;
    /**
     * 
     * @type {Array<ProductStock>}
     * @memberof Product
     */
    'productStocks'?: Array<ProductStock> | null;
}
/**
 * 
 * @export
 * @interface ProductImage
 */
export interface ProductImage {
    /**
     * 
     * @type {string}
     * @memberof ProductImage
     */
    'id': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductImage
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductImage
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductImage
     */
    'position'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductImage
     */
    'uri': string | null;
}
/**
 * 
 * @export
 * @interface ProductMapping
 */
export interface ProductMapping {
    /**
     * 
     * @type {ProductMappingItem}
     * @memberof ProductMapping
     */
    'externalProduct'?: ProductMappingItem;
    /**
     * 
     * @type {ProductMappingItem}
     * @memberof ProductMapping
     */
    'domainProduct'?: ProductMappingItem;
    /**
     * 
     * @type {ProductMappingStrategy}
     * @memberof ProductMapping
     */
    'strategy'?: ProductMappingStrategy;
}
/**
 * 
 * @export
 * @interface ProductMappingItem
 */
export interface ProductMappingItem {
    /**
     * 
     * @type {string}
     * @memberof ProductMappingItem
     */
    'id': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductMappingItem
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductMappingItem
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductMappingItem
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductMappingItem
     */
    'sku'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductMappingItem
     */
    'ean'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductMappingItem
     */
    'imageUri'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductMappingItem
     */
    'productStockId'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductMappingRequest
 */
export interface ProductMappingRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductMappingRequest
     */
    'shopifyParentProductId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductMappingRequest
     */
    'shopifyVariantProductId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductMappingRequest
     */
    'shopifyVariantProductStockId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductMappingRequest
     */
    'domainProductId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductMappingRequest
     */
    'domainProductStockId'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ProductMappingStrategy {
    Proposal = 'Proposal',
    Mapped = 'Mapped',
    Unknown = 'Unknown'
}

/**
 * 
 * @export
 * @interface ProductSeo
 */
export interface ProductSeo {
    /**
     * 
     * @type {string}
     * @memberof ProductSeo
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductSeo
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ProductStatus {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

/**
 * 
 * @export
 * @interface ProductStock
 */
export interface ProductStock {
    /**
     * 
     * @type {number}
     * @memberof ProductStock
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ProductVariantInventoryPolicy {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum ShippingProviderEnum {
    Selbstabholung = 'Selbstabholung',
    Dhl = 'DHL',
    Standard = 'Standard',
    Hermes = 'Hermes',
    Ups = 'UPS',
    Dpd = 'DPD',
    Composite = 'COMPOSITE',
    Unknown = 'UNKNOWN',
    Fedex = 'FEDEX',
    Gls = 'GLS'
}

/**
 * 
 * @export
 * @interface ShopifyBulkOperationFinishWebhookRequest
 */
export interface ShopifyBulkOperationFinishWebhookRequest {
    /**
     * 
     * @type {string}
     * @memberof ShopifyBulkOperationFinishWebhookRequest
     */
    'admin_graphql_api_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShopifyBulkOperationFinishWebhookRequest
     */
    'completed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShopifyBulkOperationFinishWebhookRequest
     */
    'created_at'?: string;
    /**
     * 
     * @type {BulkOperationErrorCode}
     * @memberof ShopifyBulkOperationFinishWebhookRequest
     */
    'error_code'?: BulkOperationErrorCode;
    /**
     * 
     * @type {BulkOperationStatus}
     * @memberof ShopifyBulkOperationFinishWebhookRequest
     */
    'status'?: BulkOperationStatus;
}
/**
 * 
 * @export
 * @interface ShopifyIntegrationConfigResponse
 */
export interface ShopifyIntegrationConfigResponse {
    /**
     * 
     * @type {string}
     * @memberof ShopifyIntegrationConfigResponse
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof ShopifyIntegrationConfigResponse
     */
    'appClientId': string;
    /**
     * 
     * @type {string}
     * @memberof ShopifyIntegrationConfigResponse
     */
    'appClientSecret': string;
    /**
     * 
     * @type {string}
     * @memberof ShopifyIntegrationConfigResponse
     */
    'installationLink': string;
    /**
     * 
     * @type {string}
     * @memberof ShopifyIntegrationConfigResponse
     */
    'shopifyAppVersionLink': string;
    /**
     * 
     * @type {string}
     * @memberof ShopifyIntegrationConfigResponse
     */
    'bulkOperationWebhookId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShopifyIntegrationConfigResponse
     */
    'integrationId': string;
    /**
     * 
     * @type {string}
     * @memberof ShopifyIntegrationConfigResponse
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShopifyIntegrationConfigResponse
     */
    'enabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShopifyIntegrationConfigResponse
     */
    'installed': boolean;
}
/**
 * 
 * @export
 * @interface ShopifyOrderExportConfigResponse
 */
export interface ShopifyOrderExportConfigResponse {
    /**
     * 
     * @type {string}
     * @memberof ShopifyOrderExportConfigResponse
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShopifyOrderExportConfigResponse
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof ShopifyOrderExportConfigResponse
     */
    'integrationSubModuleId': string;
    /**
     * 
     * @type {string}
     * @memberof ShopifyOrderExportConfigResponse
     */
    'exportFlag': string | null;
    /**
     * 
     * @type {string}
     * @memberof ShopifyOrderExportConfigResponse
     */
    'cronExpression'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShopifyOrderExportConfigResponse
     */
    'splitShippingLinesByTaxes'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShopifyOrderExportConfigResponse
     */
    'shippingLineTitle'?: string | null;
}
/**
 * 
 * @export
 * @interface ShopifyProduct202501
 */
export interface ShopifyProduct202501 {
    /**
     * 
     * @type {string}
     * @memberof ShopifyProduct202501
     */
    '__typename': string | null;
    /**
     * 
     * @type {string}
     * @memberof ShopifyProduct202501
     */
    'id': string | null;
    /**
     * 
     * @type {string}
     * @memberof ShopifyProduct202501
     */
    '__parentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShopifyProduct202501
     */
    'title': string | null;
    /**
     * 
     * @type {string}
     * @memberof ShopifyProduct202501
     */
    'descriptionHtml': string | null;
    /**
     * 
     * @type {string}
     * @memberof ShopifyProduct202501
     */
    'createdAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShopifyProduct202501
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShopifyProduct202501
     */
    'publishedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShopifyProduct202501
     */
    'vendor': string | null;
    /**
     * 
     * @type {string}
     * @memberof ShopifyProduct202501
     */
    'productType': string | null;
    /**
     * 
     * @type {string}
     * @memberof ShopifyProduct202501
     */
    'handle': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShopifyProduct202501
     */
    'tags': Array<string> | null;
    /**
     * 
     * @type {ProductStatus}
     * @memberof ShopifyProduct202501
     */
    'status': ProductStatus;
    /**
     * 
     * @type {ProductSeo}
     * @memberof ShopifyProduct202501
     */
    'seo': ProductSeo;
    /**
     * 
     * @type {Array<MediaImage>}
     * @memberof ShopifyProduct202501
     */
    'mediaImages'?: Array<MediaImage> | null;
    /**
     * 
     * @type {Array<ShopifyProductVariant202501>}
     * @memberof ShopifyProduct202501
     */
    'productVariants'?: Array<ShopifyProductVariant202501> | null;
}
/**
 * 
 * @export
 * @interface ShopifyProductImportConfigResponse
 */
export interface ShopifyProductImportConfigResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ShopifyProductImportConfigResponse
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof ShopifyProductImportConfigResponse
     */
    'integrationSubModuleId': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ShopifyProductImportConfigResponse
     */
    'productIds': Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ShopifyProductImportConfigResponse
     */
    'productVariantIds': Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof ShopifyProductImportConfigResponse
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @interface ShopifyProductSyncRequest
 */
export interface ShopifyProductSyncRequest {
    /**
     * 
     * @type {string}
     * @memberof ShopifyProductSyncRequest
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @interface ShopifyProductVariant202501
 */
export interface ShopifyProductVariant202501 {
    /**
     * 
     * @type {string}
     * @memberof ShopifyProductVariant202501
     */
    '__typename': string | null;
    /**
     * 
     * @type {string}
     * @memberof ShopifyProductVariant202501
     */
    'id': string | null;
    /**
     * 
     * @type {string}
     * @memberof ShopifyProductVariant202501
     */
    '__parentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShopifyProductVariant202501
     */
    'title': string | null;
    /**
     * 
     * @type {string}
     * @memberof ShopifyProductVariant202501
     */
    'sku': string | null;
    /**
     * 
     * @type {number}
     * @memberof ShopifyProductVariant202501
     */
    'position'?: number | null;
    /**
     * 
     * @type {ProductVariantInventoryPolicy}
     * @memberof ShopifyProductVariant202501
     */
    'inventoryPolicy'?: ProductVariantInventoryPolicy;
    /**
     * 
     * @type {number}
     * @memberof ShopifyProductVariant202501
     */
    'inventoryQuantity'?: number | null;
    /**
     * 
     * @type {InventoryItem}
     * @memberof ShopifyProductVariant202501
     */
    'inventoryItem': InventoryItem;
    /**
     * 
     * @type {string}
     * @memberof ShopifyProductVariant202501
     */
    'price': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShopifyProductVariant202501
     */
    'availableForSale'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ShopifyProductVariant202501
     */
    'barcode': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShopifyProductVariant202501
     */
    'taxable'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ShopifyProductVariant202501
     */
    'taxCode': string | null;
    /**
     * 
     * @type {string}
     * @memberof ShopifyProductVariant202501
     */
    'displayName': string | null;
    /**
     * 
     * @type {Image}
     * @memberof ShopifyProductVariant202501
     */
    'image'?: Image;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ShopifyProductVariantFieldIdentifier {
    Barcode = 'Barcode',
    Sku = 'Sku',
    Id = 'Id'
}

/**
 * 
 * @export
 * @interface ShopifyProductVariantFilterCriteria
 */
export interface ShopifyProductVariantFilterCriteria {
    /**
     * 
     * @type {ShopifyProductVariantFieldIdentifier}
     * @memberof ShopifyProductVariantFilterCriteria
     */
    'field': ShopifyProductVariantFieldIdentifier;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShopifyProductVariantFilterCriteria
     */
    'values': Array<string> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ShopifyProductsRefreshStatus {
    Pending = 'Pending',
    Invalidated = 'Invalidated',
    Completed = 'Completed',
    Failed = 'Failed'
}

/**
 * 
 * @export
 * @interface ShopifyProductsStatusResponse
 */
export interface ShopifyProductsStatusResponse {
    /**
     * 
     * @type {ShopifyProductsRefreshStatus}
     * @memberof ShopifyProductsStatusResponse
     */
    'status'?: ShopifyProductsRefreshStatus;
    /**
     * 
     * @type {string}
     * @memberof ShopifyProductsStatusResponse
     */
    'manufacturerId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShopifyProductsStatusResponse
     */
    'errorMessages'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface ShopifyRedirectResponse
 */
export interface ShopifyRedirectResponse {
    /**
     * 
     * @type {string}
     * @memberof ShopifyRedirectResponse
     */
    'redirectUri': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum TaxTypeIdentifier {
    Free = 'Free',
    Reduced = 'Reduced',
    Full = 'Full'
}

/**
 * 
 * @export
 * @interface UpsertHmacCredentialsRequest
 */
export interface UpsertHmacCredentialsRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertHmacCredentialsRequest
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertHmacCredentialsRequest
     */
    'integrationId': string;
}
/**
 * 
 * @export
 * @interface UpsertInpettoClientBasedIntegrationConfigRequest
 */
export interface UpsertInpettoClientBasedIntegrationConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertInpettoClientBasedIntegrationConfigRequest
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertInpettoClientBasedIntegrationConfigRequest
     */
    'integrationId': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertInpettoClientBasedIntegrationConfigRequest
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertInpettoClientBasedIntegrationConfigRequest
     */
    'enabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertInpettoClientBasedIntegrationConfigRequest
     */
    'installed'?: boolean;
}
/**
 * 
 * @export
 * @interface UpsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest
 */
export interface UpsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest
     */
    'integrationSubModuleId': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest
     */
    'enabled': boolean;
}
/**
 * 
 * @export
 * @interface UpsertInpettoClientBasedOrderExportConfigRequest
 */
export interface UpsertInpettoClientBasedOrderExportConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertInpettoClientBasedOrderExportConfigRequest
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertInpettoClientBasedOrderExportConfigRequest
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpsertInpettoClientBasedOrderExportConfigRequest
     */
    'integrationSubModuleId': string;
}
/**
 * 
 * @export
 * @interface UpsertInpettoClientBasedProductImportConfigRequest
 */
export interface UpsertInpettoClientBasedProductImportConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertInpettoClientBasedProductImportConfigRequest
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertInpettoClientBasedProductImportConfigRequest
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpsertInpettoClientBasedProductImportConfigRequest
     */
    'integrationSubModuleId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpsertInpettoClientBasedProductImportConfigRequest
     */
    'productIds': Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpsertInpettoClientBasedProductImportConfigRequest
     */
    'productVariantIds': Array<string> | null;
}
/**
 * 
 * @export
 * @interface UpsertInpettoClientBasedProductMappingsRequest
 */
export interface UpsertInpettoClientBasedProductMappingsRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertInpettoClientBasedProductMappingsRequest
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {Array<InpettoClientBasedProductMappingRequest>}
     * @memberof UpsertInpettoClientBasedProductMappingsRequest
     */
    'mappings': Array<InpettoClientBasedProductMappingRequest> | null;
}
/**
 * 
 * @export
 * @interface UpsertInpettoClientBasedProductStockNotificationConfigRequest
 */
export interface UpsertInpettoClientBasedProductStockNotificationConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertInpettoClientBasedProductStockNotificationConfigRequest
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertInpettoClientBasedProductStockNotificationConfigRequest
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpsertInpettoClientBasedProductStockNotificationConfigRequest
     */
    'integrationSubModuleId': string;
}
/**
 * 
 * @export
 * @interface UpsertOrderDeliveryInformationRequest
 */
export interface UpsertOrderDeliveryInformationRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderDeliveryInformationRequest
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderDeliveryInformationRequest
     */
    'trackingCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertOrderDeliveryInformationRequest
     */
    'weight'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderDeliveryInformationRequest
     */
    'transmissionDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderDeliveryInformationRequest
     */
    'shippingDateEarliest'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderDeliveryInformationRequest
     */
    'shippingDateLatest'?: string | null;
    /**
     * 
     * @type {Array<DeliveryPosition>}
     * @memberof UpsertOrderDeliveryInformationRequest
     */
    'positions'?: Array<DeliveryPosition> | null;
    /**
     * 
     * @type {ShippingProviderEnum}
     * @memberof UpsertOrderDeliveryInformationRequest
     */
    'shippingProvider'?: ShippingProviderEnum;
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderDeliveryInformationRequest
     */
    'orderId'?: string | null;
}
/**
 * 
 * @export
 * @interface UpsertProductStockRequest
 */
export interface UpsertProductStockRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertProductStockRequest
     */
    'productId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertProductStockRequest
     */
    'newQuantity'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertProductStockRequest
     */
    'isCloseout'?: boolean;
}
/**
 * 
 * @export
 * @interface UpsertShopifyIntegrationConfigRequest
 */
export interface UpsertShopifyIntegrationConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyIntegrationConfigRequest
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyIntegrationConfigRequest
     */
    'appClientId': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyIntegrationConfigRequest
     */
    'appClientSecret': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyIntegrationConfigRequest
     */
    'installationLink': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyIntegrationConfigRequest
     */
    'shopifyAppVersionLink': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyIntegrationConfigRequest
     */
    'bulkOperationWebhookId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyIntegrationConfigRequest
     */
    'integrationId': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyIntegrationConfigRequest
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertShopifyIntegrationConfigRequest
     */
    'enabled': boolean;
}
/**
 * 
 * @export
 * @interface UpsertShopifyOrderDeliveryInformationNotificationConfigRequest
 */
export interface UpsertShopifyOrderDeliveryInformationNotificationConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyOrderDeliveryInformationNotificationConfigRequest
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertShopifyOrderDeliveryInformationNotificationConfigRequest
     */
    'enabled': boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpsertShopifyOrderDeliveryInformationNotificationConfigRequest
     */
    'shippingProviderMapping'?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @interface UpsertShopifyOrderExportConfigRequest
 */
export interface UpsertShopifyOrderExportConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyOrderExportConfigRequest
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertShopifyOrderExportConfigRequest
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyOrderExportConfigRequest
     */
    'integrationSubModuleId': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyOrderExportConfigRequest
     */
    'exportFlag': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertShopifyOrderExportConfigRequest
     */
    'splitShippingLinesByTaxes'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyOrderExportConfigRequest
     */
    'shippingLineTitle'?: string | null;
}
/**
 * 
 * @export
 * @interface UpsertShopifyProductImportConfigRequest
 */
export interface UpsertShopifyProductImportConfigRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpsertShopifyProductImportConfigRequest
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyProductImportConfigRequest
     */
    'integrationSubModuleId': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpsertShopifyProductImportConfigRequest
     */
    'productIds': Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpsertShopifyProductImportConfigRequest
     */
    'productVariantIds': Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyProductImportConfigRequest
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @interface UpsertShopifyProductMappingsRequest
 */
export interface UpsertShopifyProductMappingsRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyProductMappingsRequest
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {Array<ProductMappingRequest>}
     * @memberof UpsertShopifyProductMappingsRequest
     */
    'mappings': Array<ProductMappingRequest> | null;
}
/**
 * 
 * @export
 * @interface UpsertShopifyProductStockNotificationConfigRequest
 */
export interface UpsertShopifyProductStockNotificationConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyProductStockNotificationConfigRequest
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertShopifyProductStockNotificationConfigRequest
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyProductStockNotificationConfigRequest
     */
    'integrationSubModuleId': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum VersionKeyEnum {
    PhpVersion = 'phpVersion',
    ShopVersion = 'shopVersion',
    ErpVersion = 'erpVersion',
    InpettoConnectVersion = 'inpettoConnectVersion',
    DatabaseVersion = 'databaseVersion'
}

/**
 * 
 * @export
 * @interface VersionKeyEnumStringKeyValuePair
 */
export interface VersionKeyEnumStringKeyValuePair {
    /**
     * 
     * @type {VersionKeyEnum}
     * @memberof VersionKeyEnumStringKeyValuePair
     */
    'key'?: VersionKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof VersionKeyEnumStringKeyValuePair
     */
    'value'?: string | null;
}

/**
 * IntegrationLoggingApi - axios parameter creator
 * @export
 */
export const IntegrationLoggingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {IntegrationIdentifierType} [integrationIdentifier] 
         * @param {IntegrationSubModuleType} [subModuleType] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {number} [limit] 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLogsGet: async (integrationIdentifier?: IntegrationIdentifierType, subModuleType?: IntegrationSubModuleType, startDate?: string, endDate?: string, limit?: number, manufacturerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (integrationIdentifier !== undefined) {
                localVarQueryParameter['IntegrationIdentifier'] = integrationIdentifier;
            }

            if (subModuleType !== undefined) {
                localVarQueryParameter['SubModuleType'] = subModuleType;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['StartDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['EndDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (limit !== undefined) {
                localVarQueryParameter['Limit'] = limit;
            }

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrationLoggingApi - functional programming interface
 * @export
 */
export const IntegrationLoggingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntegrationLoggingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {IntegrationIdentifierType} [integrationIdentifier] 
         * @param {IntegrationSubModuleType} [subModuleType] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {number} [limit] 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLogsGet(integrationIdentifier?: IntegrationIdentifierType, subModuleType?: IntegrationSubModuleType, startDate?: string, endDate?: string, limit?: number, manufacturerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IntegrationSubModuleLogDocument>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLogsGet(integrationIdentifier, subModuleType, startDate, endDate, limit, manufacturerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IntegrationLoggingApi - factory interface
 * @export
 */
export const IntegrationLoggingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntegrationLoggingApiFp(configuration)
    return {
        /**
         * 
         * @param {IntegrationIdentifierType} [integrationIdentifier] 
         * @param {IntegrationSubModuleType} [subModuleType] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {number} [limit] 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLogsGet(integrationIdentifier?: IntegrationIdentifierType, subModuleType?: IntegrationSubModuleType, startDate?: string, endDate?: string, limit?: number, manufacturerId?: string, options?: any): AxiosPromise<Array<IntegrationSubModuleLogDocument>> {
            return localVarFp.apiLogsGet(integrationIdentifier, subModuleType, startDate, endDate, limit, manufacturerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntegrationLoggingApi - object-oriented interface
 * @export
 * @class IntegrationLoggingApi
 * @extends {BaseAPI}
 */
export class IntegrationLoggingApi extends BaseAPI {
    /**
     * 
     * @param {IntegrationIdentifierType} [integrationIdentifier] 
     * @param {IntegrationSubModuleType} [subModuleType] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {number} [limit] 
     * @param {string} [manufacturerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationLoggingApi
     */
    public apiLogsGet(integrationIdentifier?: IntegrationIdentifierType, subModuleType?: IntegrationSubModuleType, startDate?: string, endDate?: string, limit?: number, manufacturerId?: string, options?: AxiosRequestConfig) {
        return IntegrationLoggingApiFp(this.configuration).apiLogsGet(integrationIdentifier, subModuleType, startDate, endDate, limit, manufacturerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShopifyAppApi - axios parameter creator
 * @export
 */
export const ShopifyAppApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [hmac] 
         * @param {string} [shop] 
         * @param {string} [timestamp] 
         * @param {string} [host] 
         * @param {string} [session] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1BaseGet: async (hmac?: string, shop?: string, timestamp?: string, host?: string, session?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopify/v1/base`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (hmac !== undefined) {
                localVarQueryParameter['hmac'] = hmac;
            }

            if (shop !== undefined) {
                localVarQueryParameter['shop'] = shop;
            }

            if (timestamp !== undefined) {
                localVarQueryParameter['timestamp'] = timestamp;
            }

            if (host !== undefined) {
                localVarQueryParameter['host'] = host;
            }

            if (session !== undefined) {
                localVarQueryParameter['session'] = session;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [code] 
         * @param {string} [hmac] 
         * @param {string} [host] 
         * @param {string} [shop] 
         * @param {string} [timestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1OauthCodeGet: async (code?: string, hmac?: string, host?: string, shop?: string, timestamp?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopify/v1/oauth/code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (hmac !== undefined) {
                localVarQueryParameter['hmac'] = hmac;
            }

            if (host !== undefined) {
                localVarQueryParameter['host'] = host;
            }

            if (shop !== undefined) {
                localVarQueryParameter['shop'] = shop;
            }

            if (timestamp !== undefined) {
                localVarQueryParameter['timestamp'] = timestamp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShopifyAppApi - functional programming interface
 * @export
 */
export const ShopifyAppApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShopifyAppApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [hmac] 
         * @param {string} [shop] 
         * @param {string} [timestamp] 
         * @param {string} [host] 
         * @param {string} [session] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopifyV1BaseGet(hmac?: string, shop?: string, timestamp?: string, host?: string, session?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShopifyRedirectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopifyV1BaseGet(hmac, shop, timestamp, host, session, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [code] 
         * @param {string} [hmac] 
         * @param {string} [host] 
         * @param {string} [shop] 
         * @param {string} [timestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopifyV1OauthCodeGet(code?: string, hmac?: string, host?: string, shop?: string, timestamp?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShopifyRedirectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopifyV1OauthCodeGet(code, hmac, host, shop, timestamp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShopifyAppApi - factory interface
 * @export
 */
export const ShopifyAppApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShopifyAppApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [hmac] 
         * @param {string} [shop] 
         * @param {string} [timestamp] 
         * @param {string} [host] 
         * @param {string} [session] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1BaseGet(hmac?: string, shop?: string, timestamp?: string, host?: string, session?: string, options?: any): AxiosPromise<ShopifyRedirectResponse> {
            return localVarFp.shopifyV1BaseGet(hmac, shop, timestamp, host, session, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [code] 
         * @param {string} [hmac] 
         * @param {string} [host] 
         * @param {string} [shop] 
         * @param {string} [timestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1OauthCodeGet(code?: string, hmac?: string, host?: string, shop?: string, timestamp?: string, options?: any): AxiosPromise<ShopifyRedirectResponse> {
            return localVarFp.shopifyV1OauthCodeGet(code, hmac, host, shop, timestamp, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShopifyAppApi - object-oriented interface
 * @export
 * @class ShopifyAppApi
 * @extends {BaseAPI}
 */
export class ShopifyAppApi extends BaseAPI {
    /**
     * 
     * @param {string} [hmac] 
     * @param {string} [shop] 
     * @param {string} [timestamp] 
     * @param {string} [host] 
     * @param {string} [session] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopifyAppApi
     */
    public shopifyV1BaseGet(hmac?: string, shop?: string, timestamp?: string, host?: string, session?: string, options?: AxiosRequestConfig) {
        return ShopifyAppApiFp(this.configuration).shopifyV1BaseGet(hmac, shop, timestamp, host, session, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [code] 
     * @param {string} [hmac] 
     * @param {string} [host] 
     * @param {string} [shop] 
     * @param {string} [timestamp] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopifyAppApi
     */
    public shopifyV1OauthCodeGet(code?: string, hmac?: string, host?: string, shop?: string, timestamp?: string, options?: AxiosRequestConfig) {
        return ShopifyAppApiFp(this.configuration).shopifyV1OauthCodeGet(code, hmac, host, shop, timestamp, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShopifyDeliveryInformationApi - axios parameter creator
 * @export
 */
export const ShopifyDeliveryInformationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiDeliveryInformationConfigGet: async (manufacturerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopify/v1/api/deliveryInformation/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertShopifyOrderDeliveryInformationNotificationConfigRequest} [upsertShopifyOrderDeliveryInformationNotificationConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiDeliveryInformationConfigPost: async (upsertShopifyOrderDeliveryInformationNotificationConfigRequest?: UpsertShopifyOrderDeliveryInformationNotificationConfigRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopify/v1/api/deliveryInformation/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertShopifyOrderDeliveryInformationNotificationConfigRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShopifyDeliveryInformationApi - functional programming interface
 * @export
 */
export const ShopifyDeliveryInformationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShopifyDeliveryInformationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopifyV1ApiDeliveryInformationConfigGet(manufacturerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetShopifyOrderDeliveryInformationNotificationConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopifyV1ApiDeliveryInformationConfigGet(manufacturerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertShopifyOrderDeliveryInformationNotificationConfigRequest} [upsertShopifyOrderDeliveryInformationNotificationConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopifyV1ApiDeliveryInformationConfigPost(upsertShopifyOrderDeliveryInformationNotificationConfigRequest?: UpsertShopifyOrderDeliveryInformationNotificationConfigRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopifyV1ApiDeliveryInformationConfigPost(upsertShopifyOrderDeliveryInformationNotificationConfigRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShopifyDeliveryInformationApi - factory interface
 * @export
 */
export const ShopifyDeliveryInformationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShopifyDeliveryInformationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiDeliveryInformationConfigGet(manufacturerId?: string, options?: any): AxiosPromise<GetShopifyOrderDeliveryInformationNotificationConfigResponse> {
            return localVarFp.shopifyV1ApiDeliveryInformationConfigGet(manufacturerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertShopifyOrderDeliveryInformationNotificationConfigRequest} [upsertShopifyOrderDeliveryInformationNotificationConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiDeliveryInformationConfigPost(upsertShopifyOrderDeliveryInformationNotificationConfigRequest?: UpsertShopifyOrderDeliveryInformationNotificationConfigRequest, options?: any): AxiosPromise<void> {
            return localVarFp.shopifyV1ApiDeliveryInformationConfigPost(upsertShopifyOrderDeliveryInformationNotificationConfigRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShopifyDeliveryInformationApi - object-oriented interface
 * @export
 * @class ShopifyDeliveryInformationApi
 * @extends {BaseAPI}
 */
export class ShopifyDeliveryInformationApi extends BaseAPI {
    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopifyDeliveryInformationApi
     */
    public shopifyV1ApiDeliveryInformationConfigGet(manufacturerId?: string, options?: AxiosRequestConfig) {
        return ShopifyDeliveryInformationApiFp(this.configuration).shopifyV1ApiDeliveryInformationConfigGet(manufacturerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertShopifyOrderDeliveryInformationNotificationConfigRequest} [upsertShopifyOrderDeliveryInformationNotificationConfigRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopifyDeliveryInformationApi
     */
    public shopifyV1ApiDeliveryInformationConfigPost(upsertShopifyOrderDeliveryInformationNotificationConfigRequest?: UpsertShopifyOrderDeliveryInformationNotificationConfigRequest, options?: AxiosRequestConfig) {
        return ShopifyDeliveryInformationApiFp(this.configuration).shopifyV1ApiDeliveryInformationConfigPost(upsertShopifyOrderDeliveryInformationNotificationConfigRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShopifyIntegrationApi - axios parameter creator
 * @export
 */
export const ShopifyIntegrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {InvokeShopifyAppDeploymentRequest} [invokeShopifyAppDeploymentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiIntegrationActionInvokeAppCreationPost: async (invokeShopifyAppDeploymentRequest?: InvokeShopifyAppDeploymentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopify/v1/api/integration/action/invoke-app-creation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invokeShopifyAppDeploymentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiIntegrationConfigDelete: async (manufacturerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopify/v1/api/integration/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['manufacturerId'] = manufacturerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiIntegrationConfigGet: async (manufacturerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopify/v1/api/integration/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertShopifyIntegrationConfigRequest} [upsertShopifyIntegrationConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiIntegrationConfigPost: async (upsertShopifyIntegrationConfigRequest?: UpsertShopifyIntegrationConfigRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopify/v1/api/integration/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertShopifyIntegrationConfigRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShopifyIntegrationApi - functional programming interface
 * @export
 */
export const ShopifyIntegrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShopifyIntegrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {InvokeShopifyAppDeploymentRequest} [invokeShopifyAppDeploymentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopifyV1ApiIntegrationActionInvokeAppCreationPost(invokeShopifyAppDeploymentRequest?: InvokeShopifyAppDeploymentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShopifyIntegrationConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopifyV1ApiIntegrationActionInvokeAppCreationPost(invokeShopifyAppDeploymentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopifyV1ApiIntegrationConfigDelete(manufacturerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopifyV1ApiIntegrationConfigDelete(manufacturerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopifyV1ApiIntegrationConfigGet(manufacturerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShopifyIntegrationConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopifyV1ApiIntegrationConfigGet(manufacturerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertShopifyIntegrationConfigRequest} [upsertShopifyIntegrationConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopifyV1ApiIntegrationConfigPost(upsertShopifyIntegrationConfigRequest?: UpsertShopifyIntegrationConfigRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopifyV1ApiIntegrationConfigPost(upsertShopifyIntegrationConfigRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShopifyIntegrationApi - factory interface
 * @export
 */
export const ShopifyIntegrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShopifyIntegrationApiFp(configuration)
    return {
        /**
         * 
         * @param {InvokeShopifyAppDeploymentRequest} [invokeShopifyAppDeploymentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiIntegrationActionInvokeAppCreationPost(invokeShopifyAppDeploymentRequest?: InvokeShopifyAppDeploymentRequest, options?: any): AxiosPromise<ShopifyIntegrationConfigResponse> {
            return localVarFp.shopifyV1ApiIntegrationActionInvokeAppCreationPost(invokeShopifyAppDeploymentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiIntegrationConfigDelete(manufacturerId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.shopifyV1ApiIntegrationConfigDelete(manufacturerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiIntegrationConfigGet(manufacturerId?: string, options?: any): AxiosPromise<ShopifyIntegrationConfigResponse> {
            return localVarFp.shopifyV1ApiIntegrationConfigGet(manufacturerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertShopifyIntegrationConfigRequest} [upsertShopifyIntegrationConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiIntegrationConfigPost(upsertShopifyIntegrationConfigRequest?: UpsertShopifyIntegrationConfigRequest, options?: any): AxiosPromise<void> {
            return localVarFp.shopifyV1ApiIntegrationConfigPost(upsertShopifyIntegrationConfigRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShopifyIntegrationApi - object-oriented interface
 * @export
 * @class ShopifyIntegrationApi
 * @extends {BaseAPI}
 */
export class ShopifyIntegrationApi extends BaseAPI {
    /**
     * 
     * @param {InvokeShopifyAppDeploymentRequest} [invokeShopifyAppDeploymentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopifyIntegrationApi
     */
    public shopifyV1ApiIntegrationActionInvokeAppCreationPost(invokeShopifyAppDeploymentRequest?: InvokeShopifyAppDeploymentRequest, options?: AxiosRequestConfig) {
        return ShopifyIntegrationApiFp(this.configuration).shopifyV1ApiIntegrationActionInvokeAppCreationPost(invokeShopifyAppDeploymentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopifyIntegrationApi
     */
    public shopifyV1ApiIntegrationConfigDelete(manufacturerId?: string, options?: AxiosRequestConfig) {
        return ShopifyIntegrationApiFp(this.configuration).shopifyV1ApiIntegrationConfigDelete(manufacturerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopifyIntegrationApi
     */
    public shopifyV1ApiIntegrationConfigGet(manufacturerId?: string, options?: AxiosRequestConfig) {
        return ShopifyIntegrationApiFp(this.configuration).shopifyV1ApiIntegrationConfigGet(manufacturerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertShopifyIntegrationConfigRequest} [upsertShopifyIntegrationConfigRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopifyIntegrationApi
     */
    public shopifyV1ApiIntegrationConfigPost(upsertShopifyIntegrationConfigRequest?: UpsertShopifyIntegrationConfigRequest, options?: AxiosRequestConfig) {
        return ShopifyIntegrationApiFp(this.configuration).shopifyV1ApiIntegrationConfigPost(upsertShopifyIntegrationConfigRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShopifyOrderExportApi - axios parameter creator
 * @export
 */
export const ShopifyOrderExportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiOrdersConfigGet: async (manufacturerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopify/v1/api/orders/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertShopifyOrderExportConfigRequest} [upsertShopifyOrderExportConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiOrdersConfigPost: async (upsertShopifyOrderExportConfigRequest?: UpsertShopifyOrderExportConfigRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopify/v1/api/orders/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertShopifyOrderExportConfigRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShopifyOrderExportApi - functional programming interface
 * @export
 */
export const ShopifyOrderExportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShopifyOrderExportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopifyV1ApiOrdersConfigGet(manufacturerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShopifyOrderExportConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopifyV1ApiOrdersConfigGet(manufacturerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertShopifyOrderExportConfigRequest} [upsertShopifyOrderExportConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopifyV1ApiOrdersConfigPost(upsertShopifyOrderExportConfigRequest?: UpsertShopifyOrderExportConfigRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopifyV1ApiOrdersConfigPost(upsertShopifyOrderExportConfigRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShopifyOrderExportApi - factory interface
 * @export
 */
export const ShopifyOrderExportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShopifyOrderExportApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiOrdersConfigGet(manufacturerId?: string, options?: any): AxiosPromise<ShopifyOrderExportConfigResponse> {
            return localVarFp.shopifyV1ApiOrdersConfigGet(manufacturerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertShopifyOrderExportConfigRequest} [upsertShopifyOrderExportConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiOrdersConfigPost(upsertShopifyOrderExportConfigRequest?: UpsertShopifyOrderExportConfigRequest, options?: any): AxiosPromise<void> {
            return localVarFp.shopifyV1ApiOrdersConfigPost(upsertShopifyOrderExportConfigRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShopifyOrderExportApi - object-oriented interface
 * @export
 * @class ShopifyOrderExportApi
 * @extends {BaseAPI}
 */
export class ShopifyOrderExportApi extends BaseAPI {
    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopifyOrderExportApi
     */
    public shopifyV1ApiOrdersConfigGet(manufacturerId?: string, options?: AxiosRequestConfig) {
        return ShopifyOrderExportApiFp(this.configuration).shopifyV1ApiOrdersConfigGet(manufacturerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertShopifyOrderExportConfigRequest} [upsertShopifyOrderExportConfigRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopifyOrderExportApi
     */
    public shopifyV1ApiOrdersConfigPost(upsertShopifyOrderExportConfigRequest?: UpsertShopifyOrderExportConfigRequest, options?: AxiosRequestConfig) {
        return ShopifyOrderExportApiFp(this.configuration).shopifyV1ApiOrdersConfigPost(upsertShopifyOrderExportConfigRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShopifyProductImportApi - axios parameter creator
 * @export
 */
export const ShopifyProductImportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ShopifyProductSyncRequest} [shopifyProductSyncRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiProductsActionSyncPost: async (shopifyProductSyncRequest?: ShopifyProductSyncRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopify/v1/api/products/action/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shopifyProductSyncRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiProductsConfigGet: async (manufacturerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopify/v1/api/products/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertShopifyProductImportConfigRequest} [upsertShopifyProductImportConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiProductsConfigPost: async (upsertShopifyProductImportConfigRequest?: UpsertShopifyProductImportConfigRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopify/v1/api/products/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertShopifyProductImportConfigRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiProductsMappingsGet: async (manufacturerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopify/v1/api/products/mappings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertShopifyProductMappingsRequest} [upsertShopifyProductMappingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiProductsMappingsPost: async (upsertShopifyProductMappingsRequest?: UpsertShopifyProductMappingsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopify/v1/api/products/mappings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertShopifyProductMappingsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetShopifyProductsRequest} [getShopifyProductsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiProductsPost: async (getShopifyProductsRequest?: GetShopifyProductsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopify/v1/api/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getShopifyProductsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiProductsRefreshPost: async (manufacturerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopify/v1/api/products/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['manufacturerId'] = manufacturerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiProductsStatusGet: async (manufacturerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopify/v1/api/products/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['manufacturerId'] = manufacturerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShopifyProductImportApi - functional programming interface
 * @export
 */
export const ShopifyProductImportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShopifyProductImportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ShopifyProductSyncRequest} [shopifyProductSyncRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopifyV1ApiProductsActionSyncPost(shopifyProductSyncRequest?: ShopifyProductSyncRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShopifyProductsStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopifyV1ApiProductsActionSyncPost(shopifyProductSyncRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopifyV1ApiProductsConfigGet(manufacturerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShopifyProductImportConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopifyV1ApiProductsConfigGet(manufacturerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertShopifyProductImportConfigRequest} [upsertShopifyProductImportConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopifyV1ApiProductsConfigPost(upsertShopifyProductImportConfigRequest?: UpsertShopifyProductImportConfigRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopifyV1ApiProductsConfigPost(upsertShopifyProductImportConfigRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopifyV1ApiProductsMappingsGet(manufacturerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductMapping>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopifyV1ApiProductsMappingsGet(manufacturerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertShopifyProductMappingsRequest} [upsertShopifyProductMappingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopifyV1ApiProductsMappingsPost(upsertShopifyProductMappingsRequest?: UpsertShopifyProductMappingsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopifyV1ApiProductsMappingsPost(upsertShopifyProductMappingsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetShopifyProductsRequest} [getShopifyProductsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopifyV1ApiProductsPost(getShopifyProductsRequest?: GetShopifyProductsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShopifyProduct202501>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopifyV1ApiProductsPost(getShopifyProductsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopifyV1ApiProductsRefreshPost(manufacturerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShopifyProductsStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopifyV1ApiProductsRefreshPost(manufacturerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopifyV1ApiProductsStatusGet(manufacturerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShopifyProductsStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopifyV1ApiProductsStatusGet(manufacturerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShopifyProductImportApi - factory interface
 * @export
 */
export const ShopifyProductImportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShopifyProductImportApiFp(configuration)
    return {
        /**
         * 
         * @param {ShopifyProductSyncRequest} [shopifyProductSyncRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiProductsActionSyncPost(shopifyProductSyncRequest?: ShopifyProductSyncRequest, options?: any): AxiosPromise<ShopifyProductsStatusResponse> {
            return localVarFp.shopifyV1ApiProductsActionSyncPost(shopifyProductSyncRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiProductsConfigGet(manufacturerId?: string, options?: any): AxiosPromise<ShopifyProductImportConfigResponse> {
            return localVarFp.shopifyV1ApiProductsConfigGet(manufacturerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertShopifyProductImportConfigRequest} [upsertShopifyProductImportConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiProductsConfigPost(upsertShopifyProductImportConfigRequest?: UpsertShopifyProductImportConfigRequest, options?: any): AxiosPromise<void> {
            return localVarFp.shopifyV1ApiProductsConfigPost(upsertShopifyProductImportConfigRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiProductsMappingsGet(manufacturerId?: string, options?: any): AxiosPromise<Array<ProductMapping>> {
            return localVarFp.shopifyV1ApiProductsMappingsGet(manufacturerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertShopifyProductMappingsRequest} [upsertShopifyProductMappingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiProductsMappingsPost(upsertShopifyProductMappingsRequest?: UpsertShopifyProductMappingsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.shopifyV1ApiProductsMappingsPost(upsertShopifyProductMappingsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetShopifyProductsRequest} [getShopifyProductsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiProductsPost(getShopifyProductsRequest?: GetShopifyProductsRequest, options?: any): AxiosPromise<Array<ShopifyProduct202501>> {
            return localVarFp.shopifyV1ApiProductsPost(getShopifyProductsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiProductsRefreshPost(manufacturerId?: string, options?: any): AxiosPromise<ShopifyProductsStatusResponse> {
            return localVarFp.shopifyV1ApiProductsRefreshPost(manufacturerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiProductsStatusGet(manufacturerId?: string, options?: any): AxiosPromise<ShopifyProductsStatusResponse> {
            return localVarFp.shopifyV1ApiProductsStatusGet(manufacturerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShopifyProductImportApi - object-oriented interface
 * @export
 * @class ShopifyProductImportApi
 * @extends {BaseAPI}
 */
export class ShopifyProductImportApi extends BaseAPI {
    /**
     * 
     * @param {ShopifyProductSyncRequest} [shopifyProductSyncRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopifyProductImportApi
     */
    public shopifyV1ApiProductsActionSyncPost(shopifyProductSyncRequest?: ShopifyProductSyncRequest, options?: AxiosRequestConfig) {
        return ShopifyProductImportApiFp(this.configuration).shopifyV1ApiProductsActionSyncPost(shopifyProductSyncRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopifyProductImportApi
     */
    public shopifyV1ApiProductsConfigGet(manufacturerId?: string, options?: AxiosRequestConfig) {
        return ShopifyProductImportApiFp(this.configuration).shopifyV1ApiProductsConfigGet(manufacturerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertShopifyProductImportConfigRequest} [upsertShopifyProductImportConfigRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopifyProductImportApi
     */
    public shopifyV1ApiProductsConfigPost(upsertShopifyProductImportConfigRequest?: UpsertShopifyProductImportConfigRequest, options?: AxiosRequestConfig) {
        return ShopifyProductImportApiFp(this.configuration).shopifyV1ApiProductsConfigPost(upsertShopifyProductImportConfigRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopifyProductImportApi
     */
    public shopifyV1ApiProductsMappingsGet(manufacturerId?: string, options?: AxiosRequestConfig) {
        return ShopifyProductImportApiFp(this.configuration).shopifyV1ApiProductsMappingsGet(manufacturerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertShopifyProductMappingsRequest} [upsertShopifyProductMappingsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopifyProductImportApi
     */
    public shopifyV1ApiProductsMappingsPost(upsertShopifyProductMappingsRequest?: UpsertShopifyProductMappingsRequest, options?: AxiosRequestConfig) {
        return ShopifyProductImportApiFp(this.configuration).shopifyV1ApiProductsMappingsPost(upsertShopifyProductMappingsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetShopifyProductsRequest} [getShopifyProductsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopifyProductImportApi
     */
    public shopifyV1ApiProductsPost(getShopifyProductsRequest?: GetShopifyProductsRequest, options?: AxiosRequestConfig) {
        return ShopifyProductImportApiFp(this.configuration).shopifyV1ApiProductsPost(getShopifyProductsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopifyProductImportApi
     */
    public shopifyV1ApiProductsRefreshPost(manufacturerId?: string, options?: AxiosRequestConfig) {
        return ShopifyProductImportApiFp(this.configuration).shopifyV1ApiProductsRefreshPost(manufacturerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopifyProductImportApi
     */
    public shopifyV1ApiProductsStatusGet(manufacturerId?: string, options?: AxiosRequestConfig) {
        return ShopifyProductImportApiFp(this.configuration).shopifyV1ApiProductsStatusGet(manufacturerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShopifyProductStocksApi - axios parameter creator
 * @export
 */
export const ShopifyProductStocksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} integrationSubModuleId 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiProductStocksConfigGet: async (integrationSubModuleId: string, manufacturerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationSubModuleId' is not null or undefined
            assertParamExists('shopifyV1ApiProductStocksConfigGet', 'integrationSubModuleId', integrationSubModuleId)
            const localVarPath = `/shopify/v1/api/productStocks/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }

            if (integrationSubModuleId !== undefined) {
                localVarQueryParameter['IntegrationSubModuleId'] = integrationSubModuleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertShopifyProductStockNotificationConfigRequest} [upsertShopifyProductStockNotificationConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiProductStocksConfigPost: async (upsertShopifyProductStockNotificationConfigRequest?: UpsertShopifyProductStockNotificationConfigRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopify/v1/api/productStocks/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertShopifyProductStockNotificationConfigRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShopifyProductStocksApi - functional programming interface
 * @export
 */
export const ShopifyProductStocksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShopifyProductStocksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} integrationSubModuleId 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopifyV1ApiProductStocksConfigGet(integrationSubModuleId: string, manufacturerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetShopifyProductStockNotificationConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopifyV1ApiProductStocksConfigGet(integrationSubModuleId, manufacturerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertShopifyProductStockNotificationConfigRequest} [upsertShopifyProductStockNotificationConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopifyV1ApiProductStocksConfigPost(upsertShopifyProductStockNotificationConfigRequest?: UpsertShopifyProductStockNotificationConfigRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopifyV1ApiProductStocksConfigPost(upsertShopifyProductStockNotificationConfigRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShopifyProductStocksApi - factory interface
 * @export
 */
export const ShopifyProductStocksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShopifyProductStocksApiFp(configuration)
    return {
        /**
         * 
         * @param {string} integrationSubModuleId 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiProductStocksConfigGet(integrationSubModuleId: string, manufacturerId?: string, options?: any): AxiosPromise<GetShopifyProductStockNotificationConfigResponse> {
            return localVarFp.shopifyV1ApiProductStocksConfigGet(integrationSubModuleId, manufacturerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertShopifyProductStockNotificationConfigRequest} [upsertShopifyProductStockNotificationConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1ApiProductStocksConfigPost(upsertShopifyProductStockNotificationConfigRequest?: UpsertShopifyProductStockNotificationConfigRequest, options?: any): AxiosPromise<void> {
            return localVarFp.shopifyV1ApiProductStocksConfigPost(upsertShopifyProductStockNotificationConfigRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShopifyProductStocksApi - object-oriented interface
 * @export
 * @class ShopifyProductStocksApi
 * @extends {BaseAPI}
 */
export class ShopifyProductStocksApi extends BaseAPI {
    /**
     * 
     * @param {string} integrationSubModuleId 
     * @param {string} [manufacturerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopifyProductStocksApi
     */
    public shopifyV1ApiProductStocksConfigGet(integrationSubModuleId: string, manufacturerId?: string, options?: AxiosRequestConfig) {
        return ShopifyProductStocksApiFp(this.configuration).shopifyV1ApiProductStocksConfigGet(integrationSubModuleId, manufacturerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertShopifyProductStockNotificationConfigRequest} [upsertShopifyProductStockNotificationConfigRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopifyProductStocksApi
     */
    public shopifyV1ApiProductStocksConfigPost(upsertShopifyProductStockNotificationConfigRequest?: UpsertShopifyProductStockNotificationConfigRequest, options?: AxiosRequestConfig) {
        return ShopifyProductStocksApiFp(this.configuration).shopifyV1ApiProductStocksConfigPost(upsertShopifyProductStockNotificationConfigRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShopifyWebhooksApi - axios parameter creator
 * @export
 */
export const ShopifyWebhooksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [integrationSubModuleId] 
         * @param {ShopifyBulkOperationFinishWebhookRequest} [shopifyBulkOperationFinishWebhookRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1WebhooksBulkOperationFinishPost: async (manufacturerId?: string, integrationSubModuleId?: string, shopifyBulkOperationFinishWebhookRequest?: ShopifyBulkOperationFinishWebhookRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopify/v1/webhooks/bulkOperationFinish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }

            if (integrationSubModuleId !== undefined) {
                localVarQueryParameter['IntegrationSubModuleId'] = integrationSubModuleId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shopifyBulkOperationFinishWebhookRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [integrationSubModuleId] 
         * @param {Operation} [operation] 
         * @param {FulfillmentsRequest} [fulfillmentsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1WebhooksOrderShippingDetailsPost: async (manufacturerId?: string, integrationSubModuleId?: string, operation?: Operation, fulfillmentsRequest?: FulfillmentsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopify/v1/webhooks/orderShippingDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }

            if (integrationSubModuleId !== undefined) {
                localVarQueryParameter['IntegrationSubModuleId'] = integrationSubModuleId;
            }

            if (operation !== undefined) {
                localVarQueryParameter['Operation'] = operation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fulfillmentsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1WebhooksOrdersPost: async (body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopify/v1/webhooks/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [integrationSubModuleId] 
         * @param {InventoryItemUpdateRequest} [inventoryItemUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1WebhooksProductStocksPost: async (manufacturerId?: string, integrationSubModuleId?: string, inventoryItemUpdateRequest?: InventoryItemUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopify/v1/webhooks/productStocks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }

            if (integrationSubModuleId !== undefined) {
                localVarQueryParameter['IntegrationSubModuleId'] = integrationSubModuleId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inventoryItemUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShopifyWebhooksApi - functional programming interface
 * @export
 */
export const ShopifyWebhooksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShopifyWebhooksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [integrationSubModuleId] 
         * @param {ShopifyBulkOperationFinishWebhookRequest} [shopifyBulkOperationFinishWebhookRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopifyV1WebhooksBulkOperationFinishPost(manufacturerId?: string, integrationSubModuleId?: string, shopifyBulkOperationFinishWebhookRequest?: ShopifyBulkOperationFinishWebhookRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopifyV1WebhooksBulkOperationFinishPost(manufacturerId, integrationSubModuleId, shopifyBulkOperationFinishWebhookRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [integrationSubModuleId] 
         * @param {Operation} [operation] 
         * @param {FulfillmentsRequest} [fulfillmentsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopifyV1WebhooksOrderShippingDetailsPost(manufacturerId?: string, integrationSubModuleId?: string, operation?: Operation, fulfillmentsRequest?: FulfillmentsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopifyV1WebhooksOrderShippingDetailsPost(manufacturerId, integrationSubModuleId, operation, fulfillmentsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopifyV1WebhooksOrdersPost(body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopifyV1WebhooksOrdersPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [integrationSubModuleId] 
         * @param {InventoryItemUpdateRequest} [inventoryItemUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopifyV1WebhooksProductStocksPost(manufacturerId?: string, integrationSubModuleId?: string, inventoryItemUpdateRequest?: InventoryItemUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopifyV1WebhooksProductStocksPost(manufacturerId, integrationSubModuleId, inventoryItemUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShopifyWebhooksApi - factory interface
 * @export
 */
export const ShopifyWebhooksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShopifyWebhooksApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [integrationSubModuleId] 
         * @param {ShopifyBulkOperationFinishWebhookRequest} [shopifyBulkOperationFinishWebhookRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1WebhooksBulkOperationFinishPost(manufacturerId?: string, integrationSubModuleId?: string, shopifyBulkOperationFinishWebhookRequest?: ShopifyBulkOperationFinishWebhookRequest, options?: any): AxiosPromise<void> {
            return localVarFp.shopifyV1WebhooksBulkOperationFinishPost(manufacturerId, integrationSubModuleId, shopifyBulkOperationFinishWebhookRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [integrationSubModuleId] 
         * @param {Operation} [operation] 
         * @param {FulfillmentsRequest} [fulfillmentsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1WebhooksOrderShippingDetailsPost(manufacturerId?: string, integrationSubModuleId?: string, operation?: Operation, fulfillmentsRequest?: FulfillmentsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.shopifyV1WebhooksOrderShippingDetailsPost(manufacturerId, integrationSubModuleId, operation, fulfillmentsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1WebhooksOrdersPost(body?: string, options?: any): AxiosPromise<void> {
            return localVarFp.shopifyV1WebhooksOrdersPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [integrationSubModuleId] 
         * @param {InventoryItemUpdateRequest} [inventoryItemUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopifyV1WebhooksProductStocksPost(manufacturerId?: string, integrationSubModuleId?: string, inventoryItemUpdateRequest?: InventoryItemUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.shopifyV1WebhooksProductStocksPost(manufacturerId, integrationSubModuleId, inventoryItemUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShopifyWebhooksApi - object-oriented interface
 * @export
 * @class ShopifyWebhooksApi
 * @extends {BaseAPI}
 */
export class ShopifyWebhooksApi extends BaseAPI {
    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {string} [integrationSubModuleId] 
     * @param {ShopifyBulkOperationFinishWebhookRequest} [shopifyBulkOperationFinishWebhookRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopifyWebhooksApi
     */
    public shopifyV1WebhooksBulkOperationFinishPost(manufacturerId?: string, integrationSubModuleId?: string, shopifyBulkOperationFinishWebhookRequest?: ShopifyBulkOperationFinishWebhookRequest, options?: AxiosRequestConfig) {
        return ShopifyWebhooksApiFp(this.configuration).shopifyV1WebhooksBulkOperationFinishPost(manufacturerId, integrationSubModuleId, shopifyBulkOperationFinishWebhookRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {string} [integrationSubModuleId] 
     * @param {Operation} [operation] 
     * @param {FulfillmentsRequest} [fulfillmentsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopifyWebhooksApi
     */
    public shopifyV1WebhooksOrderShippingDetailsPost(manufacturerId?: string, integrationSubModuleId?: string, operation?: Operation, fulfillmentsRequest?: FulfillmentsRequest, options?: AxiosRequestConfig) {
        return ShopifyWebhooksApiFp(this.configuration).shopifyV1WebhooksOrderShippingDetailsPost(manufacturerId, integrationSubModuleId, operation, fulfillmentsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopifyWebhooksApi
     */
    public shopifyV1WebhooksOrdersPost(body?: string, options?: AxiosRequestConfig) {
        return ShopifyWebhooksApiFp(this.configuration).shopifyV1WebhooksOrdersPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {string} [integrationSubModuleId] 
     * @param {InventoryItemUpdateRequest} [inventoryItemUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopifyWebhooksApi
     */
    public shopifyV1WebhooksProductStocksPost(manufacturerId?: string, integrationSubModuleId?: string, inventoryItemUpdateRequest?: InventoryItemUpdateRequest, options?: AxiosRequestConfig) {
        return ShopifyWebhooksApiFp(this.configuration).shopifyV1WebhooksProductStocksPost(manufacturerId, integrationSubModuleId, inventoryItemUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


