import { Stage } from '../../layout/stage/Stage';
import { UnderConstruction } from '../../elements/emptystate/EmptyState';

const Invoices = () => {
  return (
    <Stage>
      <UnderConstruction />
    </Stage>
  );
};

export default Invoices;

// {user?.isProductOwner ? (
//   <>
//     <Navigator
//       title={t('view.documents.monthlyManufacturerInvoices.title')}
//     ></Navigator>{' '}
//     <Card bigScreenWidth={'100%'}>
//       <CardSection>
//         <MonthlyManufacturerInvoices />
//       </CardSection>
//     </Card>
//   </>
// ) : (
//   <UnderConstruction />
// )}
