import './shopifyproductimport.css';
import Card, { CardSection } from '../../../../../elements/card/Card';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../../../api/PetCloudApi';
import { useEffect, useState } from 'react';
import {
  ShopifyProductImportConfigResponse,
  ShopifyProductsRefreshStatus,
} from '../../../../../api/shopifyapi/api';
import { useErrorHandler } from '../../../../../contexts/errorhandler/ErrorHandler';
import useNotifications from '../../../../../hooks/useNotifications';
import { LoadingContainer } from '../../../../../elements/loading/Loading';
import { IntegrationSubModuleConfigResponse } from '../../../../../api/petcloudapi/api';
import { BadgeProps } from '../../../../../elements/badge/Badge';
import Button from '../../../../../elements/button/Button';
import Popup from '../../../../../elements/popup/Popup';
import ProductMappings from '../../../common/productmappings/ProductMappings';
import { ReactComponent as IconProduct } from '../../../../../../assets/icon/sidebar/products.svg';
import { ReactComponent as IconSync } from '../../../../../../assets/icon/convert.svg';
import dataProcessing from '../../../../../../assets/img/data_processing.svg';
import InformationBox from '../../../../../elements/informationbox/InformationBox';
import useJsonParseCamelcase from '../../../../../hooks/useJsonParseCamelcase';

interface ShopifyProductImportProps {
  manufacturerId: string;
  integrationSubModuleId: string;
  fetchedConfig?: IntegrationSubModuleConfigResponse | null;
  locked: boolean;
}

const ShopifyProductImport: React.FC<ShopifyProductImportProps> = ({
  manufacturerId,
  integrationSubModuleId,
  fetchedConfig,
  locked,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.integration.common.productImport',
  });
  const api = usePetCloudApi();
  const shopifyProductImportApi = api.shopifyProductImportApi();
  const errorHandler = useErrorHandler();
  const { pushNotification } = useNotifications();
  const { toCamelCase } = useJsonParseCamelcase();

  const [config, setConfig] = useState<
    ShopifyProductImportConfigResponse | null | undefined
  >(null);
  const [mappingPopup, setMappingPopup] = useState(false);
  const [syncPopup, setSyncPopup] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [mappingStatus, setMappingStatus] = useState<
    ShopifyProductsRefreshStatus | undefined
  >(undefined);

  useEffect(() => {
    getConfig();
  }, []);

  const getConfig = () => {
    if (fetchedConfig && fetchedConfig.config) {
      const config = JSON.parse(
        fetchedConfig?.config,
        toCamelCase
      ) as ShopifyProductImportConfigResponse;
      console.log(config);
      setConfig(config);
    } else {
      setConfig(undefined);
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const poll = async () => {
      try {
        const response =
          await shopifyProductImportApi.shopifyV1ApiProductsStatusGet(
            manufacturerId
          );
        console.log(response);
        setMappingStatus(response.data.status);
        if (response.data.status === 'Completed') return;
        if (response.data.status === 'Invalidated') {
          try {
            await shopifyProductImportApi.shopifyV1ApiProductsRefreshPost(
              manufacturerId
            );
          } catch (error: any) {
            console.log(error);
            errorHandler.addError(error.response);
            return;
          }
        }
        // Continue polling if popup is open
        if (mappingPopup) {
          timer = setTimeout(poll, 1000);
        }
      } catch (error: any) {
        console.log(error);
        errorHandler.addError(error.response);
      }
    };

    if (mappingPopup) {
      poll();
    }

    return () => clearTimeout(timer);
  }, [mappingPopup, manufacturerId]);

  const syncProducts = () => {
    setIsSubmitting(true);
    shopifyProductImportApi
      .shopifyV1ApiProductsActionSyncPost({ manufacturerId: manufacturerId })
      .then((response) => {
        console.log(response);
        pushNotification(t('notifications.sync_successful'));
        setIsSubmitting(false);
        setIsSubmitted(true);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSubmitting(false);
      });
  };

  const getBadges = () => {
    const nodes: BadgeProps[] = [];
    if (config === undefined) {
      nodes.push({
        title: t('badge.notConfigured'),
        color: 'var(--color-text_secondary)',
      });
    } else {
      nodes.push({
        title: t('badge.configured'),
        color: 'var(--color-success)',
      });
    }
    return nodes;
  };

  return (
    <div className={'shopifyProductImport'}>
      <Card bigScreenWidth={'100%'}>
        <CardSection title={t('title')} badges={getBadges()} locked={locked}>
          {config !== null ? (
            config !== undefined ? (
              <div className={'shopifyProductImport-actions'}>
                <Button
                  type={'icon-text'}
                  cta={t('cta_configure')}
                  look={'secondary'}
                  width={'minimal'}
                  action={() => setMappingPopup(true)}
                >
                  <IconProduct
                    className={'button-icon button-icon-secondary'}
                  />
                </Button>
                <Button
                  type={'icon-text'}
                  cta={t('cta_sync')}
                  look={'secondary'}
                  width={'minimal'}
                  action={() => setSyncPopup(true)}
                  isLoading={isSubmitting}
                  active={
                    !!(
                      (config.productIds && config.productIds.length > 0) ||
                      (config.productVariantIds &&
                        config.productVariantIds.length > 0)
                    )
                  }
                >
                  <IconSync className={'button-icon button-icon-secondary'} />
                </Button>
              </div>
            ) : (
              <Button
                cta={t('cta_configure')}
                look={'secondary'}
                width={'minimal'}
                action={() => setMappingPopup(true)}
                active={!locked}
              />
            )
          ) : (
            <LoadingContainer />
          )}
        </CardSection>
      </Card>
      <Popup
        toggled={mappingPopup}
        width={'80%'}
        close={() => setMappingPopup(false)}
      >
        {mappingStatus === 'Completed' ? (
          <ProductMappings
            manufacturerId={manufacturerId}
            integrationSubModuleId={integrationSubModuleId}
            integrationIdentifier={'Shopify'}
          />
        ) : (
          <LoadingContainer message={t('mappingPopup.polling')} />
        )}
      </Popup>
      <Popup
        toggled={syncPopup}
        width={'30%'}
        close={() => {
          setSyncPopup(false);
          setIsSubmitted(false);
        }}
      >
        <div className={'popup-title'}>{t('syncPopup.title')}</div>
        {isSubmitted ? (
          <>
            <img
              src={dataProcessing}
              alt={'in progress'}
              className={'shopifyProductImport-dataProcessingImg'}
            />
            <div className={'global-textElement'}>{t('syncPopup.success')}</div>
            <Button
              cta={t('cta_close')}
              look={'secondary'}
              width={'full'}
              action={() => {
                setSyncPopup(false);
                setIsSubmitted(false);
              }}
            />
          </>
        ) : (
          <>
            <InformationBox
              type={'warning'}
              title={t('syncPopup.info.title')}
              content={t('syncPopup.info.text')}
              maxWidth={500}
            />
            <Button
              type={'icon-text'}
              cta={t('cta_sync')}
              look={'secondary'}
              width={'full'}
              action={syncProducts}
              isLoading={isSubmitting}
            >
              <IconSync className={'button-icon button-icon-secondary'} />
            </Button>
          </>
        )}
      </Popup>
    </div>
  );
};

export default ShopifyProductImport;
