import './meta.css';
import { useTranslation } from 'react-i18next';
import Input from '../../../elements/input/Input';
import {
  CreateProductRequest,
  ProductResponse,
} from '../../../api/petcloudapi/api';
import { Error, hasError } from '../../../contexts/errorhandler/ErrorHandler';

interface MetaProps {
  product: ProductResponse | CreateProductRequest;
  updateProduct?: (product: ProductResponse) => void;
  updateNewProduct?: (product: CreateProductRequest) => void;
  errors?: Error[];
  errorCallback?: () => void;
}

const Meta: React.FC<MetaProps> = ({
  product,
  updateProduct,
  updateNewProduct,
  errors,
  errorCallback,
}) => {
  const { t } = useTranslation();

  const isEanRequired = () => {
    const children = (product as ProductResponse).children;
    if (children && children.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="meta">
      <div className="global-inputGroup">
        {(product as ProductResponse).productNumber ? (
          <div className="global-inputGroup-input">
            <Input
              title={t('view.product.meta.productNumber')}
              content={(product as ProductResponse).productNumber}
            />
          </div>
        ) : null}
        <div className="global-inputGroup-input">
          <Input
            title={t('view.product.meta.manufacturerProductNumber')}
            content={product.manufacturerProductNumber}
            update={(e) => {
              if (updateProduct) {
                updateProduct({
                  ...(product as ProductResponse),
                  manufacturerProductNumber: e,
                });
              } else if (updateNewProduct) {
                updateNewProduct({
                  ...(product as CreateProductRequest),
                  manufacturerProductNumber: e,
                });
              }
            }}
            inherited={
              updateProduct
                ? (
                    product as ProductResponse
                  ).parent?.manufacturerProductNumber?.toString()
                : undefined
            }
            hint={t('view.product.meta.numberHint')}
            errors={hasError(errors, ['Number'], errorCallback)}
          />
        </div>
        <div className="global-inputGroup-input">
          <Input
            title={t('view.product.meta.ean')}
            content={product.ean}
            update={(e) => {
              if (updateProduct) {
                updateProduct({
                  ...(product as ProductResponse),
                  ean: e,
                });
              } else if (updateNewProduct) {
                updateNewProduct({
                  ...(product as CreateProductRequest),
                  ean: e,
                });
              }
            }}
            required={isEanRequired()}
            hint={t('view.product.meta.ean_hint')}
            errors={hasError(errors, ['Ean'], errorCallback)}
          />
        </div>
      </div>
    </div>
  );
};

export default Meta;
