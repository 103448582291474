import './pricing.css';
import {
  CreateProductPriceRequest,
  ProductPriceResponse,
  TaxTypeIdentifier,
} from '../../../api/petcloudapi/api';
import {
  Error,
  useErrorHandler,
} from '../../../contexts/errorhandler/ErrorHandler';
import { CardTitle, TabConfig } from '../../../elements/card/Card';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { Has } from '../../../contexts/auth/Authorization';
import { useEffect, useState } from 'react';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import {
  Dropdown,
  DropdownOption,
} from '../../../elements/selectors/Selectors';
import ErrorCheck from '../../../elements/errorcheck/ErrorCheck';
import Button from '../../../elements/button/Button';
import { LoadingContainer } from '../../../elements/loading/Loading';
import Popup from '../../../elements/popup/Popup';
import Price from './Price';
import NewPriceWizard from './NewPriceWizard';
import UniversalProductPrice from '../../../types/UniversalProductPrice';
import { Store } from 'react-notifications-component';
import usePersistInCookies from '../../../hooks/useStorageData';

export type MODE = 'READ_ONLY' | 'NEW' | 'INHERITED' | 'NORMAL';

interface NewPricingProps {
  productId?: string;
  productVersionId?: string;
  taxTypeIdentifier?: TaxTypeIdentifier;
  inheritedProductPrices?: ProductPriceResponse[] | null;
  updateTaxTypeIdentifier: (identifier: TaxTypeIdentifier) => void;
  inlineCreationCallback?: (prices: UniversalProductPrice[]) => void;
  readonly?: boolean;
  errors?: Error[];
  hint?: string;
  updateProductViewTabs?: (
    array: { tabKey: string; safe: boolean; error?: boolean }[],
    tabArray?: TabConfig[],
    errorOverrides?: Error[]
  ) => void;
  removeErrors: (keys: string[]) => Error[];
  toggleProductSavingHelpOverlay?: () => void;
}

const Pricing: React.FC<NewPricingProps> = ({
  productId,
  productVersionId,
  taxTypeIdentifier,
  updateTaxTypeIdentifier,
  inheritedProductPrices,
  inlineCreationCallback,
  readonly,
  errors,
  hint,
  updateProductViewTabs,
  removeErrors,
  toggleProductSavingHelpOverlay,
}) => {
  const { t, i18n } = useTranslation();
  const api = usePetCloudApi();
  const countriesApi = api.countriesApi();
  const currenciesApi = api.currenciesApi();
  const productPricesApi = api.productPricesApi();
  const errorHandler = useErrorHandler();

  const [productPrices, setProductPrices] = useState<
    ProductPriceResponse[] | null
  >(null);
  const [originalProductPrices, setOriginalProductPrices] = useState<
    ProductPriceResponse[] | null
  >(null);
  const [newProductPrices, setNewProductPrices] = useState<
    UniversalProductPrice[]
  >([]);
  const [currencyOptions, setCurrencyOptions] = useState<
    DropdownOption[] | null
  >(null);
  const [countryOptions, setCountryOptions] = useState<DropdownOption[] | null>(
    null
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newPriceWizard, setNewPriceWizard] = useState(false);
  const { persistObject, getPersistedObject } = usePersistInCookies();

  const mode: MODE = readonly
    ? 'READ_ONLY'
    : !productId
    ? 'NEW'
    : inheritedProductPrices &&
      productPrices?.length === 0 &&
      newProductPrices.length === 0
    ? 'INHERITED'
    : 'NORMAL';

  // get all required data
  useEffect(() => {
    getProductPrices();
  }, [productVersionId]);

  useEffect(() => {
    getCurrencies();
    getCountries();
  }, []);

  useEffect(() => {
    if (inlineCreationCallback) {
      inlineCreationCallback(newProductPrices);
      persistObject(newProductPrices, 'new_product_prices');
    }
  }, [newProductPrices]);

  const getProductPrices = () => {
    if (productId) {
      setProductPrices(null);
      setOriginalProductPrices(null);
      productPricesApi
        .productPricesGetProductPrices(productId, productVersionId)
        .then((response) => {
          console.log(response);
          setProductPrices(response.data);
          setOriginalProductPrices(response.data);
          setNewProductPrices([]);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    } else {
      const persisted = getPersistedObject('new_product_prices');
      setNewProductPrices(persisted ?? []);
      setProductPrices([]);
      setOriginalProductPrices([]);
    }
  };

  const getCurrencies = () => {
    currenciesApi
      .currenciesGetCurrencies()
      .then((response) => {
        console.log(response);
        const currencyOptions = response.data.map((currency) => {
          return {
            id: currency.id,
            name: currency.symbol,
          };
        });
        setCurrencyOptions(currencyOptions);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCountries = () => {
    countriesApi
      .countriesGetCountries()
      .then((response) => {
        console.log(response);
        const countryOptions = response.data.map((country) => {
          const name = country.name[i18n.language as TranslatedStringIndex];
          return {
            id: country.id,
            name: name ? name : 'missing translation',
          };
        });
        setCountryOptions(countryOptions);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // set tab safety to false when changes to any price are made
  useEffect(() => {
    if (updateProductViewTabs && productPrices && originalProductPrices) {
      if (
        JSON.stringify(productPrices) !==
          JSON.stringify(originalProductPrices) ||
        newProductPrices.length > 0
      ) {
        updateProductViewTabs([{ tabKey: 'pricing', safe: false }]);
      }
    }
  }, [productPrices, newProductPrices]);

  const transferInheritedProductPrices = () => {
    if (inheritedProductPrices) {
      setNewProductPrices(
        inheritedProductPrices.map((price) => {
          return {
            id: price.id,
            net: price.net > 0.0 ? price.net : 0.1,
            gross: price.gross > 0.0 ? price.gross : 0.1,
            listPriceGross: price.listPriceGross,
            quantityStart: price.quantityStart,
            quantityEnd: price.quantityEnd,
            currencyId: price.currencyId,
            countryId: price.countryId,
            productId: productId,
          };
        })
      );
    }
  };

  const updatePrice = (
    index: number,
    price: UniversalProductPrice | ProductPriceResponse,
    updatesNew: boolean
  ) => {
    if (updatesNew) {
      const update = [...newProductPrices];
      update[index] = {
        ...price,
      };
      setNewProductPrices(update);
    } else {
      if (productPrices) {
        const update = [...productPrices];
        update[index] = {
          ...(price as ProductPriceResponse),
        };
        setProductPrices(update);
      }
    }
  };

  // removes price visually, actual deletion will happen on save
  const removeProductPrice = (priceId: string) => {
    if (productPrices) {
      const update = [...productPrices];
      const i = update.findIndex((p) => p.id === priceId);
      if (i !== -1) {
        update.splice(i, 1);
      }
      setProductPrices(update);
    }
  };

  const removeNewProductPrice = (priceId: string) => {
    const update = [...newProductPrices];
    const i = update.findIndex((p) => p.id === priceId);
    if (i !== -1) {
      update.splice(i, 1);
    }
    setNewProductPrices(update);
  };

  const handleSubmit = (prices: ProductPriceResponse[]) => {
    if (prices) {
      const promises: Promise<unknown>[] = [];
      setIsSubmitting(true);
      removeErrors(['ProductPrices']);
      originalProductPrices?.forEach((productPrice) => {
        const productPriceToBeUpdated = prices.find(
          (p) => p.id === productPrice.id
        );
        if (productPriceToBeUpdated) {
          if (productPriceToBeUpdated !== productPrice) {
            promises.push(submitProducePrice(productPriceToBeUpdated));
          }
        } else {
          promises.push(deleteProductPrice(productPrice.id));
        }
      });

      newProductPrices.forEach((newProductPrice) => {
        promises.push(submitNewProductPrice(newProductPrice));
      });

      Promise.all(promises)
        .then(() => {
          Store.addNotification({
            message: t('view.product.notifications.priceUpdate_successful'),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
          getProductPrices();
          setIsSubmitting(false);
          if (updateProductViewTabs) {
            updateProductViewTabs(
              [{ tabKey: 'pricing', safe: true, error: false }],
              undefined
            );
          }
          if (toggleProductSavingHelpOverlay) {
            toggleProductSavingHelpOverlay();
          }
        })
        .catch(() => {
          Store.addNotification({
            message: t('view.product.notifications.priceUpdate_failed'),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
          setIsSubmitting(false);
        });
    }
  };

  const submitProducePrice = (productPrice: ProductPriceResponse) => {
    return new Promise((resolve, reject) => {
      productPricesApi
        .productPricesUpdateProductPrice(productPrice.id, productPrice)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          reject(error);
        });
    });
  };

  const submitNewProductPrice = (newPrice: CreateProductPriceRequest) => {
    return new Promise((resolve, reject) => {
      productPricesApi
        .productPricesCreateProductPrice(newPrice)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          reject(error);
        });
    });
  };

  const deleteProductPrice = (priceId: string) => {
    return new Promise((resolve, reject) => {
      productPricesApi
        .productPricesDeleteProductPrice(priceId)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          reject();
        });
    });
  };

  /* this merges all current productPrices and newProductPrices as UniversalProductPrices
  to be able to scan those prices prior to creating new ones to avoid overlap (doubled prices for same country and currency) */
  const getCurrentPricesMerged = () => {
    const results: UniversalProductPrice[] = [];
    productPrices?.forEach((price) => {
      results.push({
        id: price.id,
        net: price.net,
        gross: price.gross,
        listPriceGross: price.listPriceGross,
        listPriceNet: price.listPriceNet,
        quantityStart: price.quantityStart,
        quantityEnd: price.quantityEnd,
        currencyId: price.currencyId,
        countryId: price.countryId,
        productId: price.productId,
      });
    });
    results.concat(newProductPrices);
    return results;
  };

  const renderNewPriceWizard = (
    currencyOptions: DropdownOption[],
    countryOptions: DropdownOption[]
  ) => {
    return (
      <Popup
        toggled={newPriceWizard}
        width={'50%'}
        close={() => setNewPriceWizard(false)}
      >
        <div className={'popup-title'}>
          {t('view.product.pricing.newPriceWizard.title')}
        </div>
        <NewPriceWizard
          productId={productId}
          currencyOptions={currencyOptions}
          countryOptions={countryOptions}
          inlineCreationCallback={(price) => {
            setNewProductPrices([...newProductPrices, price]);
            setNewPriceWizard(false);
          }}
          creationCallback={(price) => {
            if (newProductPrices) {
              setNewProductPrices([...newProductPrices, price]);
              setNewPriceWizard(false);
            }
          }}
          currentPrices={getCurrentPricesMerged()}
        />
      </Popup>
    );
  };

  if (currencyOptions && countryOptions && productPrices) {
    return (
      <div className={'pricing'}>
        <CardTitle
          title={t('view.product.pricing.title')}
          inherited={
            inheritedProductPrices !== undefined
              ? productPrices &&
                productPrices.length === 0 &&
                newProductPrices.length === 0
              : undefined
          }
          hint={hint ? { message: hint } : undefined}
          knowledgeBaseItems={[
            {
              title: t('knowledgebase.product.tips.listPrice.title'),
              text: t('knowledgebase.product.tips.listPrice.text'),
              mediaUri: t('knowledgebase.product.tips.listPrice.media'),
            },
            {
              title: t('knowledgebase.product.tutorials.pricing.title'),
              text: t('knowledgebase.product.tutorials.pricing.text'),
              videoUri: t('knowledgebase.product.tutorials.pricing.video'),
            },
          ]}
          hasBottomMargin
        />
        <div className="pricing-taxTypeIdentifier">
          <div className="pricing-price-inputGroup">
            <div className="global-inputGroup-inputInline">
              <Dropdown
                title={t('view.product.pricing.taxTypeIdentifier.title')}
                options={['Full', 'Reduced', 'Free']}
                translationPath="view.product.pricing.taxTypeIdentifier."
                selected={taxTypeIdentifier}
                update={
                  !readonly
                    ? (e) =>
                        updateTaxTypeIdentifier(
                          e.target.selectedOptions[0].value as TaxTypeIdentifier
                        )
                    : undefined
                }
                required={!productId}
              />
            </div>
          </div>
        </div>
        <ErrorCheck errors={errors} checkFor={['ProductPrices']} />
        <div className={'pricing-prices'}>
          {productPrices.map((price, i) => {
            return (
              <Price
                index={i}
                price={price}
                updatePrice={updatePrice}
                deletePrice={removeProductPrice}
                currencyOptions={currencyOptions}
                countryOptions={countryOptions}
                mode={mode}
              />
            );
          })}
          {newProductPrices.map((price, i) => {
            return (
              <Price
                index={i}
                price={price}
                updatePrice={updatePrice}
                deletePrice={removeNewProductPrice}
                currencyOptions={currencyOptions}
                countryOptions={countryOptions}
                mode={'NEW'}
              />
            );
          })}
          {productPrices?.length === 0 && newProductPrices.length === 0
            ? inheritedProductPrices?.map((price, i) => {
                return (
                  <Price
                    index={i}
                    price={price}
                    updatePrice={updatePrice}
                    currencyOptions={currencyOptions}
                    countryOptions={countryOptions}
                    mode={'INHERITED'}
                  />
                );
              })
            : null}
        </div>
        <div
          className={
            'global-cardActions global-cardActions-postBorder pricing-actions'
          }
        >
          {inheritedProductPrices &&
          inheritedProductPrices.length > 0 &&
          productPrices?.length === 0 &&
          newProductPrices.length === 0 ? (
            <Has authorizations={['product_prices:create']}>
              <Button
                cta={t('view.product.pricing.transfer')}
                look={'secondary'}
                action={transferInheritedProductPrices}
                width="minimal"
                active={!readonly}
              />
            </Has>
          ) : (
            <Has authorizations={['product_prices:create']}>
              <Button
                cta={t('view.product.pricing.new')}
                look={'secondary'}
                action={() => setNewPriceWizard(true)}
                width="minimal"
                active={!readonly}
              />
            </Has>
          )}
          {productId ? (
            <Button
              cta={t('actions.save')}
              look="save"
              action={() => {
                handleSubmit(productPrices);
              }}
              width="minimal"
              active={
                (productPrices !== originalProductPrices ||
                  newProductPrices.length > 0) &&
                !readonly
              }
              isLoading={isSubmitting}
            />
          ) : null}
        </div>
        {renderNewPriceWizard(currencyOptions, countryOptions)}
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default Pricing;
