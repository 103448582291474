import './companyinfo.css';
import { AdyenWizardStep } from '../AdyenWizard';
import { TempShareholderInfo } from '../shareholders/Shareholders';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../../contexts/errorhandler/ErrorHandler';
import useNotifications from '../../../../../hooks/useNotifications';
import {
  AdyenLegalEntityResponse,
  CreateAdyenLegalEntityRequest,
  LegalEntityAssociation,
  TypeEnum10,
  TypeEnum17,
  TypeEnum19,
} from '../../../../../api/petcloudapi/api';
import useCommonDropDownOptions from '../../../../../hooks/useCommonDropDownOptions';
import MenuList, {
  MenuListItem,
} from '../../../../../elements/menulist/MenuList';
import { useState } from 'react';
import Popup from '../../../../../elements/popup/Popup';
import FormBuilder from '../../../../../elements/formbuilder/FormBuilder';
import { DropdownOption } from '../../../../../elements/selectors/Selectors';
import Button from '../../../../../elements/button/Button';

const emptyLegalEntityRequest = (
  manufacturerId: string,
  entityAssociations: LegalEntityAssociation[]
): CreateAdyenLegalEntityRequest => ({
  type: TypeEnum10.Organization,
  entityAssociations: entityAssociations,
  individual: null,
  organization: {
    type: TypeEnum19.PrivateCompany,
    description: '',
    legalName: '',
    registeredAddress: {
      city: null,
      country: null,
      postalCode: null,
      street: null,
    },
    registrationNumber: 'HRB...',
  },
  soleProprietorship: null,
  reference: '',
  manufacturerId: manufacturerId,
});

interface CompanyInfoProps {
  setStep: (step: AdyenWizardStep) => void;
  tempShareholderInfo: TempShareholderInfo[];
  fetchedLegalEntities: AdyenLegalEntityResponse[] | null;
  manufacturerId: string;
  setLegalEntityId: (id: string) => void;
  setLegalEntityName: (name: string) => void;
}

const CompanyInfo: React.FC<CompanyInfoProps> = ({
  setStep,
  tempShareholderInfo,
  fetchedLegalEntities,
  manufacturerId,
  setLegalEntityId,
  setLegalEntityName,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.manufacturer.adyenWizard.companyInfo',
  });
  const api = usePetCloudApi();
  const adyenLegalEntitiesApi = api.adyenLegalEntitiesApi();
  const errorHandler = useErrorHandler();
  const { pushNotification } = useNotifications();
  const { countryOptions } = useCommonDropDownOptions();

  const [legalEntities, setLegalEntities] = useState<
    AdyenLegalEntityResponse[]
  >(fetchedLegalEntities ?? []);
  const [newLegalEntity, setNewLegalEntity] =
    useState<CreateAdyenLegalEntityRequest | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectedLegalEntityId, setSelectedLegalEntityId] = useState<
    string | null
  >(null);

  const createLegalEntity = () => {
    if (newLegalEntity) {
      setIsSubmitting(true);
      const reference = newLegalEntity.organization?.legalName;
      if (reference) {
        adyenLegalEntitiesApi
          .adyenLegalEntitiesCreateLegalEntity({
            ...newLegalEntity,
            reference: reference,
          })
          .then((response) => {
            console.log(response);

            // push to be rendered in legal entities list
            const updateL = fetchedLegalEntities ?? [];
            updateL.push(response.data);
            setLegalEntities(updateL);

            setIsSubmitting(false);
            setNewLegalEntity(null);
            pushNotification(t('notifications.create_successful'));
          })
          .catch((error) => {
            console.log(error);
            errorHandler.addError(error.response);
            setIsSubmitting(false);
          });
      }
    }
  };

  const getMenuListItems = (): MenuListItem[] => {
    return legalEntities
      .filter((x) => x.type === ('Organization' as TypeEnum10))
      .map((entity) => {
        const toggled = selectedLegalEntityId === entity.legalEntityId;
        return {
          title: entity.reference,
          toggled: toggled,
          onToggle: () => {
            setSelectedLegalEntityId(entity.legalEntityId);
          },
        };
      });
  };

  const getEntityAssociations = (): LegalEntityAssociation[] => {
    const ea: LegalEntityAssociation[] = [];

    tempShareholderInfo.forEach((temp) => {
      if (temp.uboThroughOwnership) {
        ea.push({
          type: TypeEnum17.UboThroughOwnership,
          jobTitle: temp.jobTitle,
          legalEntityId: temp.legalEntityId,
        });
      }
      if (temp.uboThroughControl) {
        ea.push({
          type: TypeEnum17.UboThroughControl,
          jobTitle: temp.jobTitle,
          legalEntityId: temp.legalEntityId,
        });
      }
      if (temp.signatory) {
        ea.push({
          type: TypeEnum17.Signatory,
          jobTitle: temp.jobTitle,
          legalEntityId: temp.legalEntityId,
        });
      }
    });

    return ea;
  };

  const nextStep = () => {
    if (selectedLegalEntityId) {
      setLegalEntityId(selectedLegalEntityId);
      setLegalEntityName(
        legalEntities.find((x) => x.legalEntityId === selectedLegalEntityId)
          ?.reference ?? 'Reference missing'
      );
      setStep(AdyenWizardStep.TransferInstrument);
    }
  };

  const phoneTypeOptions: DropdownOption[] = [
    {
      id: 'mobile',
      name: t('phoneTypeOptions.mobile'),
    },
    { id: 'landline', name: t('phoneTypeOptions.landline') },
  ];

  return (
    <div className="companyInfo">
      <div className={'popup-title'}>{t('title')}</div>
      <div className={'global-textElement global-textElement'}>{t('text')}</div>
      <MenuList
        items={getMenuListItems()}
        addButton={{
          cta: t('cta_add'),
          look: 'primary',
          action: () =>
            setNewLegalEntity(
              emptyLegalEntityRequest(manufacturerId, getEntityAssociations())
            ),
        }}
      />
      {newLegalEntity ? (
        <Popup
          toggled={true}
          width={'30%'}
          close={() => setNewLegalEntity(null)}
        >
          <div className={'popup-title'}>{t('form.title')}</div>
          <div className={'companyInfo-form'}>
            <FormBuilder
              contentObject={newLegalEntity}
              setContentObject={setNewLegalEntity}
              elements={[
                {
                  type: 'group',
                  children: [
                    {
                      type: 'input',
                      title: t('form.legalName'),
                      objectPath: 'organization.legalName',
                    },
                    {
                      type: 'input',
                      title: t('form.registrationNumber'),
                      objectPath: 'organization.registrationNumber',
                    },
                  ],
                },
                {
                  type: 'group',
                  children: [
                    {
                      type: 'input',
                      title: t('form.street'),
                      objectPath: 'organization.registeredAddress.street',
                    },
                    {
                      type: 'input',
                      title: t('form.postalCode'),
                      objectPath: 'organization.registeredAddress.postalCode',
                    },
                  ],
                },
                {
                  type: 'group',
                  children: [
                    {
                      type: 'input',
                      title: t('form.city'),
                      objectPath: 'organization.registeredAddress.city',
                    },
                    {
                      type: 'dropdownMenu',
                      title: t('form.country'),
                      objectPath: 'organization.registeredAddress.country',
                      optionObjects: countryOptions,
                    },
                  ],
                },
                {
                  type: 'group',
                  children: [
                    {
                      type: 'input',
                      title: t('form.phoneNumber'),
                      objectPath: 'organization.phone.number',
                      size: 2,
                    },
                    {
                      type: 'dropdown',
                      title: t('form.phoneType'),
                      objectPath: 'organization.phone.type',
                      optionObjects: phoneTypeOptions,
                    },
                  ],
                },
              ]}
            />
          </div>
          <div className={'global-cardActions'}>
            <Button
              cta={t('form.cta')}
              look={'save'}
              action={() => createLegalEntity()}
              isLoading={isSubmitting}
            />
          </div>
        </Popup>
      ) : null}
      <div className={'global-cardActions global-cardActions-margin'}>
        <Button
          cta={t('cta_next')}
          action={nextStep}
          look={'secondary'}
          width={'minimal'}
          active={!!selectedLegalEntityId}
        />
      </div>
    </div>
  );
};

export default CompanyInfo;
