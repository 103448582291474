import Input from '../../../../elements/input/Input';
import {
  Dropdown,
  DropdownOption,
} from '../../../../elements/selectors/Selectors';
import React, { useEffect, useState } from 'react';
import {
  CreateManufacturerContactRequest,
  Department,
  ManufacturerContactResponse,
} from '../../../../api/petcloudapi/api';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import TranslatedStringIndex from '../../../../types/TranslatedStringIndex';
import { useTranslation } from 'react-i18next';
import { LoadingContainer } from '../../../../elements/loading/Loading';

interface ContactEditProps {
  member: ManufacturerContactResponse | CreateManufacturerContactRequest;
  updateCurrentUser?: (member: ManufacturerContactResponse) => void;
  updateNewUser?: (member: CreateManufacturerContactRequest) => void;
}

const ContactEdit: React.FC<ContactEditProps> = ({
  member,
  updateCurrentUser,
  updateNewUser,
}) => {
  const { t, i18n } = useTranslation();
  const api = usePetCloudApi();
  const countriesApi = api.countriesApi();
  const errorHandler = useErrorHandler();
  const [countries, setCountries] = useState<DropdownOption[] | null>(null);

  useEffect(() => {
    countriesApi
      .countriesGetCountries()
      .then((response) => {
        console.log(response);
        setCountries(
          response.data.map((country) => {
            return {
              id: country.id,
              name:
                country.name[i18n.language as TranslatedStringIndex] ??
                'missing country name for your localization',
            };
          })
        );
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  }, []);

  const departments = [
    {
      id: 'ManagingDirector',
      name: t('components.contactedit.departments.managing_director'),
    },
    {
      id: 'Accounting',
      name: t('components.contactedit.departments.accounting'),
    },
    { id: 'Sales', name: t('components.contactedit.departments.sales') },
    {
      id: 'ContentDirector',
      name: t('components.contactedit.departments.content_director'),
    },
    {
      id: 'CustomerSupport',
      name: t('components.contactedit.departments.customer_support'),
    },
    {
      id: 'ProductPreparation',
      name: t('components.contactedit.departments.product_preparation'),
    },
    {
      id: 'ProductManagement',
      name: t('components.contactedit.departments.product_management'),
    },
    {
      id: 'ProductDamageSettlement',
      name: t('components.contactedit.departments.product_damage_settlement'),
    },
  ];

  if (countries) {
    return (
      <>
        <div className="global-inputGroup">
          <div className="global-inputGroup-input" style={{ flex: 0.5 }}>
            <Input
              title={t('components.contactedit.title')}
              content={member.title ? member.title : ''}
              update={(e) => {
                member.title = e;
                if (updateNewUser) {
                  updateNewUser(member);
                } else if (updateCurrentUser) {
                  updateCurrentUser(member as ManufacturerContactResponse);
                }
              }}
            />
          </div>
          <div className="global-inputGroup-input" style={{ flex: 0.5 }}>
            <Input
              title={t('components.contactedit.salutation')}
              content={member.salutation}
              update={(e) => {
                member.salutation = e;
                if (updateNewUser) {
                  updateNewUser(member);
                } else if (updateCurrentUser) {
                  updateCurrentUser(member as ManufacturerContactResponse);
                }
              }}
              required={true}
            />
          </div>
          <div className="global-inputGroup-input">
            <Input
              title={t('components.contactedit.firstName')}
              content={member.firstName}
              update={(e) => {
                member.firstName = e;
                if (updateNewUser) {
                  updateNewUser(member);
                } else if (updateCurrentUser) {
                  updateCurrentUser(member as ManufacturerContactResponse);
                }
              }}
              required={true}
            />
          </div>
          <div className="global-inputGroup-input">
            <Input
              title={t('components.contactedit.lastName')}
              content={member.lastName}
              update={(e) => {
                member.lastName = e;
                if (updateNewUser) {
                  updateNewUser(member);
                } else if (updateCurrentUser) {
                  updateCurrentUser(member as ManufacturerContactResponse);
                }
              }}
              required={true}
            />
          </div>
        </div>
        <div className="global-inputGroup">
          <div className="global-inputGroup-input">
            <Input
              title={t('components.contactedit.phone')}
              content={member.phoneNumber}
              update={(e) => {
                member.phoneNumber = e;
                if (updateNewUser) {
                  updateNewUser(member);
                } else if (updateCurrentUser) {
                  updateCurrentUser(member as ManufacturerContactResponse);
                }
              }}
              required={true}
            />
          </div>
          <div className="global-inputGroup-input">
            <Input
              title={t('components.contactedit.email')}
              content={member.email}
              update={(e) => {
                member.email = e;
                if (updateNewUser) {
                  updateNewUser(member);
                } else if (updateCurrentUser) {
                  updateCurrentUser(member as ManufacturerContactResponse);
                }
              }}
              required={true}
            />
          </div>
          <div className="global-inputGroup-input">
            <Dropdown
              optionObjects={departments}
              title={t('components.contactedit.department')}
              selected={
                departments.find((o) => o.id === member.department)?.name
              }
              update={(e) => {
                const department =
                  e.target.selectedOptions[0].getAttribute('data-value');
                if (department) {
                  member.department = department as Department;
                  if (updateNewUser) {
                    updateNewUser(member);
                  } else if (updateCurrentUser) {
                    updateCurrentUser(member as ManufacturerContactResponse);
                  }
                }
              }}
            />
          </div>
        </div>
        <div className="global-inputGroup">
          <div className="global-inputGroup-input">
            <Input
              title={t('components.contactedit.street')}
              content={member.street}
              update={(e) => {
                member.street = e;
                if (updateNewUser) {
                  updateNewUser(member);
                } else if (updateCurrentUser) {
                  updateCurrentUser(member as ManufacturerContactResponse);
                }
              }}
            />
          </div>
          <div className="global-inputGroup-input">
            <Input
              title={t('components.contactedit.streetNumber')}
              content={member.streetNumber}
              update={(e) => {
                member.streetNumber = e;
                if (updateNewUser) {
                  updateNewUser(member);
                } else if (updateCurrentUser) {
                  updateCurrentUser(member as ManufacturerContactResponse);
                }
              }}
            />
          </div>
        </div>
        <div className="global-inputGroup">
          <div className="global-inputGroup-input">
            <Input
              title={t('components.contactedit.additionalAddressLine1')}
              content={member.additionalAddressLine1}
              update={(e) => {
                member.additionalAddressLine1 = e;
                if (updateNewUser) {
                  updateNewUser(member);
                } else if (updateCurrentUser) {
                  updateCurrentUser(member as ManufacturerContactResponse);
                }
              }}
            />
          </div>
          <div className="global-inputGroup-input">
            <Input
              title={t('components.contactedit.additionalAddressLine2')}
              content={member.additionalAddressLine2}
              update={(e) => {
                member.additionalAddressLine2 = e;
                if (updateNewUser) {
                  updateNewUser(member);
                } else if (updateCurrentUser) {
                  updateCurrentUser(member as ManufacturerContactResponse);
                }
              }}
            />
          </div>
        </div>
        <div className="global-inputGroup">
          <div className="global-inputGroup-input">
            <Input
              title={t('components.contactedit.zipCode')}
              content={member.zipCode}
              update={(e) => {
                member.zipCode = e;
                if (updateNewUser) {
                  updateNewUser(member);
                } else if (updateCurrentUser) {
                  updateCurrentUser(member as ManufacturerContactResponse);
                }
              }}
              required
            />
          </div>
          <div className="global-inputGroup-input">
            <Input
              title={t('components.contactedit.city')}
              content={member.city}
              update={(e) => {
                member.city = e;
                if (updateNewUser) {
                  updateNewUser(member);
                } else if (updateCurrentUser) {
                  updateCurrentUser(member as ManufacturerContactResponse);
                }
              }}
              required
            />
          </div>
          <div className="global-inputGroup-input">
            <Input
              title={t('components.contactedit.state')}
              content={member.state}
              update={(e) => {
                member.state = e;
                if (updateNewUser) {
                  updateNewUser(member);
                } else if (updateCurrentUser) {
                  updateCurrentUser(member as ManufacturerContactResponse);
                }
              }}
            />
          </div>
          <div className="global-inputGroup-input">
            <Dropdown
              title={t('components.contactedit.country')}
              optionObjects={countries}
              selected={countries.find((o) => o.id === member.countryId)?.name}
              update={(e) => {
                const value =
                  e.target.selectedOptions[0].getAttribute('data-value');
                if (value) {
                  member.countryId = value;
                  if (updateNewUser) {
                    updateNewUser(member);
                  } else if (updateCurrentUser) {
                    updateCurrentUser(member as ManufacturerContactResponse);
                  }
                }
              }}
            />
          </div>
        </div>
      </>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default ContactEdit;
