import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigator, Stage } from '../../layout/stage/Stage';
import Button from '../../elements/button/Button';
import { ReactComponent as Eye } from '../../../assets/icon/eye.svg';
import ShopCustomization from '../../sections/shopcustomization/ShopCustomization';
import { useParams } from 'react-router-dom';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { UpdateBrandStoreRequest } from '../../api/petcloudapi/api';
import {
  Error,
  useErrorHandler,
} from '../../contexts/errorhandler/ErrorHandler';
import { LoadingContainer } from '../../elements/loading/Loading';
import { Store } from 'react-notifications-component';
import { Has, useAuthorization } from '../../contexts/auth/Authorization';
import { states } from '../../features/list/List';

const Shop = () => {
  const { t } = useTranslation();
  const { authorizations } = useAuthorization();
  const api = usePetCloudApi();
  const brandStoresApi = api.brandStoresApi();
  const params = useParams();
  const shopId = params.shopId;
  const errorHandler = useErrorHandler();
  const [shop, setShop] = useState<UpdateBrandStoreRequest | null>(null);
  const [brandName, setBrandName] = useState<string | null | undefined>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<Error[] | null>(null);

  useEffect(() => {
    getShop();
  }, []);

  const getShop = () => {
    if (shopId) {
      brandStoresApi
        .brandStoresGetBrandStoreById(shopId)
        .then((response) => {
          console.log(response);
          setBrandName(response.data.brand?.slug);
          setShop({
            ...response.data,
            heroBannerAssetId: {
              'de-DE': response.data.heroBannerAsset['de-DE']?.id,
              'en-GB': response.data.heroBannerAsset['en-GB']?.id,
            },
          });
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  };

  const submitUpdatedShop = () => {
    if (shopId && shop) {
      setIsSubmitting(true);
      setErrors(null);
      brandStoresApi
        .brandStoresUpdateBrandStore(shopId, shop as UpdateBrandStoreRequest)
        .then((response) => {
          console.log(response);
          getShop();
          setIsSubmitting(false);
          Store.addNotification({
            message: t('view.shop.notifications.update_successful'),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response, (errs) => {
            setErrors(errs);
          });
          setIsSubmitting(false);
        });
    }
  };

  if (authorizations && shop) {
    return (
      <Has authorizations={['brand_stores:detail', 'manufacturers:detail']}>
        <Stage>
          <Navigator
            title={t('view.shop.title') + ' ' + shop.name}
            badges={[
              {
                title: t('view.shop.state.' + shop.state),
                color: states[shop.state ?? 'Undefined'],
              },
            ]}
            allowBackButton
          >
            {brandName ? (
              <Button
                cta={t('view.shop.view')}
                type="icon-text"
                action={() =>
                  (window.location.href =
                    'https://inpetto.com/marken/' + brandName)
                }
                margin={'right'}
              >
                <Eye
                  className="button-icon"
                  fill="var(--color-text_secondary)"
                />
              </Button>
            ) : null}
            <Button
              cta={t('actions.save')}
              look="save"
              width="minimal"
              action={submitUpdatedShop}
              margin="left"
              isLoading={isSubmitting}
              hasPermission={authorizations.includes('brand_stores:edit')}
            />
          </Navigator>
          {shop ? (
            <ShopCustomization
              shop={shop}
              updateShop={setShop}
              errors={errors}
            />
          ) : (
            <LoadingContainer />
          )}
        </Stage>
      </Has>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default Shop;
