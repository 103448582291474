import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { usePetCloudApi } from '../../api/PetCloudApi';
import {
  BrandStoreState,
  CreateBrandStoreRequest,
} from '../../api/petcloudapi/api';
import { useUser } from '../../contexts/auth/User';
import Button from '../../elements/button/Button';
import { EmptyState } from '../../elements/emptystate/EmptyState';
import {
  Error,
  useErrorHandler,
} from '../../contexts/errorhandler/ErrorHandler';
import { Navigator, Stage } from '../../layout/stage/Stage';
import ShopCustomization from '../../sections/shopcustomization/ShopCustomization';

const NewShop = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const link = useNavigate();
  const api = usePetCloudApi();
  const brandStoresApi = api.brandStoresApi();
  const errorHandler = useErrorHandler();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<Error[] | null>(null);

  const [shop, setShop] = useState<CreateBrandStoreRequest | null>(null);

  useEffect(() => {
    if (user?.manufacturerId) {
      setShop({
        name: '',
        brandId: '',
        primaryColorHex: '#ffffff',
        secondaryColorHex: '#ffffff',
        primaryContrastColorHex: '#000000',
        secondaryContrastColorHex: '#ffffff',
        state: 'Draft' as BrandStoreState,
        manufacturerId: user.manufacturerId,
        claim: {
          'de-DE': null,
          'en-GB': null,
        },
        description: {
          'de-DE': null,
          'en-GB': null,
        },
        faq: null,
        heroBannerAssetId: {
          'de-DE': null,
          'en-GB': null,
        },
      });
    }
  }, []);

  const submitNewShop = () => {
    if (shop) {
      setErrors([]);
      setIsSubmitting(true);
      brandStoresApi
        .brandStoresCreateBrandStore(shop)
        .then((response) => {
          console.log(response);
          setIsSubmitting(false);
          link('/shop/' + response.data.id);
          setIsSubmitting(false);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response, (errs) => {
            setErrors(errs);
          });
          setIsSubmitting(false);
        });
    }
  };

  if (shop) {
    return (
      <Stage>
        <Navigator title={t('view.shop.new.title')} allowBackButton>
          <Button
            cta={t('view.shop.new.submit')}
            look="save"
            width="minimal"
            action={submitNewShop}
            margin="left"
            isLoading={isSubmitting}
          />
        </Navigator>
        <ShopCustomization shop={shop} updateShop={setShop} errors={errors} />
      </Stage>
    );
  } else {
    return <EmptyState message={t('view.shops.noManufacturer')} />;
  }
};

export default NewShop;
